import React, { useEffect, useRef, useState } from 'react';
import useTranslation from '@hooks/useTranslation';
import FilterSearchGroup from '../../../../MainPages/Components/FilterSearchGroup';
import SearchGroup from '../../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import { Button, DatePicker, Select as ReactSelect, TextInput } from '@components';
import { InputWrap, SearchWrap, SelectGroup } from '../../../../MainPages/Components/FilterSearchGroup/Components/Part';
import SearchButtonArea from '../../../../MainPages/Components/FilterSearchGroup/Components/SearchButtonArea';
import moment from 'moment';
import { LOGIN_TYPE } from '../index';

/**
 *
 * 로그인 이력 검색기능 컴포넌트
 * @param {function} promise 로그인 이력 리스트 호출 callback
 *
 * @author jinbeom-jung
 * */
const Search = ({ promise, loginType }) => {
    const t = useTranslation('Login History Log');

    const typeOptions =
        loginType === LOGIN_TYPE.oAuth
            ? [
                  { value: '', label: t('Log Type') },
                  { value: 'F', label: t('Failure') },
                  { value: 'S', label: t('Success') },
              ]
            : [
                  { value: '', label: t('Log Type') },
                  { value: 'N', label: t('Failure') },
                  { value: 'Y', label: t('Success') },
              ];

    const searchOptions = [
        { value: '', label: t('Search Option', 'Search') },
        { value: 'ip', label: t('IP') },
        { value: 'regDate', label: t('Date') },
    ];

    const [searchInput, setSearchInput] = useState('');
    const handleInputChange = e => {
        const { value } = e.target;
        setSearchInput(value);
    };

    const [date, setDate] = useState(moment().startOf('day').valueOf());
    const handleDate = selected => setDate(selected);

    const [selectedOption, setSelectedOption] = useState(searchOptions[0]);
    const handleSelectValueChange = selected => {
        const { value } = selected;
        if (value !== '') {
            setSearchInput('');
        }
        setSelectedOption(selected);
    };

    const [selectedTypeOption, setSelectedTypeOption] = useState(typeOptions[0]);
    const handleSelectedTypeOption = selected => setSelectedTypeOption(selected);

    const handleRefreshClick = () => {
        setDate(moment().startOf('day').valueOf());
        setSearchInput('');
        setSelectedTypeOption(typeOptions[0]);
        setSelectedOption(searchOptions[0]);
        promise({ page: 1 });
    };

    const handleSearchClick = () => {
        const param = {};
        if (selectedOption.value) {
            param['opt'] = selectedOption.value;
        }
        if (selectedTypeOption.value) {
            param['type'] = selectedTypeOption.value;
        }
        if (selectedOption.value) {
            param['keyword'] = selectedOption.value === 'ip' ? searchInput : date / 1000;
        }
        promise({ ...param });
    };

    const inputRef = useRef(null);
    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.querySelector('.react-datepicker__input-container>input').setAttribute('readonly', true);
        }
    }, [selectedOption, date]);
    return (
        <FilterSearchGroup className={'p-0'}>
            <SearchGroup>
                <div>
                    <ReactSelect
                        name="manipulationType"
                        placeholder={t('Log Type')}
                        value={selectedTypeOption}
                        options={typeOptions}
                        onChange={handleSelectedTypeOption}
                        customControlStyles={{
                            width: '100%',
                        }}
                        customMenuStyles={{ width: '100%' }}
                        customOptionStyles={{ width: '100%' }}
                    />
                </div>
                <SearchWrap>
                    <SelectGroup>
                        <ReactSelect
                            name="selectInput"
                            placeholder={t('Search Option', 'Search')}
                            value={selectedOption}
                            options={searchOptions}
                            onChange={handleSelectValueChange}
                            customMenuStyles={{ width: '100%' }}
                            customOptionStyles={{ width: '100%' }}
                            customControlStyles={{ width: '100%' }}
                        />
                    </SelectGroup>
                    <InputWrap>
                        {!selectedOption || selectedOption.value === 'regDate' ? (
                            <div className="w-100" ref={inputRef}>
                                <DatePicker
                                    key={`dp_${date}`}
                                    value={date}
                                    valueType="ms"
                                    handleChange={handleDate}
                                    maxDate={moment().valueOf()}
                                    withoutTime
                                />
                            </div>
                        ) : (
                            <TextInput
                                type="text"
                                name="name"
                                value={searchInput}
                                size={60}
                                disabled={!selectedOption.value}
                                handleChange={handleInputChange}
                                placeholder={t(
                                    'Please Enter your search keyword after selecting search option.',
                                    'Search',
                                )}
                            />
                        )}
                    </InputWrap>
                    <SearchButtonArea>
                        <Button className="btn-secondary mr-2" onClick={handleSearchClick}>
                            {t('Search', 'Search')}
                        </Button>
                        <Button className="btn-line btn-icon-only" iconName="refresh" onClick={handleRefreshClick} />
                    </SearchButtonArea>
                </SearchWrap>
            </SearchGroup>
        </FilterSearchGroup>
    );
};

export default Search;
