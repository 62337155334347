import React from 'react';
import CountUp from 'react-countup';
import useTranslation from '@hooks/useTranslation';
import { useOutpatientUnattendedReceivingContext, useOutpatientUnattendedReceivingDispatch } from '../../index';
import { setSelectedOutpatientStatus } from '../../OutpatientUnattendedReceivingStatusSlice';
import { Icon } from '@components';

export const VIEW_MODE = 'view';

const DATA_LABEL = {
    total: {
        value: null,
        label: 'All Unattended Storage',
    },
    paymentSuccessCount: {
        value: 'paymentSuccess',
        label: 'Successful unattended checkout',
    },
    paymentFailCount: {
        value: 'paymentFail',
        label: 'Failed unattended checkout',
    },
};

const ItemCount = ({ start = 0, end = 0, label }) => {
    const t = useTranslation('OutpatientUnattendedReceivingStatus');
    const { selectedOutpatientStatus } = useOutpatientUnattendedReceivingContext();
    const dispatch = useOutpatientUnattendedReceivingDispatch();

    const handleLabelClick = () => {
        dispatch(setSelectedOutpatientStatus(DATA_LABEL[label].value));
    };

    const selectedStyleClass = selectedOutpatientStatus === DATA_LABEL[label].value ? 'selected' : 'nonSelected';

    return (
        <div className={`flex-center col ${selectedStyleClass}`} onClick={handleLabelClick}>
            <CountUp
                className="item-num"
                decimals={0}
                start={start}
                end={end}
                decimal=","
                duration="1"
                useEasing={false}
                separator=""
                prefix=""
                suffix=""
            />
            <div className={'flx-row'}>
                {t(DATA_LABEL[label].label)}
                {selectedOutpatientStatus === DATA_LABEL[label].value ? (
                    <Icon iconName={'arrow_drop_down'} />
                ) : (
                    <Icon iconName={'arrow_right'} />
                )}
            </div>
        </div>
    );
};

export default ItemCount;
