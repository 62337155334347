import React from 'react';
import useTranslation from '@hooks/useTranslation';
import cx from 'classnames';
import { checkLostSignal } from '@util/mappInfo';

const STATUS_COLOR = {
    OUTOFRANGE: 'color-secondary',
    CRITICAL: 'color-danger',
    WARNING: 'color-warning',
    NORMAL: 'color-success',
    LOSTSIGNAL: 'color-info',
    null: 'color-gray',
    NONE: 'color-gray',
};

const LOST_SIGNAL = 'Lost signal';

const DEFAULT_VALUE = 'N/A';

const SensorInfo = ({ value, status, measure, containHumidity, lostSignal }) => {
    const temp = value == null ? DEFAULT_VALUE : value.toFixed(2);
    const t = useTranslation('RefrigeratorSensor');
    const color = checkLostSignal(lostSignal) ? STATUS_COLOR.LOSTSIGNAL : STATUS_COLOR[status];
    const statusText = checkLostSignal(lostSignal) ? LOST_SIGNAL : status;

    return (
        <div className={cx(' w-100')}>
            <div className={'d-flex flex-column'}>
                <span className={'pnt-txt s-9 color-gray'}>{measure}</span>
                <div className={'flx-row flx-sb'}>
                    <span className={cx('pnt-txt txt-bold', containHumidity ? 's-6' : 's-4', !status && 'color-gray')}>
                        {temp}
                    </span>
                    {!containHumidity && <span className={`pnt-txt s-7 ${color}`}>{t(statusText)}</span>}
                </div>
            </div>
        </div>
    );
};

export default SensorInfo;
