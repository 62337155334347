import React, { useCallback, useState } from 'react';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import '../../../../assets/main/tree.scss';
import CheckboxTree from 'react-checkbox-tree';
import { Icon } from '@components';

const addCheckedChildren = (checkedList, target) => {
    if (!checkedList.includes(target.value)) {
        checkedList.push(target.value);
        if (target.children?.length) {
            target.children.forEach(child => {
                addCheckedChildren(checkedList, child);
            });
        }
    }
};

const deleteCheckedChildren = (checkedList, target) => {
    const checkedIndex = checkedList.indexOf(target.value);
    if (~checkedIndex) {
        checkedList.splice(checkedIndex, 1);
        if (target.children?.length) {
            target.children.forEach(child => {
                deleteCheckedChildren(checkedList, child);
            });
        }
    }
};

const flattenTreeData = (treeData, parentValue = null) => {
    const flatData = [];
    treeData.forEach(node => {
        flatData.push({ ...node, parentValue });
        if (node.children?.length) {
            flatData.push(...flattenTreeData(node.children, node.value));
        }
    });
    return flatData;
};

const findAncestors = (flatData, targetValue) => {
    const path = [];
    const targetNode = flatData.find(node => node.value === targetValue);
    if (targetNode?.parentValue) {
        path.push(targetNode.parentValue);
        path.push(...findAncestors(flatData, targetNode.parentValue));
    }
    return path;
};

const deleteCheckedParent = (treeData, checkedList, target) => {
    const ancestors = findAncestors(flattenTreeData(treeData), target.value);
    return checkedList.filter(checkedId => !ancestors.includes(checkedId));
};

const Tree = ({ data, checked, setChecked }) => {
    const [expanded, setExpanded] = useState([]);

    // 해당 트리 라이브러리는 자식 노드로만 체크된 상태를 조작
    // 셀렉트박스에서 선택 할 경우, 부모 노드 값이 추가됨
    // 그 후에, 부모노드를 트리에서 체크 해제해도 라이브러리에서는 부모 노드의 값을 체크리스트에서 제거 시켜주지 않으므로 직접 구현함
    // 추가의 경우에는 선택한 하위 트리를 전부 체크된 상태로 만들지 않으므로 직접 구현함
    const handleChecked = useCallback(
        (checked, target) => {
            if (typeof setChecked === 'function') {
                let checkedList = [...checked];
                if (target.checked) {
                    addCheckedChildren(checkedList, target);
                } else {
                    deleteCheckedChildren(checkedList, target);
                    checkedList = deleteCheckedParent(data, checkedList, target);
                }
                setChecked(checkedList);
            }
        },
        [setChecked, data],
    );

    return (
        <div className={'tree-wrapper'}>
            <CheckboxTree
                // checkModel={'all'} //default 'leaf'
                // nativeCheckboxes={true}
                // optimisticToggle={false}
                showNodeIcon={false}
                nodes={data}
                checked={checked}
                expanded={expanded}
                onCheck={handleChecked}
                onExpand={expanded => setExpanded(expanded)}
                icons={{
                    check: <Icon iconName={'check_box'} className={'color-secondary md-18'} />,
                    uncheck: <Icon iconName={'check_box_outline_blank'} className={'md-18'} />,
                    halfCheck: <Icon iconName={'indeterminate_check_box'} className={'color-secondary md-18'} />,
                    expandClose: <Icon iconName={'keyboard_arrow_right'} className={'md-18'} />,
                    expandOpen: <Icon iconName={'keyboard_arrow_down'} className={'md-18'} />,
                    expandAll: <Icon iconName={'add_box'} className={'md-18'} />,
                    collapseAll: <Icon iconName={'indeterminate_check_box'} className={'md-18'} />,
                    parentClose: null,
                    parentOpen: null,
                    leaf: null,
                }}
            />
        </div>
    );
};

export default Tree;
