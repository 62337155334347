import React, { useState } from 'react';
import SensorStatus from './Components/SensorStatus';
import { useRefrigeratorSensorContext, useRefrigeratorSensorDispatch } from '../../index';
import { setSensorNum } from '../../refrigeratorSensorSlice';
import useTranslation from '@hooks/useTranslation';
import Bookmark from '../../../../Components/Bookmark';
import Modal from '@components/Modal';
import Button from '@components/Button';
import InnerItemCard from '@components/Card/InnerItemCard';
import NoDataBlock from '../../../../Components/NoDataBlock';
import { REFRIGERATOR_DASHBOARD_TYPE } from '../../RefrigeratorSensorContainer';

const RefrigeratorSensorCard = ({ handleDashboardType, refreshCallback }) => {
    const t = useTranslation('RefrigeratorSensor');
    const modalT = useTranslation('ConfirmModal');
    const { refrigeratorSensorList } = useRefrigeratorSensorContext();
    const dispatch = useRefrigeratorSensorDispatch();

    // 상세보기 button 이벤트
    const handleDetailViewButton = sensorNum => {
        dispatch(setSensorNum(sensorNum));
        handleDashboardType(REFRIGERATOR_DASHBOARD_TYPE.DETAIL);
    };

    // 월간대장 button 이벤트
    const handleMonthlyLogButton = sensorNum => {
        dispatch(setSensorNum(sensorNum));
        handleDashboardType(REFRIGERATOR_DASHBOARD_TYPE.MONTHLY);
    };

    // bookmark error modal
    const [bookmarkErrorModal, setBookmarkErrorModal] = useState(false);
    const toggleBookmarkErrorModal = () => setBookmarkErrorModal(!bookmarkErrorModal);
    const bookmarkErrorModalOkCallback = refreshCallback;
    // bookmark 호출 후 event handler
    const handleBookmarkSuccess = refreshCallback;
    const handleBookmarkFailure = () => {
        setBookmarkErrorModal(true);
    };
    return (
        <>
            {!!refrigeratorSensorList.length ? (
                <div className="grid-container item-grid-250">
                    {refrigeratorSensorList.map(
                        (
                            {
                                targetName,
                                sensorName,
                                sensorNum,
                                targetNum,
                                isBookmark,
                                sensorItems,
                                lostSignalState,
                                modDate,
                            },
                            idx,
                        ) => {
                            return (
                                <InnerItemCard
                                    key={`${targetNum}_${idx}`}
                                    bodyClassName={'overflow-hidden'}
                                    header={{
                                        title: targetName,
                                        subTitle: sensorName,
                                        action: (
                                            <>
                                                <Button
                                                    className="btn-icon-only btn-trans"
                                                    iconName="trending_up"
                                                    iconClassName={'color-gray'}
                                                    onClick={() => handleDetailViewButton(sensorNum)}
                                                />
                                                <Button
                                                    className="btn-icon-only btn-trans"
                                                    iconName="content_paste"
                                                    iconClassName={'color-gray'}
                                                    onClick={() => handleMonthlyLogButton(sensorNum)}
                                                />
                                                <Bookmark
                                                    successCallback={handleBookmarkSuccess}
                                                    failureCallback={handleBookmarkFailure}
                                                    targetNum={targetNum}
                                                    isBookmark={isBookmark}
                                                ></Bookmark>
                                            </>
                                        ),
                                    }}
                                >
                                    <div className="flx-col w-100 h-100 ">
                                        <SensorStatus
                                            key={targetNum}
                                            sensorItems={sensorItems}
                                            lostSignal={lostSignalState}
                                            modDate={modDate}
                                        />
                                    </div>
                                </InnerItemCard>
                            );
                        },
                    )}
                </div>
            ) : (
                <NoDataBlock />
            )}
            <Modal
                initModal={bookmarkErrorModal}
                toggleModal={toggleBookmarkErrorModal}
                headerTitle={t('Bookmark')}
                bodyText={modalT('Something has changed.')}
                removeCancel
                okCallback={bookmarkErrorModalOkCallback}
            />
        </>
    );
};

export default RefrigeratorSensorCard;
