import { createContext, Dispatch, useContext } from 'react';
import { ActionType } from '@components/ContextProvider';
import { createSlice } from '@reduxjs/toolkit';
import { NullableNumber, PageInfo } from '@util/type/util';
import { PAGE_SIZE } from './constants';
import { AnomalyAlertGroupSettings, PatientAnomalyAlertSetting } from '@api/sh/patientAnomalyAlertSetting';

const initialState: {
    alertGroupSettingList: AnomalyAlertGroupSettings[];
    anomalyAlertSettingListInfo: PageInfo<PatientAnomalyAlertSetting> & { pageSize: number };
    selectedGroupNum: NullableNumber;
    selectedAlertSettingGroupNum: NullableNumber;
} = {
    alertGroupSettingList: [],
    anomalyAlertSettingListInfo: {
        page: 1,
        totalCount: 0,
        totalPage: 1,
        pageSize: PAGE_SIZE,
        rows: [],
    },
    selectedGroupNum: null,
    selectedAlertSettingGroupNum: null,
};

export const AnomalyAlertStateContext = createContext(initialState);
export const AnomalyAlertDispatchContext = createContext<Dispatch<ActionType> | null>(null);

export const useAnomalyAlertStateContext = () => useContext(AnomalyAlertStateContext);
export const useAnomalyAlertDispatchContext = () => useContext(AnomalyAlertDispatchContext)!;

const anomalyAlertSlice = createSlice({
    name: 'sh/anomalyAlert',
    initialState,
    reducers: {
        setAlertGroupSettingList: (state, action) => {
            state.alertGroupSettingList = action.payload;
        },
        setAnomalyAlertSettingListInfo: (state, action) => {
            state.anomalyAlertSettingListInfo = { page: 1, ...action.payload };
        },
        selectGroupNum: (state, action) => {
            state.selectedGroupNum = action.payload;
        },
        selectAlertSettingGroupNum: (state, action) => {
            state.selectedAlertSettingGroupNum = action.payload;
        },
    },
});

export const {
    setAlertGroupSettingList,
    setAnomalyAlertSettingListInfo,
    selectGroupNum,
    selectAlertSettingGroupNum,
} = anomalyAlertSlice.actions;

export default anomalyAlertSlice;
