import React from 'react';
import CountUp from 'react-countup';
import cx from 'classnames';
import { DEFAULT_COUNT } from '../../constants';
import { UncontrolledTooltip } from 'reactstrap';
import { useTranslation } from '@util/hooks';
import styled from 'styled-components';
import { Icon } from '@components';

const ItemCount = ({
    start = DEFAULT_COUNT,
    end = DEFAULT_COUNT,
    label,
    isSelected = false,
    handleLabelClick,
    bubbleText,
}) => {
    const iconName = isSelected ? 'arrow_drop_down' : 'arrow_right';
    const t = useTranslation('AssetManagement');

    return (
        <>
            <div
                className={cx('flex-center col overflow-hidden', isSelected ? 'selected' : 'nonSelected')}
                onClick={handleLabelClick}
                id={bubbleText.replace(/ /g, '')}
            >
                <CountUp
                    className="item-num"
                    decimals={0}
                    start={start}
                    end={end}
                    decimal=","
                    duration="1"
                    useEasing={false}
                    separator=""
                    prefix=""
                    suffix=""
                />
                <div className={'flx-row'}>
                    {label}
                    <Icon iconName={iconName} />
                </div>
            </div>
            <Tooltip target={bubbleText.replace(/ /g, '')}>{t(bubbleText)}</Tooltip>
        </>
    );
};

const Tooltip = styled(UncontrolledTooltip)`
    .tooltip-inner {
        max-width: fit-content;
    }
`;

export default ItemCount;
