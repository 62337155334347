import Widget, { SERVICE_CODE, WIDGET_CATEGORY } from '../staticInfo';
import { TAG_KEYS as T } from '../tags';
import Dashboard from './Dashboard';
import DashboardSetting from './Dashboard/DashboardSetting';
import IVInjectionMonitoring from './IVInjectionMonitoring';
import IVInjectionMonitoringSetting from './IVInjectionMonitoring/IVInjectionMonitoringSetting';
import VitalSignsMonitoring from './VitalSignsMonitoring';
import VitalSignMonitoringSetting from './VitalSignsMonitoring/VitalSignsMonitoringSetting';
import VitalSignsRealTimePartron from './VitalSignsRealTimePartron';
import VitalSignsRealTimePartronSetting from './VitalSignsRealTimePartron/VitalSignsRealTimePartronSetting';
import RefrigeratorSensor from './RefrigeratorSensor';
import RefrigeratorSensorSetting from './RefrigeratorSensor/RefrigeratorSensorSetting';
import ExamPatientsMonitoring from './ExamPatientsMonitoring';
import ExamPatientsMonitoringSetting from './ExamPatientsMonitoring/ExamPatientsMonitoringSetting';
import ExamMonitoringHistory from './ExamMonitoringHistory';
import ExamPatientsEmergencyAlertHistory from './ExamPatientsEmergencyAlertHistory';
import EmergencyAlertHistory from './EmergencyAlertHistory';
import VitalSignsMonitoringForHiCardiPlus from './VitalSignsMonitoringForHiCardiPlus';
import VitalSignMonitoringForHiCardiPlusSetting from './VitalSignsMonitoringForHiCardiPlus/VitalSignsMonitoringForHiCardiPlusSetting';
import VitalSignsRealTimePartronPoC from './VitalSignsRealTimePartronPoC';
import VitalSignsRealTimePartronPoCSetting from './VitalSignsRealTimePartronPoC/VitalSignsRealTimePartronPoCSetting';
import RealTimeEnvironmentalSensor from './RealTimeEnvironmentalSensor';
import IotItemStatusForSecurity from './IotItemStatusForSecurity';
import IotItemStatusSettingForSecurity from './IotItemStatusForSecurity/IotItemStatusSetting';
import UsageSensorReport from './UsageSensorReport';
import BiometricInformationMonitoring from './BiometricInformationMonitoring';
import BiometricInformationDashboard from './BiometricInformationDashboard';
import DeviceMappingManagement from './DeviceMappingManagement';
import PatientAnomalyHistory from './PatientAnomalyHistory';
import PatientAnomalyAlertHistory from './PatientAnomalyAlertHistory';
import PatientAnomalyAlertSetting from './PatientAnomalyAlertSetting';
import { addServiceTag } from '../util/commons';

// 대시보드
export const dashboard: Widget = {
    type: 'dashboard',
    category: WIDGET_CATEGORY.DASHBOARD,
    tags: [T.DASHBOARD, T.CARD, T.IOT_COMMON],
    name: 'Dashboard',
    desc: 'It shows the count of each status for IV, patient, asset, scale and refrigerator',
    thumbnailClassName: 'w_dashboard',
    Component: Dashboard,
    SettingComponent: DashboardSetting,
    config: { defaultSettings: { name: 'Dashboard', intervalTime: 10000 } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 46,
        maxH: 54,
    },
};

// 수액 모니터링
export const ivInjectionMonitoring: Widget = {
    type: 'ivInjectionMonitoring',
    category: WIDGET_CATEGORY.MONITORING,
    tags: [T.MONITORING, T.IV_FLUIDS, T.CARD, T.IOT_COMMON],
    name: 'IV Injection Monitoring',
    desc: 'This widget monitors the current IV injection',
    thumbnailClassName: 'w_cardmonitor',
    Component: IVInjectionMonitoring,
    SettingComponent: IVInjectionMonitoringSetting,
    config: { defaultSettings: { name: 'IV Injection Monitoring', intervalTime: 30000 } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 46,
        maxH: 54,
    },
};

export const vitalSignsMonitoring = {
    type: 'vitalSignsMonitoring',
    category: WIDGET_CATEGORY.MONITORING,
    tags: [T.MONITORING, T.SENSOR, T.IOT_COMMON, T.CARD],
    name: 'Vital Signs Monitoring',
    desc: 'Monitors vital signs',
    thumbnailClassName: 'w_filter_cardmonitor',
    Component: VitalSignsMonitoring,
    SettingComponent: VitalSignMonitoringSetting,
    config: { defaultSettings: { name: 'Vital Signs Monitoring' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 14,
        h: 40,
        maxH: 64,
    },
};

// 실시간 활력징후 파트론
export const vitalSignsRealTimePartron = {
    type: 'vitalSignsRealTimePartron',
    category: WIDGET_CATEGORY.MONITORING,
    tags: [T.MONITORING, T.PARTRON_SMART_BAND, T.LIST],
    name: 'Realtime Vital Signs Monitoring(Partron)',
    desc: 'It shows the status of vital signs of the Partron device worn by the patient in real time.',
    thumbnailClassName: 'w_filter_cardmonitor',
    Component: VitalSignsRealTimePartron,
    SettingComponent: VitalSignsRealTimePartronSetting,
    config: { defaultSettings: { name: 'Realtime Vital Signs Monitoring(Partron)' } },
    layout: {
        minW: 12,
        w: 12,
        maxW: 12,
        minH: 14,
        h: 44,
        maxH: 68,
    },
};

// 냉장고 센서
export const refrigeratorSensor = {
    type: 'refrigeratorSensor',
    category: WIDGET_CATEGORY.MONITORING,
    tags: [T.MONITORING, T.CARD, T.CHOIS_REFRIGERATOR_SENSOR, T.TEMPERATURE, T.IOT_COMMON],
    name: 'Refrigerator Sensor',
    desc: "This widget shows the current refrigerator sensors' status",
    thumbnailClassName: 'w_monitor',
    Component: RefrigeratorSensor,
    SettingComponent: RefrigeratorSensorSetting,
    config: { defaultSettings: { name: 'Refrigerator Sensor', intervalTime: 30000 } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 46,
        maxH: 54,
    },
};

export const examPatientsMonitoring: Widget = {
    type: 'examPatientsMonitoring',
    category: WIDGET_CATEGORY.MONITORING,
    tags: [T.MONITORING, T.GALAXY_WATCH_5, T.GALAXY_WATCH_6, T.LIST],
    name: 'Exam Patients Monitoring',
    desc: 'Monitor nuclear medicine exam patients in a list format',
    thumbnailClassName: 'w_search_table',
    Component: ExamPatientsMonitoring,
    SettingComponent: ExamPatientsMonitoringSetting,
    config: { defaultSettings: { name: 'Exam Patients Monitoring' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 42,
        maxH: 54,
    },
};

export const examMonitoringHistory: Widget = {
    type: 'examMonitoringHistory',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    tags: [T.MANUAL_SEARCH, T.LIST],
    name: 'Exam Monitoring History',
    desc: 'View a exam patients monitoring completion history.',
    thumbnailClassName: 'w_search_table',
    Component: ExamMonitoringHistory,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Exam Monitoring History' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 42,
        maxH: 54,
    },
};

export const examPatientsEmergencyAlertHistory: Widget = {
    type: 'examPatientsEmergencyAlertHistory',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    tags: [T.MANUAL_SEARCH, T.LIST],
    name: 'Emergency History',
    desc: "It shows the patient's emergency history",
    thumbnailClassName: 'w_search_table',
    Component: ExamPatientsEmergencyAlertHistory,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Emergency History' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 42,
        maxH: 54,
    },
};

// 국립암센터 PoC 위급상황 알림 이력
export const emergencyAlertHistory: Widget = {
    type: 'emergencyAlertHistory',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    tags: [T.MANUAL_SEARCH, T.LIST],
    name: 'Critical Care Alert History',
    desc: 'It shows the emergency alert history of the integrated nursing care unit.',
    thumbnailClassName: 'w_search_table',
    Component: EmergencyAlertHistory,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Critical Care Alert History' } },
    layout: {
        minW: 10,
        w: 12,
        maxW: 12,
        minH: 36,
        h: 36,
        maxH: 40,
    },
};

// 실시간 활력징후 (HiCardi Plus)
export const vitalSignsMonitoringForHiCardiPlus: Widget = {
    type: 'vitalSignsMonitoringForHiCardiPlus',
    category: WIDGET_CATEGORY.MONITORING,
    tags: [T.MONITORING, T.HICARDI_PLUS, T.LIST],
    name: 'Smart Patch (HiCardi)',
    desc: 'It shows the status of vital signs of the HiCardi Plus device worn by the patient in real time.',
    thumbnailClassName: 'w_filter_cardmonitor',
    Component: VitalSignsMonitoringForHiCardiPlus,
    SettingComponent: VitalSignMonitoringForHiCardiPlusSetting,
    config: { defaultSettings: { name: 'Vital Signs Monitoring (HiCardi Plus)' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 14,
        h: 40,
        maxH: 64,
    },
};

// 실시간 활력징후 파트론 PoC 용
export const vitalSignsRealTimePartronPoC: Widget = {
    type: 'vitalSignsRealTimePartronPoC',
    category: WIDGET_CATEGORY.MONITORING,
    tags: [T.MONITORING, T.PARTRON_SMART_BAND, T.LIST],
    name: 'Smart Band (Partron)',
    desc: 'It shows the status of vital signs of the Partron device worn by the patient in real time.',
    thumbnailClassName: 'w_filter_cardmonitor',
    Component: VitalSignsRealTimePartronPoC,
    SettingComponent: VitalSignsRealTimePartronPoCSetting,
    config: { defaultSettings: { name: 'Real-time monitoring of mildly ill patients(Partron)' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 14,
        h: 40,
        maxH: 64,
    },
};

// 환경센서 모니터링 위젯
export const realTimeEnvironmentalSensor: Widget = {
    type: 'realTimeEnvironmentalSensor',
    category: WIDGET_CATEGORY.MONITORING,
    tags: [T.MONITORING, T.SENSOR, T.CARD],
    name: 'Real Time Environmental Sensor',
    desc: 'Shows a real-time environmental sensor status screen.',
    thumbnailClassName: 'w_filter_cardmonitor',
    Component: RealTimeEnvironmentalSensor,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Real Time Environmental Sensor' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 14,
        h: 40,
        maxH: 64,
    },
};

// 자산 현황 - 보안요원용
export const iotItemStatusForSecurity: Widget = {
    type: 'iotItemStatusForSecurity',
    category: WIDGET_CATEGORY.MONITORING,
    tags: [T.MONITORING, T.TAG, T.CARD, T.LIST],
    name: 'Iot Item Status For Security Guards',
    desc:
        'It shows the current status of the assets selecting all of them or some of them by their status(for security guards).',
    thumbnailClassName: 'w_chart_table',
    Component: IotItemStatusForSecurity,
    SettingComponent: IotItemStatusSettingForSecurity,
    config: {
        defaultSettings: {
            name: 'Iot Item Status For Security Guards',
            intervalTime: 10000,
            categoryCode: 'ASSET',
        },
    },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 46,
        maxH: 54,
    },
};

export const biometricInformationMonitoring: Widget = {
    type: 'biometricInformationMonitoring',
    category: WIDGET_CATEGORY.MONITORING,
    tags: [T.MONITORING, T.LIST, T.SENSOR, T.HEART_RATE, T.RESPIRATION_RATE, T.BODY_TEMPERATURE, T.HICARDI_PLUS],
    name: 'Monitoring by room',
    desc: "Monitor patients' biometrics by room.",
    thumbnailClassName: 'w_search_table',
    Component: BiometricInformationMonitoring,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Monitoring by room', heightOptimizable: true } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 42,
        maxH: 54,
    },
};

export const biometricInformationDashboard: Widget = {
    type: 'biometricInformationDashboard',
    category: WIDGET_CATEGORY.DASHBOARD,
    tags: [T.DASHBOARD, T.CARD, T.SENSOR, T.HEART_RATE, T.RESPIRATION_RATE, T.BODY_TEMPERATURE, T.HICARDI_PLUS],
    name: 'Biometric Information Dashboard',
    desc: "A dashboard of patients' biometric information.",
    thumbnailClassName: 'w_search_table',
    Component: BiometricInformationDashboard,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Biometric Information Dashboard', heightOptimizable: true } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 42,
        maxH: 54,
    },
};

export const deviceMappingManagement: Widget = {
    type: 'deviceMappingManagement',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    tags: [T.SETTINGS, T.LIST, T.SENSOR],
    name: 'Device Mapping Management',
    desc: 'Shows the screen where you can map or unmap devices.',
    thumbnailClassName: 'w_filter_map',
    Component: DeviceMappingManagement,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Device Mapping Management', heightOptimizable: true } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 42,
        maxH: 54,
    },
};

export const patientAnomalyHistory: Widget = {
    type: 'patientAnomalyHistory',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    tags: [T.MANUAL_SEARCH, T.LIST, T.SENSOR, T.HICARDI_PLUS],
    name: 'Patient Anomaly History',
    desc: "It shows the patient's anomaly history.",
    thumbnailClassName: 'w_search_table',
    Component: PatientAnomalyHistory,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Patient Anomaly History', heightOptimizable: true } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 42,
        maxH: 54,
    },
};

export const patientAnomalyAlertHistory: Widget = {
    type: 'patientAnomalyAlertHistory',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    tags: [T.MANUAL_SEARCH, T.LIST, T.SENSOR, T.HICARDI_PLUS],
    name: 'Patient Anomaly Alert History',
    desc: "It shows the patient's anomaly alert history.",
    thumbnailClassName: 'w_search_table',
    Component: PatientAnomalyAlertHistory,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Patient Anomaly Alert History', heightOptimizable: true } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 42,
        maxH: 54,
    },
};

export const patientAnomalyAlertSetting: Widget = {
    type: 'patientAnomalyAlertSetting',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    tags: [T.SETTINGS],
    name: 'Anomalies Alert Setting',
    desc: 'Set up patient anomaly alert control conditions.',
    thumbnailClassName: 'w_search_table',
    Component: PatientAnomalyAlertSetting,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Anomalies Alert Setting', heightOptimizable: true } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 42,
        maxH: 54,
    },
};

export const usageSensorReport: Widget = {
    type: 'usageSensorReport',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    tags: [T.MANUAL_SEARCH, T.SENSOR],
    name: 'Usage Sensor Report',
    desc: 'Ability to view information about the usage (number of events) of devices (sensors)',
    thumbnailClassName: 'w_search_table',
    Component: UsageSensorReport,
    SettingComponent: null,
    config: { defaultSettings: { name: 'UsAge Sensor Report' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 38,
        maxH: 54,
    },
};

const shWidgetList: Record<string, Widget> = {
    dashboard,
    refrigeratorSensor,
    ivInjectionMonitoring,
    vitalSignsMonitoring,
    vitalSignsRealTimePartron,
    // POC 하이카디
    vitalSignsMonitoringForHiCardiPlus,
    vitalSignsRealTimePartronPoC,
    examPatientsMonitoring,
    examMonitoringHistory,
    examPatientsEmergencyAlertHistory,
    // POC 응급알림
    emergencyAlertHistory,
    // 더자인 병원
    realTimeEnvironmentalSensor,
    // 세종병원
    iotItemStatusForSecurity,
    usageSensorReport,
    biometricInformationMonitoring,
    biometricInformationDashboard,
    deviceMappingManagement,
    patientAnomalyHistory,
    patientAnomalyAlertHistory,
    patientAnomalyAlertSetting,
};

for (const widget in shWidgetList) {
    if (shWidgetList.hasOwnProperty(widget)) {
        shWidgetList[widget].serviceCode = SERVICE_CODE.SH;
        shWidgetList[widget].tags = addServiceTag(shWidgetList[widget].tags, T.SH);
    }
}

export default shWidgetList;
