export const sensorName = ({ ...restProps } = {}) => {
    return {
        Header: 'Device Name',
        headerClassName: 'text-ellipsis',
        accessor: 'sensorName',
        className: 'd-flex justify-content-center',
        ...restProps,
    };
};

export const scannerName = ({ ...restProps } = {}) => {
    return {
        Header: 'Device Name',
        headerClassName: 'text-ellipsis',
        accessor: 'scannerName',
        className: 'd-flex justify-content-center',
        ...restProps,
    };
};

export const deviceName = ({ ...restProps } = {}) => {
    return {
        Header: 'Device Name',
        headerClassName: 'text-ellipsis',
        accessor: 'deviceName',
        className: 'd-flex justify-content-center',
        ...restProps,
    };
};

export const actions = ({ ...restProps } = {}) => {
    return {
        Header: 'Actions',
        accessor: 'action',
        headerClassName: 'd-flex justify-content-center',
        className: 'd-flex justify-content-center',
        width: 40,
        ...restProps,
    };
};

export const mapping = ({ ...restProps } = {}) => {
    return {
        Header: 'Mapping',
        accessor: 'mapp',
        headerClassName: 'd-flex justify-content-center',
        className: 'd-flex justify-content-center',
        width: 40,
        ...restProps,
    };
};
