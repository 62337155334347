import React from 'react';
import { FileInputProps } from '../type';
import cx from 'classnames';
import useTranslation from '@hooks/useTranslation';
import { Icon } from '@components';

export const FileInput = ({
    id = 'fileInput',
    handleChange,
    disabled = false,
    required,
    accept,
    className = '',
    name,
}: FileInputProps): JSX.Element => {
    const t = useTranslation();
    return (
        <div className={cx('pnt-file--group overflow-visible', className, disabled && 'form-disable')}>
            <div className="pnt-file--group w-100">
                <input type="file" id={id} name={name} onChange={handleChange} accept={accept} disabled={disabled} />
                <label htmlFor={id}>
                    <Icon iconName={'folder_copy'} />
                    {t('Upload File', 'Button')}
                </label>
            </div>
        </div>
    );
};

export default FileInput;
