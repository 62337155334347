import React from 'react';
import { useColumns, useTranslation } from '@hooks';
import * as column from '../column';
import InnerItemCard from '@components/Card/InnerItemCard';
import { Table } from '@components';
import styled from 'styled-components';
import Search from '../../Search';
import moment from 'moment';
import { setOutpatientListParam } from '../../OutpatientBaselineTestingStatusSlice';
import { useOutpatientBaselineTestingDispatch } from '../../index';

const OutpatientBaselineTestingLists = ({ outpatientBaselineTestList }) => {
    const t = useTranslation('OutpatientBaselineTesting');
    const dispatch = useOutpatientBaselineTestingDispatch();

    const columns = useColumns(
        [
            column.category(),
            column.ward(),
            column.speciality(),
            column.doctor(),
            column.id(),
            column.patientName(),
            column.reservedDate({
                Cell: ({ value }) => {
                    return value ? moment.unix(value).format('YYYY-MM-DD') : ' - ';
                },
            }),
            column.inspectionDate({
                Cell: ({ value }) => {
                    return value ? moment.unix(value).format('YYYY-MM-DD') : ' - ';
                },
            }),
            column.inspectionTime({
                Cell: ({ value }) => {
                    return value ? moment.unix(value).format('HH:mm') : ' - ';
                },
            }),
            column.highestBloodPressure(),
            column.lowestBloodPressure(),
            column.heartRateBpm(),
            column.heightCm(),
            column.weightKg(),
        ],
        t,
        [],
    );

    const handlePageChange = pageIndex => {
        dispatch(
            setOutpatientListParam(prevState => {
                return { ...prevState, page: pageIndex };
            }),
        );
    };

    return (
        <Container>
            <InnerItemCard
                className={'h-100'}
                bodyClassName={'h-100'}
                header={{
                    title: t('Patient lists'),
                }}
            >
                <Search />
                <TableContainer className={'h-90'}>
                    <Table
                        data={outpatientBaselineTestList}
                        columns={columns}
                        onPageChange={handlePageChange}
                        dynamicRowHeight={true}
                    />
                </TableContainer>
            </InnerItemCard>
        </Container>
    );
};

const Container = styled.div`
    height: 550px;
    margin-top: 5px;
`;
const TableContainer = styled.div`
    height: 250px;
    margin-bottom: 5px;
`;
export default OutpatientBaselineTestingLists;
