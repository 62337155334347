import { RequestHandlerCreator, PageInfoType, PageInfoParam } from '@api/util';
import { reqGet, reqPost, reqPatch, reqDelete } from '@api/index';
import { NullableString, NullableNumber, YN, Nullable, ServiceCode, UnixTimestamp } from '@util/type/util';

export interface DisplayGroupInfo {
    groupNum: number;
    groupName: string;
}

export type LinkTarget = 'blank' | 'self' | 'embedded';

export interface ScreenInfo<GroupType = any> {
    screenCode: string;
    screenName: string;
    menuCode: string;
    menuName: string;
    url: NullableString;
    order: number;
    description: NullableString;
    filterNum: NullableNumber;
    widgetInfo: NullableString;
    isWebView: YN;
    authGroups: Nullable<GroupType[]>;
    serviceCode: ServiceCode;
    modDate: UnixTimestamp;
    regDate: UnixTimestamp;
    externalLinkUrl: NullableString;
    linkTarget: LinkTarget;
}

interface ScreenListParam extends PageInfoParam {
    groupNum?: number;
}

interface ScreenParam {
    screenCode: string;
}

interface CreateScreenParam
    extends Omit<
        ScreenInfo,
        | 'authGroups'
        | 'description'
        | 'menuName'
        | 'url'
        | 'filterNum'
        | 'widgetInfo'
        | 'isWebView'
        | 'modeDate'
        | 'regDate'
    > {
    description?: NullableString;
    url?: NullableString;
    filterNum?: NullableNumber;
    widgetInfo?: NullableString;
    isWebView?: YN;
    groupNumList?: number[];
}

interface UpdateScreenParam {
    screenName?: string;
    description?: NullableString;
    menuCode: string;
    url?: NullableString;
    order: number;
    filterNum?: number;
    widgetInfo?: string;
    isWebView?: YN;
    groupNumList?: number[];
    screenCode: string;
}

interface DeleteScreenParam {
    screenCode: string;
}

// 화면
export const fetchScreenList: RequestHandlerCreator<
    PageInfoType<ScreenInfo<DisplayGroupInfo>>,
    ScreenListParam
> = param => reqGet('/v3/api/common/screen', param);
export const fetchScreen: RequestHandlerCreator<ScreenInfo<DisplayGroupInfo>, ScreenParam> = param =>
    reqGet(`/v3/api/common/screen/${param.screenCode}`, param);
export const createScreenApi: RequestHandlerCreator<{}, CreateScreenParam> = param =>
    reqPost('/v3/api/common/screen', param);
export const updateScreenApi: RequestHandlerCreator<{}, UpdateScreenParam> = param =>
    reqPatch(`/v3/api/common/screen/${param.screenCode}`, param);
export const deleteScreenApi: RequestHandlerCreator<{}, DeleteScreenParam> = param =>
    reqDelete(`/v3/api/common/screen/${param.screenCode}`);
