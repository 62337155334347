import React, { ChangeEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useTranslation from '@hooks/useTranslation';
import { useAsync, useConfirm, useValidation } from '@util/hooks';
import { setScreenList } from '@reducer/CustomMenu';
import { Button,Icon, Label, Modal, QuestionTooltip, SearchableSelect, Select, TextInput } from '@components';
import cx from 'classnames';
import { widgetInfoExport } from '../../../../Components/Screen/util';
import { createScreenApi, DisplayGroupInfo, fetchScreenList, updateScreenApi } from '@api/common/screen';
import { fetchAuthGroupList, GroupInfo } from '@api/common/authGroup';
import { INDOORPLUS_ERRORS } from '@util/errorCode';
import Checkbox from '@components/Input/Checkbox';
import { A_PATH_EMBED } from '../../../../Components/Router/path';
import styled from 'styled-components';
import { Tooltip } from 'reactstrap';
import { copyToClipboard } from '@util/common/util';
import ConfirmModal from '@components/Modal/ConfirmModal';
import { useAppSelector } from '@hooks';
import { Nullable, NullableString } from '@util/type/util';
import { OriginScreenInfo } from '../types';

interface Props extends RouteComponentProps {
    initOpen?: boolean;
    toggleModal: () => void;
    screenInfo: Nullable<OriginScreenInfo>;
}

const initScreenInfo: Partial<OriginScreenInfo> = {
    order: 0,
    groupNumList: [],
    linkTarget: 'blank',
};

const ScreenDetailPopup = ({
    initOpen = false,
    toggleModal = function () {
        initOpen = !initOpen;
    },
    screenInfo,
}: Props) => {
    const dispatch = useDispatch();
    const t = useTranslation('Screen');
    const { originMenuList } = useAppSelector(state => state.CustomMenu);
    const { userInfo } = useAppSelector(state => state.UserInfo as Record<string, any>);
    const [groupList, setGroupList] = useState<GroupInfo[]>([]);

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [updateScreenInfo, setUpdateScreenInfo] = useState<Partial<OriginScreenInfo>>(screenInfo ?? initScreenInfo);
    const [widgetInfo, setWidgetInfo] = useState<NullableString>(null);
    const [saveErrMsg, setSaveErrMsg] = useState(t('The request failed.', 'ErrorHandler'));

    const externalLinkOptions = useMemo(() => {
        return [
            { value: 'self', label: t('Current Tab') },
            { value: 'blank', label: t('New Tab') },
            {
                value: 'embedded',
                label: (
                    <span className={'d-flex align-center gap-1'}>
                        {t('Embedded')}
                        <QuestionTooltip
                            contents={t(
                                'Depending on the security policy of the target site, it might not be visible.',
                            )}
                        />
                    </span>
                ),
            },
        ];
    }, [t]);

    const handleSubmitClick = () => {
        const newScreenInfo = updateScreenInfo;
        if (isPassValidation()) {
            if (newScreenInfo?.screenCode && newScreenInfo?.screenName) {
                newScreenInfo.screenCode = newScreenInfo.screenCode.trim();
                newScreenInfo.screenName = newScreenInfo.screenName.trim();
            }

            if (screenInfo) {
                updateScreen({
                    ...newScreenInfo,
                    filterNum: newScreenInfo?.filterNum ?? undefined,
                    widgetInfo: newScreenInfo?.widgetInfo ?? undefined,
                    groupNumList: newScreenInfo?.groupNumList ?? [],
                });
            } else {
                createScreen({
                    ...newScreenInfo,
                    groupNumList: newScreenInfo?.groupNumList ?? [],
                    widgetInfo,
                });
            }
        } else {
            validationModalProps.toggleModal();
        }
    };

    const saveConfirmModalProps = useConfirm({
        confirmText: t('Do you want to save information?'),
        okCallback: () => {
            handleSubmitClick();
        },
    });

    const { isPassValidation, ...validationModalProps } = useValidation({
        targetData: updateScreenInfo,
        validate: screenInfo => {
            const pathRegExp = /^[a-z|A-Z|0-9]+$/gim;
            const msgArr = [];
            if (!screenInfo.screenCode || !screenInfo.screenCode.trim()) {
                msgArr.push(t('Please enter a screen ID'));
            }
            if (
                screenInfo.screenCode &&
                screenInfo.screenCode.trim() &&
                !pathRegExp.test(screenInfo.screenCode.trim())
            ) {
                msgArr.push(t('Screen ID can only contain English upper/lower case letters and numbers.'));
            }
            if (!screenInfo.screenName || !screenInfo.screenName.trim()) {
                msgArr.push(t('Please enter a screen name'));
            }
            if (!screenInfo.menuCode) {
                msgArr.push(t('Please select a display menu'));
            }
            return msgArr;
        },
    });

    const saveSuccessHandler = () => {
        getScreenList();
        setUpdateScreenInfo(initScreenInfo);
        toggleModal();
    };

    const saveSuccessModalProps = useConfirm({
        confirmText: screenInfo
            ? t('The screen information change request was successful.')
            : t('The screen information registration request was successful.'),
        okCallback: saveSuccessHandler,
        cancelCallback: saveSuccessHandler,
        removeCancel: true,
    });

    const saveFailureModalProps = useConfirm({
        confirmText: saveErrMsg,
        okCallback: () => {},
        cancelCallback: () => {},
    });

    useEffect(() => {
        const initMergedScreenInfo = { ...initScreenInfo, ...screenInfo };
        if (originMenuList && originMenuList.length && !initMergedScreenInfo.menuCode) {
            initMergedScreenInfo.menuCode = originMenuList[0].menuCode;
        }
        setUpdateScreenInfo(initMergedScreenInfo);
        return () => {
            setUpdateScreenInfo(initScreenInfo);
        };
    }, [screenInfo, initOpen, originMenuList]);

    useAsync({
        promise: fetchAuthGroupList,
        immediate: true,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            setGroupList(res.rows ?? []);
        },
        reject: err => {
            console.log({ err });
            setGroupList([]);
        },
    });

    const { promise: getScreenList } = useAsync({
        promise: fetchScreenList,
        fixedParam: { isAll: 'Y', groupNum: userInfo.authGroupNum },
        resolve: res => {
            dispatch(setScreenList(res.rows ?? []));
        },
        reject: err => {
            console.log({ err });
            dispatch(setScreenList([]));
        },
    });

    const saveResolveHandler = () => {
        saveSuccessModalProps.toggleModal();
    };

    const saveErrHandler = ({ data }: any) => {
        if (data && data.code === INDOORPLUS_ERRORS.EXISTED_RESOURCE && data.errorResponse.includes('screenCode')) {
            setSaveErrMsg(t('The same screen ID exists. Please change the screen ID.'));
        } else if (
            data &&
            data.code === INDOORPLUS_ERRORS.WRONG_VALUE &&
            data.errorResponse.includes('Malformed URL')
        ) {
            setSaveErrMsg(t('Please check the URL format.'));
        } else {
            setSaveErrMsg(t('The request failed.', 'ErrorHandler'));
        }
        saveFailureModalProps.toggleModal();
    };

    const { promise: createScreen } = useAsync({
        promise: createScreenApi,
        resolve: saveResolveHandler,
        reject: saveErrHandler,
    });
    const { promise: updateScreen } = useAsync({
        promise: updateScreenApi,
        resolve: saveResolveHandler,
        reject: saveErrHandler,
    });

    const handleCancelButtonClick = () => {
        setUpdateScreenInfo(initScreenInfo);
        toggleModal();
    };

    const uploadFileResultModalProps = useConfirm({
        initModal: false,
        confirmText: t('Files other than JSON cannot be uploaded.', 'ConfirmModal'),
    });

    // 파일 업로드
    // 파일 선택 시 파일 명 상태 저장, json 외의 파일 선택 시 파일 명 초기화 및 확인 모달창
    const handleFile: ChangeEventHandler<HTMLInputElement> = async selected => {
        const { files } = selected.target;
        if (files) {
            if (files[0] && files[0].type === 'application/json') {
                const filenameArr = files[0].name.split('.');
                const filename = filenameArr[0];
                setUpdateScreenInfo(prevState => {
                    return { ...prevState, screenName: filename, show: 'Y' };
                });
                let text = await files[0].text();
                setWidgetInfo(text);
            } else if (files[0] && files[0].type !== 'application/json') {
                setWidgetInfo(null);
                setUpdateScreenInfo(initScreenInfo);
                if (fileInputRef?.current) {
                    fileInputRef.current.value = '';
                }
                uploadFileResultModalProps.toggleModal();
            } else {
                setWidgetInfo(null);
                setUpdateScreenInfo(initScreenInfo);
                if (fileInputRef?.current) {
                    fileInputRef.current.value = '';
                }
            }
        }
    };

    useEffect(() => {
        if (!updateScreenInfo.groupNumList || !updateScreenInfo.groupNumList.length) {
            setUpdateScreenInfo(prevState => ({
                ...prevState,
                groupNumList: groupList.map(group => group.groupNum),
            }));
        }
    }, [updateScreenInfo.groupNumList]);

    return (
        <>
            <Modal
                initModal={initOpen}
                toggleModal={toggleModal}
                headerTitle={t('Screen detail')}
                modalFooter={
                    <div className={'modal-footer'}>
                        <Button className={'btn-lightgray'} onClick={handleCancelButtonClick}>
                            {t('Cancel', 'ConfirmModal')}
                        </Button>
                        <Button className={'btn-secondary'} onClick={saveConfirmModalProps.toggleModal}>
                            {t('Save', 'ConfirmModal')}
                        </Button>
                    </div>
                }
            >
                <div className={'modal-body'}>
                    <div className={'flx-col gap-4 pnt-label-5'}>
                        {!screenInfo && (
                            <Label
                                name={t('Import')}
                                value={
                                    <div className="pnt-file--group w-100">
                                        <input
                                            type="file"
                                            id="dashboardFile"
                                            onChange={handleFile}
                                            accept="application/json"
                                            ref={fileInputRef}
                                        />
                                        <label htmlFor="dashboardFile">
                                            <Icon iconName={'folder_copy'} />
                                            {t('Upload File', 'Button')}
                                        </label>
                                    </div>
                                }
                            />
                        )}
                        <Label
                            name={t('Screen ID')}
                            value={
                                <>
                                    <TextInput
                                        disabled={!!screenInfo?.screenCode}
                                        inputGroupClassName={cx('w-100', 'form-must')}
                                        type={'text'}
                                        name={'screenCode'}
                                        id={'screenCode'}
                                        placeholder={''}
                                        maxlength={25}
                                        value={updateScreenInfo.screenCode || ''}
                                        handleChange={e => {
                                            const { value } = e.target;
                                            setUpdateScreenInfo(prevState => {
                                                return { ...prevState, screenCode: value };
                                            });
                                        }}
                                    />
                                </>
                            }
                        />
                        <Label
                            name={t('Screen Name')}
                            value={
                                <>
                                    <TextInput
                                        inputGroupClassName={cx(screenInfo ? 'w-75' : 'w-100', 'form-must')}
                                        type={'text'}
                                        name={'screenName'}
                                        id={'screenName'}
                                        placeholder={''}
                                        maxlength={25}
                                        value={updateScreenInfo.screenName || ''}
                                        handleChange={e => {
                                            const { value } = e.target;
                                            setUpdateScreenInfo(prevState => {
                                                return { ...prevState, screenName: value };
                                            });
                                        }}
                                    />
                                    {screenInfo && (
                                        <Button
                                            className={'btn-brand ml-2'}
                                            onClick={() => {
                                                const { screenName, widgetInfo } = screenInfo;
                                                widgetInfoExport({
                                                    filename: screenName,
                                                    exportWidgetInfo: widgetInfo,
                                                });
                                            }}
                                        >
                                            {t('Export', 'Button')}
                                        </Button>
                                    )}
                                </>
                            }
                        />

                        <Label
                            name={t('Screen Description')}
                            value={
                                <TextInput
                                    inputGroupClassName={'w-100'}
                                    type={'text'}
                                    name={'description'}
                                    id={'description'}
                                    placeholder={''}
                                    maxlength={100}
                                    value={updateScreenInfo.description || ''}
                                    handleChange={e => {
                                        const { value } = e.target;
                                        setUpdateScreenInfo(prevState => {
                                            return { ...prevState, description: value };
                                        });
                                    }}
                                />
                            }
                        />

                        <Label
                            name={t('Order')}
                            value={
                                <TextInput
                                    inputGroupClassName={'w-100'}
                                    type={'number'}
                                    id={'order'}
                                    handleChange={e => {
                                        const { value } = e.target;
                                        if (parseInt(value) > 100) {
                                            setUpdateScreenInfo(prevState => {
                                                return { ...prevState, order: 100 };
                                            });
                                        } else {
                                            setUpdateScreenInfo((prevState: any) => {
                                                return { ...prevState, order: value };
                                            });
                                        }
                                    }}
                                    name={'order'}
                                    value={updateScreenInfo.order || 0}
                                />
                            }
                        />

                        <Label
                            name={t('Display Menu')}
                            value={
                                <Select
                                    className={'react-select w-100'}
                                    valueKey={'menuCode'}
                                    labelKey={'menuName'}
                                    value={(originMenuList || []).find(
                                        menu => menu.menuCode === updateScreenInfo.menuCode,
                                    )}
                                    options={originMenuList}
                                    customControlStyles={{ minWidth: '100%' }}
                                    customMenuStyles={{ minWidth: '100%' }}
                                    onChange={(selected: any) => {
                                        setUpdateScreenInfo(prevState => ({
                                            ...prevState,
                                            menuCode: selected.menuCode,
                                            menuName: selected.menuName,
                                        }));
                                    }}
                                    isModalSelect
                                />
                            }
                        />

                        <Label
                            name={t('For WebView')}
                            value={
                                <WebViewValidationInputContainer className={'d-flex w-100'}>
                                    <Checkbox
                                        checked={updateScreenInfo.isWebView === 'Y'}
                                        handleChecked={e => {
                                            const isWebView = e.currentTarget.checked ? 'Y' : 'N';
                                            setUpdateScreenInfo(prevState => ({
                                                ...prevState,
                                                isWebView,
                                            }));
                                        }}
                                    />
                                    <WebViewValidationInput screenInfo={updateScreenInfo} />
                                </WebViewValidationInputContainer>
                            }
                        />

                        <Label
                            name={t('External Link')}
                            value={
                                <ExternalLinkWrapper>
                                    <TextInput
                                        type={'url'}
                                        name={'externalLinkUrl'}
                                        id={'externalLinkUrl'}
                                        placeholder={''}
                                        maxlength={255}
                                        value={updateScreenInfo.externalLinkUrl || ''}
                                        handleChange={e => {
                                            const { value } = e.target;
                                            setUpdateScreenInfo(prevState => {
                                                return { ...prevState, externalLinkUrl: value || null };
                                            });
                                        }}
                                    />
                                    <Select
                                        value={externalLinkOptions.find(
                                            opt => opt.value === updateScreenInfo.linkTarget,
                                        )}
                                        options={externalLinkOptions}
                                        customControlStyles={{ minWidth: '100%' }}
                                        customMenuStyles={{ minWidth: '100%' }}
                                        onChange={(selected: any) => {
                                            setUpdateScreenInfo(prevState => ({
                                                ...prevState,
                                                linkTarget: selected.value,
                                            }));
                                        }}
                                        isModalSelect
                                    />
                                </ExternalLinkWrapper>
                            }
                        />

                        <Label
                            name={t('Authority')}
                            value={
                                <SearchableSelect
                                    isModalSelect
                                    className={'w-100'}
                                    title={t('Authority')}
                                    valueKey={'groupNum'}
                                    labelKey={'groupName'}
                                    selected={updateScreenInfo.groupNumList ?? []}
                                    data={groupList}
                                    onChange={selected => {
                                        setUpdateScreenInfo(prevState => ({
                                            ...prevState,
                                            groupNumList: selected?.map((group: DisplayGroupInfo) => group.groupNum),
                                        }));
                                    }}
                                />
                            }
                        />
                    </div>
                </div>
            </Modal>
            <ConfirmModal {...uploadFileResultModalProps} />
            <ConfirmModal {...saveConfirmModalProps} />
            <ConfirmModal {...validationModalProps} />
            <ConfirmModal {...saveSuccessModalProps} />
            <ConfirmModal {...saveFailureModalProps} />
        </>
    );
};

const WebViewValidationInputContainer = styled.div`
    align-items: center;
    .devided-input-btn-text {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: auto 25px;
        flex-grow: 1;
    }
    .pnt-input--group .pnt-input {
        border-radius: 2px 0 0 2px;
    }
    #root .app-container & .pnt-btn.btn-trans.devided-btn {
        background-color: #f6f5f8;
        border: 1px solid #e8e6ef !important;
        border-radius: 0 2px 2px 0;
        position: relative;
        transform: unset;
        top: unset;
        .material-icons-round {
            line-height: 1.5;
        }
    }
    #root .app-container.app-theme-pnt-dark & .pnt-btn.btn-trans.devided-btn {
        background-color: #414147;
        border-color: #414147 !important;
        border-left-color: #5f5f68 !important;
    }
`;

const ExternalLinkWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: auto 100px;
    column-gap: 0.5rem;
    .pnt-input--group {
        min-width: 4rem;
        width: unset;
    }
    .react-select {
        width: unset;
    }
`;

const WebViewValidationInput = ({ screenInfo }: { screenInfo: Partial<OriginScreenInfo> }) => {
    const t = useTranslation('Screen');
    const autoCloseRef = useRef<any>(null);

    const [tooltipOpen, setTooltipOpen] = useState(false);

    if (screenInfo.isWebView !== 'Y') {
        return null;
    }
    if (!screenInfo.screenCode?.trim()) {
        return <>t('Please enter the screen code.')</>;
    }
    if (!screenInfo.menuCode) {
        return <>t('Please select a menu.')</>;
    }
    const embedPath = `${window.location.origin}${window.location.pathname}#${A_PATH_EMBED}/${screenInfo.menuCode}/${screenInfo.screenCode}`;

    const autoCloseTooltip = () => {
        if (autoCloseRef.current) {
            clearTimeout(autoCloseRef.current);
            autoCloseRef.current = null;
        }
        autoCloseRef.current = setTimeout(() => {
            setTooltipOpen(false);
            autoCloseRef.current = null;
        }, 2000);
    };

    return (
        <>
            <TextInput
                id={'copyWebViewPath'}
                inputGroupClassName={'devided-input-btn-text'}
                buttonClassName={'devided-btn'}
                readonly
                name={'embedPath'}
                value={embedPath}
                iconName={tooltipOpen ? 'check' : 'content_copy'}
                buttonHandleClick={() => {
                    copyToClipboard(embedPath, () => {
                        setTooltipOpen(true);
                        autoCloseTooltip();
                    });
                }}
            />
            <Tooltip isOpen={tooltipOpen} target={'copyWebViewPath'} autohide={false}>
                {t('Copied')}
            </Tooltip>
        </>
    );
};

export default withRouter(ScreenDetailPopup);
