import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import useTranslation from '@hooks/useTranslation';
import useAsync from '@hooks/useAsync';
import { getRefrigeratorSensorMonthlyLogApi, updateRefrigeratorSensorDailyLogApi } from '@api/monitoring';
import MonthlyTable from './Components/MonthlyTable';
import DatePicker from '@components/DatePicker';
import { useRefrigeratorSensorContext, useRefrigeratorSensorDispatch } from '../../index';
import moment from 'moment';
import { Button } from '@components';
import ReactToPrint from 'react-to-print';
import useConfirmModal from '@hooks/useConfirmModal';
import { stopUpdatingData, startUpdatingData } from '../../refrigeratorSensorSlice';
import ConfirmModal from '@components/Modal/ConfirmModal';
import TableInput from './Components/TableInput';

const TIME_TITLE = 'AM 9:00 / PM 5:00';
const EMPTY_CONTENT = '-';

const RefrigeratorSensorMonthlyLog = () => {
    const t = useTranslation('RefrigeratorSensor');
    const buttonT = useTranslation('Button');
    const dispatch = useRefrigeratorSensorDispatch();
    const { sensorInfo, sensorNum, isUpdatingData } = useRefrigeratorSensorContext();
    const monthlyReportRef = useRef();
    const [currentMonthlyLog, setCurrentMonthlyLog] = useState({});
    const [monthlyLogRows, setMonthlyLogRows] = useState([]);
    const [measurementTime, setMeasurementTime] = useState(TIME_TITLE);

    // 월간대장 리스트 조회
    const { promise: getRefrigeratorSensorMonthlyLog } = useAsync({
        promise: getRefrigeratorSensorMonthlyLogApi,
        param: {
            startDate: moment().startOf('date').unix(),
            endDate: moment().endOf('date').unix(),
            sensorNum: sensorNum,
        },
        resolve: res => {
            if (res.fridgeLogValues) {
                setMeasurementTime(getMeasurementTime(res.fridgeLogValues));
            }
            setCurrentMonthlyLog(res);
        },
        reject: err => console.error(err),
    });
    // 월 선택
    const [selectedDate, setSelectedDate] = useState(moment().unix());
    const handleChangeDate = date => {
        setSelectedDate(date);
    };

    const toggleSubmitModal = () => {
        if (isUpdatingData) {
            dispatch(stopUpdatingData());
        } else {
            dispatch(startUpdatingData());
        }
    };

    const { Modal: SaveSuccessModal, toggleModal: toggleSaveSuccessModal } = useConfirmModal({
        confirmText: t('Save is complete'),
        removeCancel: true,
    });

    const { Modal: SaveFailureModal, toggleModal: toggleSaveFailureModal } = useConfirmModal({
        confirmText: t('The save failed'),
        removeCancel: true,
    });

    const { promise: updateDailyLog } = useAsync({
        promise: updateRefrigeratorSensorDailyLogApi,
        resolve: res => {
            toggleSaveSuccessModal();
        },
        reject: err => {
            console.error(err);
            toggleSaveFailureModal();
        },
    });

    const updateCurrentMonthlyLog = ({ logNum, contents }) => {
        setCurrentMonthlyLog(prevState => {
            const { fridgeLogValues } = prevState;
            for (const day in fridgeLogValues) {
                if (fridgeLogValues[day][0].logNum === logNum) {
                    fridgeLogValues[day][0].contents = contents;
                }
            }

            return { ...prevState, fridgeLogValues };
        });
    };

    const submit = () => {
        const values = [];
        const { fridgeLogValues } = currentMonthlyLog;
        for (const day in fridgeLogValues) {
            values.push(...fridgeLogValues[day].filter(({ targetNum, logNum }) => targetNum && logNum));
        }
        updateDailyLog({ deviceList: [{ deviceNum: sensorNum, values }] });
    };

    // 월간대장 테이블 Column 정보
    const initColumns = React.useMemo(
        () => [
            { header: 'Day', accessor: 'day' },
            { header: 'Time', accessor: 'firstTime' },
            { header: 'Temp', accessor: 'firstTemp', outOfControl: 'firstOutOfControl' },
            { header: 'Humidity', accessor: 'firstHumidity', outOfControl: 'firstOutOfControl' },
            { header: 'Time', accessor: 'secondTime' },
            { header: 'Temp', accessor: 'secondTemp', outOfControl: 'secondOutOfControl' },
            { header: 'Humidity', accessor: 'secondHumidity', outOfControl: 'secondOutOfControl' },
            { header: 'Manager', accessor: 'firstManager' },
            { header: 'Note', accessor: 'contents' },
        ],
        [],
    );

    /**
     * 냉장고별 월간대장 표 데이터 생성
     *
     * @param res 월간대장 응답값
     */
    const initTableRows = res => {
        if (!res) {
            return [];
        }
        const { maxControlLimit: max, minControlLimit: min, fridgeLogValues } = res;
        let rows = [];
        for (const day in fridgeLogValues) {
            // 날짜 변경
            const convertRegDate = value => (value ? moment.unix(value).format('HH:mm') : EMPTY_CONTENT);
            // 온도 변경
            const convertTemp = value => (value ? value.toFixed(2) : EMPTY_CONTENT);
            const contentsText = fridgeLogValues[day][0] ? fridgeLogValues[day][0].contents : null;
            // row 생성
            const row = {
                day: day,
                firstTime: convertRegDate(fridgeLogValues[day][0] ? fridgeLogValues[day][0].regDate : null),
                firstTemp: convertTemp(fridgeLogValues[day][0] ? fridgeLogValues[day][0].temperature : null),
                firstHumidity: convertTemp(fridgeLogValues[day][0] ? fridgeLogValues[day][0].humidity : null),
                secondTime: convertRegDate(fridgeLogValues[day][1] ? fridgeLogValues[day][1].regDate : null),
                secondTemp: convertTemp(fridgeLogValues[day][1] ? fridgeLogValues[day][1].temperature : null),
                secondHumidity: convertTemp(fridgeLogValues[day][1] ? fridgeLogValues[day][1].humidity : null),
                firstManager: '',
                contents: (
                    <TableInput
                        initValue={contentsText}
                        className="custom-bg-daily-report text-ellipsis text-start px-2"
                        title={contentsText}
                        maxLength={50}
                        handleChange={inputValue => {
                            updateCurrentMonthlyLog({
                                logNum: fridgeLogValues[day][0].logNum,
                                contents: inputValue,
                            });
                        }}
                    />
                ),
            };
            row.firstOutOfControl = row.firstTemp === null ? false : min > row.firstTemp || max < row.firstTemp;
            row.secondOutOfControl = row.secondTemp === null ? false : min > row.secondTemp || max < row.secondTemp;
            rows.push(row);
        }
        return rows;
    };

    // Mount/Unmount 시점
    useEffect(() => {
        getRefrigeratorSensorMonthlyLog({
            startDate: moment(selectedDate * 1000)
                .startOf('month')
                .unix(),
            endDate: moment(selectedDate * 1000)
                .endOf('month')
                .unix(),
            monthLastDay: moment(selectedDate * 1000)
                .endOf('month')
                .date(),
            sensorNum: sensorNum,
        });

        return () => {
            setCurrentMonthlyLog([]);
            setMonthlyLogRows([]);
        };
    }, [sensorNum, selectedDate]);

    // 서버 응답값 상태변화에 따른 표 데이터 생성
    useEffect(() => {
        const initRows = initTableRows(currentMonthlyLog);
        setMonthlyLogRows(initRows);
    }, [currentMonthlyLog]);

    // 프린트 대상 컴포넌트
    const reactToPrintContent = React.useCallback(() => {
        return monthlyReportRef.current;
    }, [monthlyReportRef.current]);

    // 온도 정상 허용 최대값
    const temperatureMax = currentMonthlyLog.temperatureMaxControlLimit;
    // 온도 정상 허용 최소값
    const temperatureMin = currentMonthlyLog.temperatureMinControlLimit;

    // 습도 정상 허용 최대값
    const humidityMax = currentMonthlyLog.humidityMaxControlLimit;
    // 습도 정상 허용 최소값
    const humidityMin = currentMonthlyLog.humidityMinControlLimit;

    return (
        <PrintContainer ref={monthlyReportRef}>
            <PrintDisplayNoneContainer>
                <Title>{`${sensorInfo[sensorNum].targetName} ${t(
                    'Refrigerator temperature and humidity management ledger',
                )}`}</Title>
                <FlexSubTitle className="mb-3">
                    <div>
                        <SubTitle>{t('Department')}</SubTitle> :{' '}
                        <Value>{currentMonthlyLog.wardInfo ? currentMonthlyLog.wardInfo : EMPTY_CONTENT}</Value>
                        <SubTitle>{t('Measurement Time')}</SubTitle> : <Value>{measurementTime}</Value>
                        <div>
                            <SubTitle>{t('Permitted Range')}</SubTitle> :
                            <Value>{`${t('Temperature')}:${
                                temperatureMax !== null && temperatureMin !== null
                                    ? `${temperatureMin ?? EMPTY_CONTENT} ~ ${temperatureMax ?? EMPTY_CONTENT} (℃)`
                                    : `${EMPTY_CONTENT} (℃)`
                            } / ${t('Humidity')}:${
                                humidityMin !== null && humidityMax !== null
                                    ? `${humidityMin ?? EMPTY_CONTENT} ~ ${humidityMax ?? EMPTY_CONTENT} (%)`
                                    : `  ${EMPTY_CONTENT} (%)`
                            }`}</Value>
                        </div>
                    </div>
                    <FlexSubTitle>
                        <SubTitle className="mr-3">{t('Inspection Year Month')}</SubTitle>
                        <PrintButtonContainer>
                            <DatePicker
                                value={selectedDate}
                                valueType={'s'}
                                handleChange={selected => handleChangeDate(selected)}
                                maxDate={moment.now()}
                                withoutDay
                                showMonthYearPicker
                            />
                            <ReactToPrint
                                trigger={() => (
                                    <Button className="pnt-btn btn-secondary ml-3">{buttonT('Print')}</Button>
                                )}
                                targetComponent={monthlyReportRef.current}
                                content={reactToPrintContent}
                            />
                        </PrintButtonContainer>
                        <PrintValueContainer>
                            <Value>{moment(selectedDate * 1000).format('YYYY-MM')}</Value>
                        </PrintValueContainer>
                    </FlexSubTitle>
                </FlexSubTitle>
            </PrintDisplayNoneContainer>
            <PrintDisplayUnsetContainer>
                <h5 className="text-center ">{`${sensorInfo[sensorNum].targetName} ${t(
                    'Refrigerator temperature and humidity management ledger',
                )}`}</h5>
                <div className="border mb-3">
                    <div className="d-flex align-items-center border-bottom">
                        <div className="flx-1 text-center py-1 px-2">
                            <SubTitle>{t('Inspection Year Month')}</SubTitle>
                        </div>
                        <div className="flx-1 py-1 px-2 border-start">
                            <PrintTextContainer>{moment(selectedDate * 1000).format('YYYY-MM')}</PrintTextContainer>
                        </div>
                        <div className="flx-1 text-center py-1 px-2 border-start">
                            <SubTitle>{t('Permitted Range')}</SubTitle>
                        </div>
                        <div className="flx-1 py-1 px-2 border-start">
                            <PrintTextContainer>{`${t('Temperature')}:${
                                temperatureMax !== null && temperatureMin !== null
                                    ? `${temperatureMin ?? EMPTY_CONTENT} ~ ${temperatureMax ?? EMPTY_CONTENT} (℃)`
                                    : `  ${EMPTY_CONTENT} (℃)`
                            } / ${t('Humidity')}:${
                                humidityMin !== null && humidityMax !== null
                                    ? `${humidityMin ?? EMPTY_CONTENT} ~ ${humidityMax ?? EMPTY_CONTENT} (%)`
                                    : `  ${EMPTY_CONTENT} (%)`
                            }`}</PrintTextContainer>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="flx-1 text-center py-1 px-2">
                            <SubTitle>{t('Measurement Time')}</SubTitle>
                        </div>
                        <div className="flx-1 py-1 px-2 border-start">
                            <PrintTextContainer>{measurementTime}</PrintTextContainer>
                        </div>
                        <div className="flx-1 text-center py-1 px-2 border-start">
                            <SubTitle>{t('Department')}</SubTitle>
                        </div>
                        <div className="flx-1 py-1 px-2 border-start">
                            <PrintTextContainer>
                                {currentMonthlyLog.wardInfo ? currentMonthlyLog.wardInfo : EMPTY_CONTENT}
                            </PrintTextContainer>
                        </div>
                    </div>
                </div>
            </PrintDisplayUnsetContainer>
            <Container>
                <MonthlyTable columns={initColumns} rows={monthlyLogRows} />
            </Container>

            <ConfirmModal
                initModal={isUpdatingData}
                confirmText={t('Do you want to save?')}
                toggleModal={toggleSubmitModal}
                okCallback={submit}
            />
            <SaveSuccessModal />
            <SaveFailureModal />
        </PrintContainer>
    );
};

const getMeasurementTime = monthlyLog => {
    const measurementTimeCandidates = monthlyLog[1].map(() => ({}));
    for (const day in monthlyLog) {
        monthlyLog[day].forEach(({ regDate }, i) => {
            const regDateTime = regDate ? moment.unix(regDate).format('HH:mm') : null;
            if (regDateTime !== null) {
                if (measurementTimeCandidates[i][regDateTime]) {
                    measurementTimeCandidates[i][regDateTime]++;
                } else {
                    measurementTimeCandidates[i][regDateTime] = 1;
                }
            }
        });
    }
    return measurementTimeCandidates
        .map(candidates => {
            let resultTime = null;
            for (const time in candidates) {
                if (!resultTime || candidates[time] > candidates[resultTime]) {
                    resultTime = time;
                }
            }
            return resultTime ?? '-';
        })
        .join(' / ');
};

const Title = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0.5rem;
    @media print {
        color: black;
        font-size: 14px;
    }
`;

const FlexSubTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media print {
        color: black;
        align-items: flex-end;
    }
`;

const SubTitle = styled.span`
    min-width: 56px;
    text-align: justify;
    text-align-last: justify;
    display: inline-block;
    @media print {
        min-width: 48px;
        color: black;
        font-size: 12px;
    }
`;

const Value = styled.b`
    margin-right: 3rem;
    @media print {
        color: black;
        font-size: 12px;
    }
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, auto);
    grid-template-rows: repeat(auto-fill, auto);
    grid-gap: 10px;
`;

const PrintContainer = styled.div`
    padding-bottom: 1rem;
    @media print {
        margin: 8mm 8mm;
        padding-bottom: 0;
    }
`;
const PrintButtonContainer = styled.div`
    display: flex;
    @media print {
        display: none;
    }
`;
const PrintValueContainer = styled.div`
    display: none;
    margin-right: -2rem;
    @media print {
        display: flex;
    }
`;

const PrintTextContainer = styled.span`
    @media print {
        color: black;
        font-size: 12px;
    }
`;
const PrintDisplayNoneContainer = styled.div`
    display: unset;
    @media print {
        display: none;
    }
`;
const PrintDisplayUnsetContainer = styled.div`
    display: none;
    @media print {
        display: unset;
        @page {
            padding: 1rem;
        }
    }
`;
export default React.memo(RefrigeratorSensorMonthlyLog);
