import React, { useEffect, useRef } from 'react';
import { useAppSelector, useAsync } from '@hooks';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import { EVENT_TYPE_EMERGENCY_SENSOR_ITEM_STATUS_EVENT } from '@reducer/SocketInfo';
import { RealtimeEmergencySensorItemStatusData } from '@util/socket/socketData';
import { AlertSettingMap, useAnomalyAlertFilter } from '@hooks/EmergencyAlert/useEmergencyAlert';
import { getAnomalyAlertSoundApi } from '@api/sh/patientAnomalyAlertSetting';
import { useDispatch } from 'react-redux';
import { getSnoozeKey, setAnomalyAlertSoundList } from '@reducer/EmergencyAlert';

const AnomalyAlertSound = () => {
    const dispatch = useDispatch();
    const audiosRef = useRef<Record<string, HTMLAudioElement>>({});
    const ignoreDiffState = useRef<{
        anomalyAlertSettingList: AlertSettingMap;
        anomalyAlertSnoozeList: Record<string, number>;
    }>({ anomalyAlertSettingList: {}, anomalyAlertSnoozeList: {} });
    const { anomalyAlertSettingList, anomalyAlertSnoozeList } = useAppSelector(state => state.EmergencyAlert);

    useEffect(() => {
        ignoreDiffState.current = { anomalyAlertSettingList, anomalyAlertSnoozeList };
    }, [anomalyAlertSettingList, anomalyAlertSnoozeList]);

    const {
        state: { response: soundInfo },
    } = useAsync({
        promise: getAnomalyAlertSoundApi,
        immediate: true,
        resolve: res => {
            dispatch(setAnomalyAlertSoundList(res));
        },
    });

    const socketFilter = useAnomalyAlertFilter();

    useSocketEvent({
        name: EVENT_TYPE_EMERGENCY_SENSOR_ITEM_STATUS_EVENT,
        filterConfig: socketFilter,
        handler: data => {
            const {
                event: { alertOpen },
                unixTime,
                alertSettingGroupNum,
            } = data as RealtimeEmergencySensorItemStatusData;

            const { anomalyAlertSettingList } = ignoreDiffState.current;
            const setting = anomalyAlertSettingList[String(alertSettingGroupNum)];
            if (setting) {
                const audios = audiosRef.current;
                const audio = audiosRef.current[setting.alertSound?.uri];
                if (alertOpen === 'Y' && audio) {
                    const { anomalyAlertSnoozeList } = ignoreDiffState.current;
                    const snoozeEndTime = anomalyAlertSnoozeList[getSnoozeKey(data)];
                    if (snoozeEndTime && snoozeEndTime > unixTime) {
                        return;
                    }

                    for (const uri in audios) {
                        audios[uri].pause();
                        audios[uri].currentTime = 0;
                    }

                    audio.volume = typeof setting.alertSoundVolume === 'number' ? setting.alertSoundVolume / 100 : 1;
                    audio.play();
                }
            }
        },
    });

    return (
        <div className={'d-none'}>
            {(soundInfo?.alertSounds || []).map(sound => {
                return (
                    <audio
                        key={sound.uri}
                        ref={ref => {
                            if (ref) {
                                audiosRef.current[sound.uri] = ref;
                            }
                        }}
                        src={soundInfo?.alertSoundBaseUrl + sound.uri}
                    />
                );
            })}
        </div>
    );
};

export default AnomalyAlertSound;
