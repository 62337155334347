import React, { useState } from 'react';
import { useAsync, useColumns, useTranslation } from '@hooks';
import * as column from '../column';
import InnerItemCard from '@components/Card/InnerItemCard';
import Search from './Components/Search';
import { Table } from '@components';
import styled from 'styled-components';
import BloodDrawModal from '../../BloodDrawModal';
import { useOutpatientBloodDrawStatusContext, useOutpatientBloodDrawStatusDispatch } from '../../index';
import { getSelectedOutpatientBloodDrawApi } from '@api/monitoring';
import { setOutpatientListParam, setSelectedOutpatient } from '../../OutpatientBloodDrawStatusSlice';
import moment from 'moment';

const OutpatientBloodDrawStatusList = ({ outpatientBloodList }) => {
    const t = useTranslation('OutpatientBloodDrawStatus');
    const [modal, setModal] = useState(false);
    const { selectedOutpatient } = useOutpatientBloodDrawStatusContext();
    const dispatch = useOutpatientBloodDrawStatusDispatch();

    const handlePageChange = pageIndex => {
        dispatch(
            setOutpatientListParam(prevState => {
                return { ...prevState, page: pageIndex };
            }),
        );
    };

    const columns = useColumns(
        [
            column.category(),
            column.ward(),
            column.speciality(),
            column.doctor(),
            column.id(),
            column.patientName(),
            column.examCode(),
            column.examName(),
            column.reservedDate({
                Cell: ({ value }) => <span>{moment.unix(value).format('YYYY-MM-DD')}</span>,
            }),
            column.reservedTime({
                Cell: ({ value }) => <span>{moment.unix(value).format('HH:mm')}</span>,
            }),
            column.arrivedTime({
                Cell: ({ value }) => <span>{moment.unix(value).format('HH:mm')}</span>,
            }),
            column.arrivedNumber(),
            column.inspectOrNot({
                Cell: ({ value }) => {
                    return <span>{value === 'Y' ? 'O' : 'X'}</span>;
                },
            }),
        ],
        t,
        [],
    );
    const { promise: getSelectedOutpatientBloodTestInfo } = useAsync({
        promise: getSelectedOutpatientBloodDrawApi,
        resolve: res => {
            dispatch(setSelectedOutpatient(res));
        },
        reject: err => {
            console.error(err);
        },
    });
    const handleModal = data => {
        const { bloodTestNum } = data;
        setModal(!modal);
        getSelectedOutpatientBloodTestInfo({ bloodTestNum });
    };

    return (
        <Container>
            <InnerItemCard
                className={'h-100'}
                bodyClassName={'h-100'}
                header={{
                    title: t('Blood draw patient list'),
                }}
            >
                <Search />
                <TableContainer className={'h-90'}>
                    <Table
                        data={outpatientBloodList}
                        columns={columns}
                        onTrClick={handleModal}
                        onPageChange={handlePageChange}
                        dynamicRowHeight={true}
                    />
                </TableContainer>
            </InnerItemCard>
            {selectedOutpatient && <BloodDrawModal modal={modal} setModal={setModal} />}
        </Container>
    );
};

const Container = styled.div`
    height: 550px;
    margin-top: 5px;
`;
const TableContainer = styled.div`
    height: 250px;
    margin-bottom: 5px;
`;

export default OutpatientBloodDrawStatusList;
