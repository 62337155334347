import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { deleteScreenApi } from '@api/common/screen';
import { ConfirmModal,Icon } from '@components';
import { useAsync, useConfirm, useTranslation } from '@hooks';
import { widgetInfoExport } from '../../../../Components/Screen/util';
import { selectScreen, useScreenListDispatchContext } from '../slice';
import { deleteMenuScreen } from '@reducer/CustomMenu';
import { useDispatch } from 'react-redux';
import { OriginScreenInfo } from '../types';

interface Props {
    row: {
        original: OriginScreenInfo;
    };
}
const ScreenSubMenu = ({ row: { original } }: Props) => {
    const storeDispatch = useDispatch();
    const dispatch = useScreenListDispatchContext();
    const t = useTranslation('Screen');

    const { promise: deleteScreen } = useAsync({
        promise: deleteScreenApi,
        resolve: () => {
            storeDispatch(deleteMenuScreen(original));
        },
    });

    const toggleDeleteModal = () => {
        deleteModalProps.toggleModal();
    };

    const handleDelete = (screenInfo: OriginScreenInfo) => {
        deleteScreen(screenInfo);
    };

    const deleteModalProps = useConfirm({
        header: { title: t('Confirm', 'ConfirmModal') },
        confirmText: t('Do you want to delete the screen?'),
        okCallback: handleDelete,
        callbackParam: original,
    });

    return (
        <>
            <UncontrolledDropdown className="w-100">
                <DropdownToggle tag={'div'} className={'flex-center cursor-pointer'}>
                    <Icon iconName={'menu'} />
                </DropdownToggle>
                <DropdownMenu className={'grid-sub-menu'}>
                    <DropdownItem
                        onClick={() => {
                            dispatch(selectScreen(original));
                        }}
                    >
                        <Icon iconName={'info_outline'} className={'md-18'} />
                        <span className="ml-2">{t('Screen detail')}</span>
                    </DropdownItem>
                    {!isMobile && (
                        <Link to={`/setting/screen/edit/${original.screenCode}`}>
                            <DropdownItem>
                                <Icon iconName={'add_to_queue'} className={'md-18'} />
                                <span className="ml-2">{t('Edit the screen')}</span>
                            </DropdownItem>
                        </Link>
                    )}
                    <DropdownItem onClick={toggleDeleteModal}>
                        <Icon iconName={'delete_outline'} className={'md-18'} />
                        <span className="ml-2">{t('Delete the screen')}</span>
                    </DropdownItem>
                    {/*
                    대시보드 목록 버튼에 내보내기 버튼 추가
                    widgetInfo 데이터가 없을 경우 hidden
                       */}
                    {original.widgetInfo && (
                        <DropdownItem
                            onClick={() => {
                                if (original?.widgetInfo)
                                    widgetInfoExport({
                                        filename: `${original.screenName}.json`,
                                        exportWidgetInfo: JSON.parse(original.widgetInfo),
                                    });
                            }}
                        >
                            <Icon iconName={'exit_to_app'} className={'md-18'} />
                            <span className="ml-2">{t('Export')}</span>
                        </DropdownItem>
                    )}
                </DropdownMenu>
            </UncontrolledDropdown>
            <ConfirmModal {...deleteModalProps} />
        </>
    );
};

export { ScreenSubMenu };
