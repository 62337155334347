import { getOutpatientUsageDay, postOutpatientUsageDayExcel } from '@api/so/outpatient';
import { useAsync, useTranslation } from '@hooks';
import React, { useRef, useState } from 'react';
import InsightBox, { BoxBodyText, HighlightedText } from '../Components/InsightBox';
import SearchGroupSO from '../Components/SearchGroupSO';
import WidgetCard from '../../Components/WidgetCard';
import { useAsyncExcel } from '../../util/useAsyncExcel';
import useDepartmentOptions from '../util/useDepartmentOptions';
import useRangeDateSO from '../util/useRangeDateSO';
import Chart from './Chart';
import { isValidData } from '../util/checkData';
import NoDataBlock from '../../Components/NoDataBlock';
import { Trans } from 'react-i18next';
import moment from 'moment';
import useDateTypeOptions from '../util/useDateTypeOptions';
import { LoadingBlock } from '@components';

// 외래 사용량 분석(요일별)
const OutpatientUsageAnalysisByDay = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();
    const t = useTranslation('OutpatientReport');

    const departmentValues = useDepartmentOptions();
    const dateTypeValues = useDateTypeOptions();
    const dateValues = useRangeDateSO(dateTypeValues.dateType);
    const [isSaturday, setIsSaturday] = useState(false);

    const { state, promise: getData } = useAsync({
        promise: getOutpatientUsageDay,
        immediate: true,
        param: {
            departmentCode: departmentValues.department.toString(),
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
        },
        reject: err => console.error(err),
    });
    const data = state.response ? state.response.rows : [];
    const { departmentName, totalCount, peakWeekDay } = state.response ? state.response.additionalDatas : {};

    const triggerDownload = useAsyncExcel({
        promise: postOutpatientUsageDayExcel,
        param: {
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
            departmentCode: departmentValues.department.toString(),
            columnMetas: [
                {
                    key: 'regDay',
                    name: t('Date(day)'),
                },
                {
                    key: 'departmentName',
                    name: t('Department name'),
                },
                {
                    key: 'departmentCode',
                    name: t('Department code'),
                },
                {
                    key: 'kioskCountMon',
                    name: `${t('Usage (Mon)')} (${t('Cases')})`,
                },
                {
                    key: 'kioskRateMon',
                    name: `${t('Usage Rate(Mon)')} (%)`,
                },
                {
                    key: 'kioskCountTue',
                    name: `${t('Usage (Tue)')} (${t('Cases')})`,
                },
                {
                    key: 'kioskRateTue',
                    name: `${t('Usage Rate(Tue)')} (%)`,
                },
                {
                    key: 'kioskCountWed',
                    name: `${t('Usage (Wed)')} (${t('Cases')})`,
                },
                {
                    key: 'kioskRateWed',
                    name: `${t('Usage Rate(Wed)')} (%)`,
                },
                {
                    key: 'kioskCountThu',
                    name: `${t('Usage (Thu)')} (${t('Cases')})`,
                },
                {
                    key: 'kioskRateThu',
                    name: `${t('Usage Rate(Thu)')} (%)`,
                },
                {
                    key: 'kioskCountFri',
                    name: `${t('Usage (Fri)')} (${t('Cases')})`,
                },
                {
                    key: 'kioskRateFri',
                    name: `${t('Usage Rate(Fri)')} (%)`,
                },
            ],
            fileName:
                'so_kioskData_' +
                moment.unix(dateValues.startDate).format('YYYYMMDD') +
                '_' +
                moment.unix(dateValues.endDate).format('YYYYMMDD'),
            zipFileName: 'so_usage_by_day',
        },
        reject: err => console.error(err),
    });

    const handleDownloadClick = () => {
        if (!isValidData(data)) {
            return;
        }

        triggerDownload();
    };

    const handleSaturday = checked => {
        setIsSaturday(checked.target.checked);
    };

    return (
        <WidgetCard widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
            <SearchGroupSO
                showDateType
                dateTypeValues={dateTypeValues}
                showDepartment
                departmentValues={departmentValues}
                dateValues={dateValues}
                getData={getData}
                showDownload={isValidData(data)}
                handleDownload={handleDownloadClick}
                showSaturday
                handleSaturday={handleSaturday}
            />

            <LoadingBlock blocking={state.isLoading}>
                {isValidData(data) ? (
                    <>
                        <InsightBox>
                            <BoxBodyText>
                                <Trans
                                    t={t}
                                    values={{ departmentName, totalCount, peakWeekDay }}
                                    components={[<HighlightedText />]}
                                >
                                    {data.length >= 2
                                        ? 'During the inquiry period, the total kiosk usage for the selected departments was <0>{{totalCount}}</0>, with the highest usage day being <0>{{peakWeekDay}}</0>.'
                                        : 'During the inquiry period, the total kiosk usage for <0>{{departmentName}}</0> was <0>{{totalCount}}</0>, with the highest usage day being <0>{{peakWeekDay}}</0>.'}
                                </Trans>
                            </BoxBodyText>
                        </InsightBox>
                        <Chart isSaturday={isSaturday} data={data} />
                    </>
                ) : (
                    <NoDataBlock text={t('No data')} />
                )}
            </LoadingBlock>
            {children}
        </WidgetCard>
    );
};

export default OutpatientUsageAnalysisByDay;
