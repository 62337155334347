import React from 'react';
import cx from 'classnames';
import styles from '@asset/pnt/widgets/RefrigeratorSensor.module.scss';
import { checkLostSignal } from '@util/mappInfo';
import SensorInfo from './SensorInfo';
import RangeBar from './RangeBar';
import { useTranslation } from '@hooks';
import Battery from './Battery';
import moment from 'moment';
import { Icon } from '@components';

const STATUS = {
    NORMAL: { ICON: 'done', COLOR: 'bg-success', STATUS_COLOR: 'bg-success bg-dark' },
    WARNING: { ICON: 'priority_high', COLOR: 'bg-warning', STATUS_COLOR: 'bg-warning bg-dark' },
    CRITICAL: { ICON: 'close', COLOR: 'bg-danger', STATUS_COLOR: 'bg-danger bg-d-2' },
    OUTOFRANGE: { ICON: 'question_mark', COLOR: 'bg-secondary', STATUS_COLOR: 'bg-secondary bg-d-3' },
    LOSTSIGNAL: { ICON: 'mobiledata_off', COLOR: 'bg-info', STATUS_COLOR: 'bg-info bg-d-3' },
    EMPTY: { ICON: 'remove', COLOR: 'bg-depth-9', STATUS_COLOR: 'bg-gray' },
    NONE: { ICON: 'remove', COLOR: 'bg-depth-9', STATUS_COLOR: 'bg-gray' },
};

const SENSOR_ITEMS = {
    BATTERY: 'BATTERY',
    TEMPERATURE: 'TEMPERATURE',
    HUMIDITY: 'HUMIDITY',
};

const SensorStatus = ({ sensorItems, lostSignalState, modDate }) => {
    const t = useTranslation('RefrigeratorSensor');

    const BATTERY_STATUS = sensorItems?.[SENSOR_ITEMS.BATTERY];

    return (
        <>
            <div className={'flx-col w-100 h-90 flex-center'}>
                {sensorItems &&
                    Object.entries(sensorItems)?.map(([key, value]) => {
                        if (key === SENSOR_ITEMS.BATTERY) return null;

                        const {
                            sensorNum,
                            status,
                            sensingType,
                            measurementUnit,
                            currentValue,
                            minRangeLimit,
                            minRiskLimit,
                            minControlLimit,
                            maxControlLimit,
                            maxRiskLimit,
                            maxRangeLimit,
                        } = value;

                        const statusKey = checkLostSignal(lostSignalState) ? 'LOSTSIGNAL' : status ? status : 'EMPTY';
                        const containHumidity = sensorItems.hasOwnProperty('HUMIDITY');

                        return (
                            <div className={'flx-row w-100'} key={`${key}-${value.sensingType}`}>
                                <div
                                    key={sensorNum}
                                    className={cx(
                                        containHumidity ? styles.sensor_imgs : styles.sensor_img,
                                        STATUS[statusKey].COLOR,
                                    )}
                                >
                                    <span className={cx(key === 'TEMPERATURE' ? 's_temperature' : 's_humidity')}></span>
                                    <div className={cx(styles.status, STATUS[statusKey].STATUS_COLOR)}>
                                        <Icon iconName={STATUS[statusKey].ICON} />
                                    </div>
                                </div>
                                <div className="ml-3 flx-1 flex-column align-start">
                                    <div className={cx('w-100', containHumidity ? 'flx-row' : 'flx-col')}>
                                        <SensorInfo
                                            value={currentValue}
                                            status={status}
                                            measure={`${t(sensingType)}${measurementUnit}`}
                                            containHumidity={containHumidity}
                                            lostSignal={lostSignalState}
                                        />
                                        <RangeBar
                                            currentTemperature={currentValue}
                                            range={{
                                                minRangeLimit,
                                                minRiskLimit,
                                                minControlLimit,
                                                maxControlLimit,
                                                maxRiskLimit,
                                                maxRangeLimit,
                                            }}
                                            status={status}
                                            lostSignal={lostSignalState}
                                            containHumidity={containHumidity}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
            <div className="flx-row justify-content-between align-items-end opacity-8 mt-1">
                <Battery value={BATTERY_STATUS?.currentValue} unit={BATTERY_STATUS?.measurementUnit} />
                <p className="pnt-txt s-10">
                    {modDate ? moment(modDate * 1000).format('YYYY-MM-DD (HH:mm:ss)') : '- ( - )'}
                </p>
            </div>
        </>
    );
};

export default SensorStatus;
