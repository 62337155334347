import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Button, TextInput } from '@components';
import { useAppSelector, useTranslation } from '@hooks';
import {
    initScreenInfo,
    setHighlight,
    setScreenListInfo,
    useScreenListDispatchContext,
    useScreenListStateContext,
} from '../slice';
import { OriginScreenInfo } from '../types';
import { WidgetInfo } from '../../../../../Widgets/staticInfo';

const Search = () => {
    const t = useTranslation('Screen');
    const [searchInput, setSearchInput] = useState('');

    const { screenListInfo } = useScreenListStateContext();
    const dispatch = useScreenListDispatchContext();
    const { originScreenList } = useAppSelector(state => state.CustomMenu);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleSearchClick();
    };
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value);
    };
    const handleSearchClick = () => {
        const filterList = originScreenList?.filter((v: OriginScreenInfo) => {
            const widgetInfo = v.widgetInfo ? JSON.parse(v.widgetInfo) : [];

            if (widgetInfo?.length) {
                const name = widgetInfo.find((v: WidgetInfo) => {
                    const customWidgetName = v.config?.settings?.name?.includes(searchInput) ?? '';
                    const WidgetName = t(v.name, 'Widget');
                    return WidgetName.includes(searchInput) || customWidgetName;
                });

                return v.screenName.includes(searchInput) || name;
            }

            return v.screenName.includes(searchInput);
        });

        dispatch(setHighlight(searchInput));
        dispatch(setScreenListInfo({ ...screenListInfo, rows: filterList }));
    };

    const handleRefreshClick = () => {
        dispatch(setHighlight(''));
        setSearchInput('');
        dispatch(setScreenListInfo({ ...initScreenInfo, rows: originScreenList }));
    };

    return (
        <div className="flx-row">
            <p className="pnt-txt txt-bold mr-4">{t('Search', 'Search')}</p>
            <form className={'flx-row'} onSubmit={handleSubmit}>
                <TextInput
                    type={'text'}
                    size={60}
                    placeholder={t('Please enter a screen Name or widget Name')}
                    name={'name'}
                    value={searchInput}
                    handleChange={handleInputChange}
                />
                <Button className={'btn-darkgray btn-icon'} iconName="search" onClick={handleSearchClick}>
                    {t('Search', 'Search')}
                </Button>
            </form>
            <Button className="btn-lightgray btn-icon-only ml-2" iconName="refresh" onClick={handleRefreshClick} />
        </div>
    );
};

export default Search;
