import { useAppSelector } from '@hooks';
import { useState } from 'react';
import { YN } from '@util/type/util';

// 사용하는 정보만 정의
interface GroupInfo {
    groupNum: number | null;
    useScannerAutoMapping: YN;
    groupName: string;
}

const initGroupInfo = (initData: GroupInfo[]): GroupInfo => {
    if (initData.length === 1) {
        const { groupName, groupNum, useScannerAutoMapping } = initData[0];
        return {
            groupName,
            groupNum,
            useScannerAutoMapping,
        };
    }

    return {
        groupName: '',
        groupNum: null,
        useScannerAutoMapping: 'N',
    };
};

const useGroupInfoList = () => {
    const { groupInfoList } = useAppSelector(state => state.UserInfo.userInfo) as { groupInfoList: GroupInfo[] };
    const [selectedGroup, setSelectedGroup] = useState(initGroupInfo(groupInfoList));

    // 계정이 소속된 부서 목록
    const groupListOption = groupInfoList.map(item => ({
        label: item.groupName,
        value: item.groupNum,
        useScannerAutoMapping: item.useScannerAutoMapping,
    }));

    const handleGroupChange = (groupInfo: Partial<GroupInfo>) => {
        setSelectedGroup(prev => ({ ...prev, ...groupInfo }));
    };

    const handleGroupReset = () => {
        setSelectedGroup(prev => ({ ...prev, groupName: '', groupNum: null }));
    };

    return {
        groupInfoList,
        groupListOption,
        selectedGroup,
        handleGroupReset,
        handleGroupChange,
    };
};

export default useGroupInfoList;
