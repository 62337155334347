import React from 'react';
import styled from 'styled-components';
import Nouislider from 'nouislider-react';
import 'nouislider/distribute/nouislider.css';
import moment from 'moment';
import { TIMER_STEP } from './constants';
import { useOutpatientCongestionContext, useOutpatientCongestionDispatch } from './Context';
import { setPlayTime } from './Context/slice';
import { SelectGroup } from '../../../MainPages/Components/FilterSearchGroup/Components/Part';

const PlayController = () => {
    const { playTime, startDate, endDate } = useOutpatientCongestionContext();
    const dispatch = useOutpatientCongestionDispatch();

    return (
        <SelectGroup className={'w-100 h-100'}>
            <div className={'flx-row'}>
                <PlayTimePlate className={'pnt-nameplate mw-100'}>
                    <span className={'cont-wrap px-1'}>
                        {playTime && moment.unix(playTime).format('YYYY-MM-DD HH:mm:ss')}
                    </span>
                </PlayTimePlate>
                <div className={'w-100 play-body-custom'}>
                    <div className={'w-100 px-3 row-custom'}>
                        <StyledNouislider
                            className="tracing-play-bar"
                            animate={false}
                            connect={[true, false]}
                            step={TIMER_STEP}
                            start={[playTime]}
                            range={{ min: startDate, max: endDate }}
                            onSlide={(render, handle, [value], un, percent) => {
                                dispatch(setPlayTime(value));
                            }}
                        />
                    </div>
                </div>
            </div>
        </SelectGroup>
    );
};

export default PlayController;

const PlayTimePlate = styled.div`
    font-variant-numeric: tabular-nums;
`;

const StyledNouislider = styled(Nouislider)`
    & .noUi-base {
        background-color: #dcd9e1 !important;
    }
    & .noUi-handle {
        cursor: pointer;
    }
    & .noUi-connect {
        background-color: #6748e3 !important;
    }
`;

