import React, { type HTMLAttributes } from 'react';
import MobileMenuPath from './Components/MobileMenuPath';

export type PageProps = { menuPath?: string[] } & HTMLAttributes<HTMLDivElement>;

const Page = ({ menuPath, children, ...restProps }: PageProps) => {
    return (
        <div {...restProps}>
            {menuPath && Array.isArray(menuPath) && menuPath.length && <MobileMenuPath menuPath={menuPath} />}
            {children}
        </div>
    );
};

export default Page;
