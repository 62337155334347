import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Button, DatePicker } from '@components';
import { useAppSelector, useTranslation } from '@hooks';
import cx from 'classnames';
import { DATE_TYPE } from '../../constants';
import '@asset/pnt/widgets/so/DateRangeGroupSO.scss';
import { Nullable, UnixTimestamp } from '@util/type/util';
import { NullableTimestamp } from '@components/DatePicker';

type DateType = typeof DATE_TYPE[keyof typeof DATE_TYPE];

interface CustomHeader {
    date: Date;
    decreaseMonth: () => void;
    increaseMonth: () => void;
    increaseYear: () => void;
    decreaseYear: () => void;
    disableIncrease?: boolean;
    disableDecrease?: boolean;
    datetype: DateType;
}

interface Props {
    start: UnixTimestamp;
    end: UnixTimestamp;
    dateType: DateType;
    handleSave: (startDate: NullableTimestamp, endDate: NullableTimestamp) => void;
    handleDateType: (date: DateType) => void;
}

const DATEPICKER_HEADER_FORMAT: Record<DateType, string> = {
    [DATE_TYPE.DAILY]: 'YYYY.MM',
    [DATE_TYPE.WEEKLY]: 'YYYY-MM-dd',
    [DATE_TYPE.MONTHLY]: 'YYYY',
    [DATE_TYPE.YEARLY]: 'YYYY',
};

const DATEPICKER_FORMAT: Record<DateType, string> = {
    [DATE_TYPE.DAILY]: 'YYYY.MM-DD',
    [DATE_TYPE.WEEKLY]: 'YYYY-MM-DD',
    [DATE_TYPE.MONTHLY]: 'YYYY-MM',
    [DATE_TYPE.YEARLY]: 'YYYY',
};

const CalendarContainer = ({ start, end, dateType, handleSave, handleDateType }: Props) => {
    const t = useTranslation('OutpatientReport');
    const [startDate, setStartDate] = useState<NullableTimestamp>(start);
    const [endDate, setEndDate] = useState<NullableTimestamp>(end);
    const [startDateView, setStartDateView] = useState<Nullable<Date>>(null); // startDate 달력 보기
    const [endDateView, setEndDateView] = useState<Nullable<Date>>(null); // endDate 달력 보기
    const { lang } = useAppSelector(state => state.UserInfo);

    const staticStartToDate = useMemo(() => {
        if (startDate) {
            if (dateType === DATE_TYPE.DAILY) {
                return moment.unix(startDate).subtract(1, 'month').toDate();
            }
            if (dateType === DATE_TYPE.MONTHLY) {
                return moment.unix(startDate).subtract(1, 'year').toDate();
            }
            if (dateType === DATE_TYPE.YEARLY) {
                return moment.unix(startDate).subtract(12, 'year').toDate();
            }
        }
    }, [dateType]);

    const staticEndToDate = useMemo(() => {
        if (startDate) {
            if (dateType === DATE_TYPE.DAILY) {
                return moment.unix(startDate).toDate();
            }
            if (dateType === DATE_TYPE.MONTHLY) {
                return moment.unix(startDate).toDate();
            }
            if (dateType === DATE_TYPE.YEARLY) {
                return moment.unix(startDate).toDate();
            }
        }
    }, [dateType]);

    const handleDateChange = (date: NullableTimestamp, event: any) => {
        if (date) {
            if (startDate && endDate) {
                setEndDate(null);
                setStartDate(date);
                return;
            }
            if (startDate && moment.unix(date).isBefore(moment.unix(startDate))) {
                setEndDate(startDate);
                setStartDate(date);
                return;
            }

            setEndDate(date);
        }
    };

    const handleApply = () => {
        if (startDate && endDate) {
            handleSave(startDate, endDate);
        }
    };

    const handleDateTypeChange = (dateType: DateType) => () => {
        handleDateType(dateType);
    };

    const handleStartDateMonthChange = (date: Date) => {
        if (dateType === DATE_TYPE.DAILY) {
            setStartDateView(date);
            setEndDateView(moment(date).add(1, 'month').toDate());
        }
        if (dateType === DATE_TYPE.MONTHLY) {
            setStartDateView(date);
            setEndDateView(moment(date).add(1, 'year').toDate());
        }
        if (dateType === DATE_TYPE.YEARLY) {
            setStartDateView(date);
            setEndDateView(moment(date).add(12, 'year').toDate());
        }
    };

    const handleEndDateMonthChange = (date: Date) => {
        if (dateType === DATE_TYPE.DAILY || dateType === DATE_TYPE.MONTHLY) {
            setStartDateView(moment(date).subtract(1, 'month').toDate());
            setEndDateView(date);
        }
        if (dateType === DATE_TYPE.MONTHLY) {
            setStartDateView(moment(date).subtract(1, 'year').toDate());
            setEndDateView(date);
        }
        if (dateType === DATE_TYPE.YEARLY) {
            setStartDateView(moment(date).subtract(12, 'year').toDate());
            setEndDateView(date);
        }
    };

    const handleYearClass = () => {
        const yearContainerEl = document.getElementsByClassName('react-datepicker__year-wrapper');
        if (!yearContainerEl.length) return;

        Array.from(yearContainerEl).forEach(container => applyYearClass(container, startDate, endDate));
    };

    const handleMonthClass = () => {
        const headerEl = document.getElementsByClassName('react-datepicker__header');
        const monthContainerEl = document.getElementsByClassName('react-datepicker__monthPicker');

        if (monthContainerEl.length >= 2 && headerEl.length >= 2) {
            applyMonthClass(monthContainerEl[0], headerEl[0], startDate, startDateView);
            applyMonthClass(monthContainerEl[1], headerEl[1], startDate, endDateView);
        }
    };

    useEffect(() => {
        // MutationObserver 를 사용하여 dom이 변경됨을 감지
        const yearObserver = new MutationObserver(() => handleYearClass());
        const monthObserver = new MutationObserver(() => handleMonthClass());

        if (dateType === DATE_TYPE.YEARLY) {
            handleYearClass();
            const targetNodes = document.querySelectorAll('.react-datepicker__year-wrapper');
            if (targetNodes) {
                targetNodes.forEach(node => {
                    yearObserver.observe(node, { childList: true, subtree: true, characterData: true });
                });
            }
        }

        if (dateType === DATE_TYPE.MONTHLY) {
            handleMonthClass();
            const targetNodes = document.querySelectorAll('.react-datepicker__monthPicker');
            if (targetNodes) {
                targetNodes.forEach(node => {
                    yearObserver.observe(node, { childList: true, subtree: true, characterData: true });
                });
            }
        }
        return () => {
            monthObserver.disconnect();
            yearObserver.disconnect();
        };
    }, [startDate, endDate, dateType, startDateView, endDateView]);

    return (
        <div className={'range-container'}>
            <div className={'date-type-wrapper'}>
                <div
                    onClick={handleDateTypeChange(DATE_TYPE.DAILY)}
                    className={cx(dateType === DATE_TYPE.DAILY && 'date-clickable', 'pnt-txt date-type')}
                >
                    {t('Specify the date')}
                </div>
                <div
                    onClick={handleDateTypeChange(DATE_TYPE.MONTHLY)}
                    className={cx(dateType === DATE_TYPE.MONTHLY && 'date-clickable', 'pnt-txt date-type')}
                >
                    {t('Search on a monthly basis')}
                </div>
                <div
                    onClick={handleDateTypeChange(DATE_TYPE.YEARLY)}
                    className={cx(dateType === DATE_TYPE.YEARLY && 'date-clickable', 'pnt-txt date-type')}
                >
                    {t('Search on a yearly basis')}
                </div>
            </div>
            <div className={'flx-row gap-2'}>
                <DatePicker
                    value={null}
                    handleChange={handleDateChange}
                    startDate={startDate ? moment.unix(startDate).toDate() : null}
                    endDate={endDate ? moment.unix(endDate).toDate() : null}
                    dayClassName={date => {
                        const unixDate = moment(date).unix();
                        if (dateType === DATE_TYPE.DAILY) {
                            return startDate && unixDate === startDate ? 'react-datepicker__day--range-start' : '';
                        }
                        return '';
                    }}
                    renderCustomHeader={({ date, decreaseMonth, increaseMonth, decreaseYear, increaseYear }) => (
                        <CustomHeader
                            date={date}
                            decreaseMonth={decreaseMonth}
                            increaseMonth={increaseMonth}
                            decreaseYear={decreaseYear}
                            increaseYear={increaseYear}
                            disableIncrease
                            datetype={dateType}
                        />
                    )}
                    withoutTime
                    showMonthYearPicker={dateType === DATE_TYPE.MONTHLY}
                    showYearPicker={dateType === DATE_TYPE.YEARLY}
                    onMonthChange={handleStartDateMonthChange}
                    onYearChange={handleStartDateMonthChange}
                    openToDate={startDateView || staticStartToDate || undefined}
                    inline
                />
                <DatePicker
                    value={null}
                    handleChange={handleDateChange}
                    startDate={startDate ? moment.unix(startDate).toDate() : null}
                    endDate={endDate ? moment.unix(endDate).toDate() : null}
                    dayClassName={date => {
                        const unixDate = moment(date).unix();
                        return startDate && unixDate === startDate ? 'react-datepicker__day--range-start' : '';
                    }}
                    renderCustomHeader={({ date, decreaseMonth, increaseMonth, decreaseYear, increaseYear }) => (
                        <CustomHeader
                            date={date}
                            decreaseMonth={decreaseMonth}
                            increaseMonth={increaseMonth}
                            decreaseYear={decreaseYear}
                            increaseYear={increaseYear}
                            disableDecrease
                            datetype={dateType}
                        />
                    )}
                    withoutTime
                    showMonthYearPicker={dateType === DATE_TYPE.MONTHLY}
                    showYearPicker={dateType === DATE_TYPE.YEARLY}
                    onMonthChange={handleEndDateMonthChange}
                    onYearChange={handleEndDateMonthChange}
                    openToDate={endDateView || staticEndToDate || undefined}
                    inline
                />
            </div>
            <div className={'footer'}>
                <div className={'pnt-txt'}>{`${t('Inquiry period')} : ${
                    startDate ? moment.unix(startDate).locale(lang).format(DATEPICKER_FORMAT[dateType]) : '  '
                } ~ ${endDate ? moment.unix(endDate).locale(lang).format(DATEPICKER_FORMAT[dateType]) : '  '}`}</div>
                <div>
                    <Button className={'btn-secondary'} onClick={handleApply}>
                        {t('Apply')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CalendarContainer;

const CustomHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    decreaseYear,
    increaseYear,
    disableIncrease = false,
    disableDecrease = false,
    datetype,
}: CustomHeader) => {
    const { lang } = useAppSelector(state => state.UserInfo);
    const currentYear = date.getFullYear();
    const startYear = currentYear - (currentYear % 12) + 1;
    const endYear = startYear + 11; // 12년 단위 끝 연도

    return (
        <div>
            {!disableDecrease && (
                <button
                    onClick={datetype === DATE_TYPE.DAILY ? decreaseMonth : decreaseYear}
                    className={'react-datepicker__navigation react-datepicker__navigation--previous'}
                >
                    {'<'}
                </button>
            )}
            <span className={'pnt-txt txt-bold s-8'}>
                {datetype === DATE_TYPE.YEARLY
                    ? `${startYear}~${endYear}`
                    : moment(date).locale(lang).format(DATEPICKER_HEADER_FORMAT[datetype])}
            </span>
            {!disableIncrease && (
                <button
                    onClick={datetype === DATE_TYPE.DAILY ? increaseMonth : increaseYear}
                    className={'react-datepicker__navigation react-datepicker__navigation--next'}
                >
                    {'>'}
                </button>
            )}
        </div>
    );
};

const applyYearClass = (yearContainer: Element | null, startDate: NullableTimestamp, endDate: NullableTimestamp) => {
    if (!yearContainer) return;

    Array.from(yearContainer.getElementsByClassName('react-datepicker__year-text')).forEach(el => {
        const year = Number(el.innerHTML);
        const startYear = startDate ? moment.unix(startDate).year() : null;
        const endYear = endDate ? moment.unix(endDate).year() : null;

        el.classList.remove(
            'react-datepicker__year-text--selected',
            'react-datepicker__year-text--range-start',
            'react-datepicker__year-text--range-end',
            'react-datepicker__year-text--in-range',
        );

        if (startYear && year === startYear) {
            el.classList.add('react-datepicker__year-text--selected', 'react-datepicker__year-text--range-start');
        } else if (endYear && year === endYear) {
            el.classList.add('react-datepicker__year-text--selected', 'react-datepicker__year-text--range-end');
        } else if (startYear && endYear && year > startYear && year < endYear) {
            el.classList.add('react-datepicker__year-text--in-range');
        }
    });
};

const applyMonthClass = (
    monthContainer: Element | null,
    headerEl: Element,
    startDate?: NullableTimestamp,
    dateView?: Nullable<Date>,
): void => {
    if (!monthContainer || !headerEl) return;

    const startYear = startDate ? moment.unix(startDate).year().toString() : '';
    const startMonth = startDate ? (moment.unix(startDate).month() + 1).toString() + '월' : '';
    const year = dateView ? dateView.getFullYear().toString() : headerEl.getElementsByTagName('span')[0]?.innerText;

    Array.from(
        monthContainer.getElementsByClassName('react-datepicker__month-text') as HTMLCollectionOf<HTMLDivElement>,
    ).forEach(el => {
        if (startDate && el.innerText === startMonth && year === startYear) {
            el.classList.add('react-datepicker__month--range-start');
        } else {
            el.classList.remove('react-datepicker__month--range-start');
        }
    });
};
