import React from 'react';
import cx from 'classnames';
import { ToggleButtonProps } from '@components/type';

const ToggleButton = ({
    checked,
    handleChecked,
    onText = 'on',
    offText = 'off',
    className,
    disabled,
}: ToggleButtonProps) => {
    return (
        <label className={cx('pnt-toggle', className, disabled && 'form-disable')}>
            <input type="checkbox" checked={checked} onChange={handleChecked} disabled={disabled} />
            <span className="slider"></span>
            <p>{offText}</p>
            <p>{onText}</p>
        </label>
    );
};

export default ToggleButton;
