import React from 'react';
import { ButtonProps } from '../type';
import cx from 'classnames';
import { Icon } from '@components';

const Button = ({
    className,
    onClick,
    iconClassName,
    iconName,
    title,
    children,
    disabled,
    type = 'button',
}: ButtonProps): JSX.Element => {
    return (
        <button
            type={type}
            className={cx('pnt-btn', className, disabled && 'form-disable')}
            title={title}
            onClick={onClick}
            disabled={disabled}
        >
            <Icon iconName={iconName} className={iconClassName} />
            {children}
        </button>
    );
};

export default Button;
