import { useAsync } from '@hooks';
import React, { useRef } from 'react';
import InsightBox, { BoxBodyText, HighlightedText } from '../Components/InsightBox';
import SearchGroupSO from '../Components/SearchGroupSO';
import WidgetCard from '../../Components/WidgetCard';
import useDepartmentOptions from '../util/useDepartmentOptions';
import useMethodOptions from '../util/useMethodOptions';
import useRangeDateSO from '../util/useRangeDateSO';
import Chart from './Chart';

const MOCK_ROWS = [];

const OutpatientCongestionAnalysis = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();

    const departmentValues = useDepartmentOptions();
    const methodValues = useMethodOptions();
    const dateValues = useRangeDateSO();

    const { state, promise: getData } = useAsync({
        // promise: getOutpatientWaitTime,
        immediate: true,
        param: {
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
        },
        reject: err => console.error(err),
    });
    const data = state.response ? state.response.rows : [];

    return (
        <WidgetCard widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
            <SearchGroupSO
                showDepartment
                departmentValues={departmentValues}
                showMethod
                methodValues={methodValues}
                dateValues={dateValues}
                getData={getData}
            />
            {/* <InsightBox>
                <BoxBodyText>
                    조회하신 기간의 외래 혼잡도는 <HighlightedText>소아청소년과, 재활의학과</HighlightedText>의 순서로
                    가장 혼잡도가 높은 것으로 나타납니다.
                </BoxBodyText>
                <BoxBodyText>
                    이는 <HighlightedText>외래 진료</HighlightedText>가 상대적으로 오래 걸리기 때문인 것으로 추정됩니다.
                </BoxBodyText>
            </InsightBox> */}
            <Chart data={MOCK_ROWS} />
            {children}
        </WidgetCard>
    );
};

export default OutpatientCongestionAnalysis;
