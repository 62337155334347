import { useDispatch } from 'react-redux';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import { EVENT_TYPE_EMERGENCY_SENSOR_ITEM_STATUS_EVENT } from '@reducer/SocketInfo';
import { RealtimeEmergencySensorItemStatusData } from '@util/socket/socketData';
import {
    deleteEmergencyAlertContents,
    getSnoozeKey,
    setAnomalyAlertSettingList,
    setEmergencyAlertContents,
} from '@reducer/EmergencyAlert';
import { useEffect, useMemo, useRef } from 'react';
import { useAppSelector, useAsync } from '@hooks';
import { Nullable, NullableNumber } from '@util/type/util';
import { getPatientAnomalyAlertSettingListApi, PatientAnomalyAlertSetting } from '@api/sh/patientAnomalyAlertSetting';

export type AlertSettingMap = Record<string, PatientAnomalyAlertSetting & { alertSound: { uri: string } }>;

const useEmergencyAlert = () => {
    const dispatch = useDispatch();
    const socketFilter = useAnomalyAlertFilter();
    const ignoreDiffState = useRef<{
        anomalyAlertSnoozeList: Record<string, number>;
    }>({ anomalyAlertSnoozeList: {} });
    const { anomalyAlertSnoozeList } = useAppSelector(state => state.EmergencyAlert);

    useEffect(() => {
        ignoreDiffState.current = { anomalyAlertSnoozeList };
    }, [anomalyAlertSnoozeList]);

    useUpdateAnomalyAlertSettingList({ immediate: true });

    useSocketEvent({
        name: EVENT_TYPE_EMERGENCY_SENSOR_ITEM_STATUS_EVENT,
        filterConfig: socketFilter,
        handler: data => {
            const {
                event: { alertOpen },
                unixTime,
            } = data as RealtimeEmergencySensorItemStatusData;

            if (alertOpen === 'Y') {
                const { anomalyAlertSnoozeList } = ignoreDiffState.current;
                const snoozeEndTime = anomalyAlertSnoozeList[getSnoozeKey(data)];
                if (snoozeEndTime && snoozeEndTime > unixTime) {
                    return;
                }
                dispatch(setEmergencyAlertContents(data));
            } else {
                dispatch(deleteEmergencyAlertContents(data));
            }
        },
    });
};

export const useAnomalyAlertFilter = () => {
    const { anomalyAlertGroupNum, groupNums, anomalyAlertSettingList } = useAppSelector(state => {
        const {
            anomalyAlertGroupNum,
            userInfo,
        }: { anomalyAlertGroupNum: NullableNumber; userInfo: { groupNums?: number[] } } = state.UserInfo;
        const anomalyAlertSettingList: AlertSettingMap = state.EmergencyAlert.anomalyAlertSettingList;
        return { anomalyAlertGroupNum, groupNums: userInfo.groupNums || [], anomalyAlertSettingList };
    });

    return useMemo(() => {
        const alertSettingGroupNumFilter: { alertSettingGroupNum?: Nullable<{ $in: number[] }> } = {
            alertSettingGroupNum: { $in: [] },
        };
        if (anomalyAlertSettingList) {
            for (const alertSettingGroupNum in anomalyAlertSettingList) {
                if (alertSettingGroupNumFilter.alertSettingGroupNum) {
                    alertSettingGroupNumFilter.alertSettingGroupNum.$in.push(Number(alertSettingGroupNum));
                }
            }
        }
        if (!alertSettingGroupNumFilter.alertSettingGroupNum?.$in.length) {
            alertSettingGroupNumFilter.alertSettingGroupNum = null;
            delete alertSettingGroupNumFilter.alertSettingGroupNum;
        }
        if (anomalyAlertGroupNum) {
            return { ...alertSettingGroupNumFilter, target: { properties: { ward: String(anomalyAlertGroupNum) } } };
        }
        if (groupNums.length) {
            return {
                ...alertSettingGroupNumFilter,
                target: { properties: { ward: { $in: groupNums.map(num => String(num)) } } },
            };
        }
        return { target: { properties: { ward: 'none' } } };
    }, [anomalyAlertGroupNum, anomalyAlertSettingList, groupNums]);
};

const useUpdateAnomalyAlertSettingList = (param?: { immediate?: boolean; deps?: any[] }) => {
    const dispatch = useDispatch();
    const { anomalyAlertGroupNum } = useAppSelector(state => state.UserInfo);

    return useAsync({
        promise: getPatientAnomalyAlertSettingListApi,
        fixedParam: { groupNum: anomalyAlertGroupNum, isAll: 'Y' },
        immediate: param?.immediate,
        deps: [anomalyAlertGroupNum, ...(param?.deps || [])],
        resolve: res => {
            dispatch(setAnomalyAlertSettingList(res.rows));
        },
    });
};

export default useEmergencyAlert;
