import { createSlice } from '@reduxjs/toolkit';
import { GeofenceGroupInfo as OriginGeofenceGroupInfo, GeofenceInfo as OriginGeofenceInfo } from '@api/common/geofence';
import { Bounds } from '@reducer/Common/FloorInfo';

interface GeofenceInfoStore {
    geofenceGroupList: GeofenceGroupInfo[];
    geofenceList: GeofenceInfo[];
}

interface GeofenceInfo extends OriginGeofenceInfo {
    bounds: Bounds[];
}
interface GeofenceGroupInfo extends OriginGeofenceGroupInfo {
    geofenceList: GeofenceInfo[];
    geofenceNums: number[];
    floorId: string | undefined;
}

const initialState: GeofenceInfoStore = {
    geofenceGroupList: [],
    geofenceList: [],
};

const { actions, reducer } = createSlice({
    name: 'common/geofenceInfo',
    initialState,
    reducers: {
        setGeofenceInfo: (state, action) => {
            const { geofenceGroupList, geofence } = action.payload;
            // State Geofence Group List
            if (!geofenceGroupList?.length) {
                return;
            }
            state.geofenceGroupList = mapGeofenceToGroup(geofenceGroupList, state.geofenceList);

            // State Geofence List
            const geofenceList = geofence.map((geofence: OriginGeofenceInfo) => ({
                ...geofence,
                bounds: [geofence.latLngList.map(({ lat, lng }) => [lat, lng])],
            }));
            if (!geofenceList.length) {
                return;
            }
            state.geofenceGroupList = mapGeofenceToGroup(state.geofenceGroupList, geofenceList);
            state.geofenceList = geofenceList;
        },
    },
});

const mapGeofenceToGroup = (
    groupList: OriginGeofenceGroupInfo[] = [],
    fenceList: GeofenceInfo[] = [],
): GeofenceGroupInfo[] => {
    return groupList.map(group => {
        const groupGeofence = fenceList.filter(fence => fence.fcGroupNum === group.fcGroupNum);
        return {
            ...group,
            geofenceList: groupGeofence,
            geofenceNums: groupGeofence.map(fence => fence.fcNum),
            floorId: groupGeofence[0]?.floor,
        };
    });
};

export const { setGeofenceInfo } = actions;

export default reducer;
