import React, { useCallback } from 'react';
import CountUp from 'react-countup';
import useTranslation from '@hooks/useTranslation';
import { useIotItemStatusContext, useIotItemStatusDispatch } from '../../index';
import { setSelectedIotItemStatus } from '../../iotItemStatusSlice';
import { Icon } from '@components';

const TOTAL_LABEL_KEY = {
    ASSET: 'Registered Asset',
    PEOPLE: 'Registered People',
    PATIENT: 'Registered Patient',
};

const ItemCount = ({ start = 0, end = 0, label, parentCategory }) => {
    const t = useTranslation('IotItemStatus');
    const { selectedIotItemStatus } = useIotItemStatusContext();
    const dispatch = useIotItemStatusDispatch();

    const dataLabel = {
        totalCount: {
            value: null,
            label: !TOTAL_LABEL_KEY[parentCategory] ? 'Registered Asset' : TOTAL_LABEL_KEY[parentCategory],
        },
        restrictedCount: { value: 'isUnPermitted', label: 'Out of Authorized Zone' },
        lostSignalCount: { value: 'isLostSignal', label: 'Lost Signal' },
        lowBatteryCount: { value: 'isLowBattery', label: 'Low Battery' },
        neverDetectedCount: { value: 'isNeverDetected', label: 'No Response' },
    };

    const handleLabelClick = useCallback(() => {
        dispatch(setSelectedIotItemStatus(dataLabel[label].value));
    }, [dataLabel]);

    const selectedStyleClass = selectedIotItemStatus === dataLabel[label].value ? 'selected' : 'nonSelected';

    return (
        <div className={`flex-center col ${selectedStyleClass}`} onClick={handleLabelClick}>
            <CountUp
                className="item-num"
                decimals={0}
                start={start}
                end={end}
                decimal=","
                duration="1"
                useEasing={false}
                separator=""
                prefix=""
                suffix=""
            />
            <div className={'flx-row'}>
                {t(dataLabel[label].label)}
                {selectedIotItemStatus === dataLabel[label].value ? (
                    <Icon iconName={'arrow_drop_down'} />
                ) : (
                    <Icon iconName={'arrow_right'} />
                )}
            </div>
        </div>
    );
};

export default ItemCount;
