import React, { useEffect } from 'react';
import cx from 'classnames';
import { Button, ConfirmModal } from '@components';
import SingleTreeSelect from '@components/Select/SingleTreeSelect';
import { useAppSelector, useConfirm, useTranslation } from '@hooks';
import { SPEED_OPTION, TIMER_STEP } from './constants';
import { useOutpatientCongestionContext, useOutpatientCongestionDispatch } from './Context';
import { setEndDate, setIsPlaying, setPlayTime, setSelectedFloorInfo, setSpeed, setStartDate } from './Context/slice';
import { OUTPATIENT_REPORT } from '../../constants';
import PlayController from './PlayController';
import moment from 'moment';
import SearchGroupSO from '../Components/SearchGroupSO';
import styled from 'styled-components';
import useDateTypeOptions from '../util/useDateTypeOptions';

const DELAY = 1000;

const SearchContainer = ({ getData }) => {
    const t = useTranslation(OUTPATIENT_REPORT);

    const dispatch = useOutpatientCongestionDispatch();
    const { startDate, endDate, selectedFloor, playTime, isPlaying, speed } = useOutpatientCongestionContext();
    const { floorList } = useAppSelector(state => state.FloorInfo);
    const selectFloorModalProps = useConfirm({
        initModal: false,
        confirmText: t('Choose floor.'),
        removeCancel: true,
    });
    const dateTypeValues = useDateTypeOptions();

    useEffect(() => {
        let timerId;

        if (isPlaying) {
            timerId = setInterval(() => {
                dispatch(setPlayTime(playTime + TIMER_STEP));
            }, DELAY / speed);
        }

        return () => {
            clearInterval(timerId);
        };
    }, [dispatch, isPlaying, playTime, speed]);

    const handleInquiryClick = () => {
        if (selectedFloor.length <= 0) {
            selectFloorModalProps.toggleModal();
            return;
        }

        getData();
        dispatch(setPlayTime(startDate));
        dispatch(setSpeed(64));
    };

    const handleFloorChange = selected => {
        dispatch(setSelectedFloorInfo(selected));
    };

    const handleSpeedClick = optionNumber => {
        dispatch(setSpeed(optionNumber));
    };

    const handlePlayClick = () => {
        if (isPlaying) {
            dispatch(setIsPlaying(false));
            return;
        }
        dispatch(setIsPlaying(true));
    };

    // useRangeDate 참고
    const dateValues = {
        startDate,
        handleStartChange: date => {
            const unixTime = moment(date).unix();
            dispatch(setStartDate(moment.unix(unixTime).startOf('day').unix()));

            if (unixTime > endDate) {
                dispatch(setEndDate(moment.unix(unixTime).endOf('day').unix()));
            }
        },
        endDate,
        handleEndChange: date => {
            const unixTime = moment(date).unix();
            dispatch(setEndDate(moment.unix(unixTime).endOf('day').unix()));
        },
    };

    return (
        <>
            <div>
                <Container>
                    <div className={cx(isPlaying && 'search-disabled', 'd-flex gap-2')}>
                        <SingleTreeSelect
                            data={floorList}
                            title={'Category'}
                            valueKey={'floorId'}
                            labelKey={'floorName'}
                            parentKey={'upperFloorId'}
                            selected={selectedFloor}
                            onChange={handleFloorChange}
                        />
                        <SearchGroupSO
                            dateValues={dateValues}
                            getData={handleInquiryClick}
                            dateTypeValues={dateTypeValues}
                        />
                    </div>
                </Container>
                <Container>
                    {playTime && (
                        <div className={'d-flex mt-1'}>
                            <PlayController />
                            <div className={'d-flex gap-1 flex-center'}>
                                {SPEED_OPTION.map(option => (
                                    <div key={option}>
                                        <Button
                                            className={cx('form-h-small', {
                                                'btn-lightgray': speed !== option,
                                            })}
                                            onClick={() => handleSpeedClick(option)}
                                        >
                                            {'x' + option}
                                        </Button>
                                    </div>
                                ))}
                                <div className={'ml-2'}>
                                    <Button className={'btn-secondary'} onClick={handlePlayClick}>
                                        {isPlaying ? t('Stop') : t('Play')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </Container>
            </div>
            <ConfirmModal {...selectFloorModalProps} />
        </>
    );
};

const Container = styled.div`
    width: 900px;

    .search-disabled {
        opacity: 0.5;
        pointer-events: none;
    }
`;

export default SearchContainer;
