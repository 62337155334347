import React, { useEffect, useState, MouseEvent, CSSProperties } from 'react';
import cx from 'classnames';
import { Card as RSCard, CardBody } from 'reactstrap';

type TabInfo = {
    tabId: string;
    title: string;
    content: JSX.Element;
};

export interface TabProps {
    tabs: TabInfo[];
    className?: string;
    styles?: {
        [key: string]: any;
        headerItem: CSSProperties;
    };
    handleTabChange: (tabId: string) => void;
    activeTabId: string;
}

const Tab = ({ tabs = [], className, styles, handleTabChange = () => {}, activeTabId }: TabProps) => {
    const [tabOn, setTabOn] = useState(activeTabId ? activeTabId : tabs[0].tabId);
    useEffect(() => {
        handleTabChange(tabOn);
    }, [tabOn]);

    const handleTabClick = (e: MouseEvent<HTMLLIElement>) => {
        e.preventDefault();
        setTabOn(e.currentTarget.id);
    };

    return (
        <RSCard className={className}>
            <div className={'card-tab'}>
                <ul>
                    {tabs.map((data, index) => {
                        return (
                            <li
                                key={`${data.tabId}_${index}`}
                                id={data.tabId}
                                className={cx(tabOn === data.tabId && 'on', 'cursor-pointer')}
                                style={{ ...styles?.headerItem }}
                                onClick={handleTabClick}
                            >
                                <a href={'#!'}>{data.title}</a>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <CardBody>
                {tabs.map((data, index) => (
                    <div key={`${data.tabId}_${index}`} className={cx(tabOn !== data.tabId && 'd-none')}>
                        {data.content}
                    </div>
                ))}
            </CardBody>
        </RSCard>
    );
};

export default Tab;
