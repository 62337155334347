import React from 'react';
import cx from 'classnames';
import TAGS, { TagKey } from '../../../tags';
import useTranslation from '@hooks/useTranslation';
import { Icon } from '@components';

interface TagProps {
    tag: TagKey;
    selected?: boolean;
    recommended?: boolean;
    onClick?(tag: TagKey, selected: boolean): void;
}

const Tag = ({ tag, selected, recommended, onClick }: TagProps) => {
    const t = useTranslation('WidgetTag');
    const selectable = typeof selected === 'boolean';
    return (
        <button
            className={cx(
                'pnt-widget-tag',
                !selectable && 'show-only',
                selectable && selected && 'selected',
                recommended && 'recommended',
            )}
            onClick={() => {
                if (typeof onClick === 'function') {
                    onClick(tag, !selected);
                }
            }}
        >
            {t(TAGS[tag])} {selected ? <Icon iconName={'close'} /> : null}
        </button>
    );
};

export default Tag;
