import React, { useState } from 'react';
import { Button, Card, Label, Page, Table } from '@components';
import { useAppSelector, useColumns, useTranslation } from '@hooks';
import * as col from '../../../util/grid/column/menu';
import * as commonCol from '../../../util/grid/column';
import MenuListSubMenu from './MenuListSubMenu';
import { useHistory } from 'react-router-dom';
import Search from './Search';
import { Nullable } from '@util/type/util';
import { ScreenInfo } from '@api/common/screen';
import { DetailGroupInfo, MenuInfo } from '@api/common/menu';
import { Cell } from '@components/Table/types';
import HighlightedText from '../../../Components/HighlightedText';

const MenuList = () => {
    const t = useTranslation('MenuManagement');
    const history = useHistory();
    const { originMenuList } = useAppSelector(state => state.CustomMenu);
    const [menuList, setMenuList] = useState(originMenuList);
    const [highlight, setHighlight] = useState('');

    const handleRefreshClick = () => {
        setMenuList(originMenuList);
        setHighlight('');
    };

    const handleSearchClick = (searchInput: string) => {
        const filterMenu = originMenuList.filter(v => {
            if (v.screenList) {
                return (
                    v.menuName.includes(searchInput) ||
                    v.screenList.find(
                        screen => screen.screenName.includes(searchInput) || screen.menuName.includes(searchInput),
                    )
                );
            }

            return v.menuName.includes(searchInput);
        });
        setHighlight(searchInput);
        setMenuList(filterMenu);
    };

    const getMenuList = (menu: MenuInfo[]) => {
        setMenuList(menu);
    };

    const menuColumns = useColumns(
        [
            col.menuIcon(),
            col.menuName({
                Cell: ({ value }: Cell<MenuInfo, string>) => {
                    return <HighlightedText originText={value} highlightText={highlight} />;
                },
            }),
            col.menuDescription(),
            {
                Header: 'Sort Order',
                className: 'd-flex flx-center',
                accessor: 'order',
                width: 50,
            },
            {
                Header: 'Screen List',
                className: 'pr-3 pl-3 text-ellipsis',
                accessor: 'screenList',
                formatTitle: ({ value }: Cell<MenuInfo>) =>
                    value?.length && value[0]
                        ? `${value[0].menuName} > ${value[0].screenName}` +
                          (value[1] ? ` ${t('other')} ${value.length - 1}` : '')
                        : '',
                Cell: ({ value }: Cell<MenuInfo, Nullable<ScreenInfo<DetailGroupInfo>[]>>) =>
                    value?.map((data, idx) => {
                        return (
                            <Label
                                key={`${data.menuName}_${data.screenName}_${idx}`}
                                name={
                                    <HighlightedText
                                        className={'text-transform-none'}
                                        originText={`${data.menuName} > ${data.screenName}`}
                                        highlightText={highlight}
                                    />
                                }
                                labelValueClassName={`pnt-txt txt-dot txt-bold ${
                                    value?.length !== idx + 1 ? 'mb-2' : ''
                                }`}
                            />
                        );
                    }),
                style: {
                    maxHeight: '150px',
                    overflow: 'auto',
                },
            },
            commonCol.modDate(),
            {
                Header: 'Action',
                id: 'subMenu',
                width: 50,
                className: 'flex-center overflow-visible',
                Cell: ({ row }: { row: { original: MenuInfo } }) => (
                    <MenuListSubMenu getFilterMenu={setMenuList} row={row} />
                ),
            },
        ],
        t,
        [highlight],
    );

    return (
        <Page className="h-100" menuPath={[t('Menu Management', 'Menu'), t('Menu List', 'Menu')]}>
            <Card
                className="h-100"
                bodyClassName={'d-flex flex-column gap-4'}
                header={{
                    title: t('Menu List'),
                    action: (
                        <Button
                            className="btn-icon btn-secondary"
                            iconName="add"
                            onClick={() => history.push('/setting/menu/create')}
                        >
                            {t('Create Menu')}
                        </Button>
                    ),
                }}
            >
                <Search
                    getFilterMenu={getMenuList}
                    handleSearch={handleSearchClick}
                    handleRefresh={handleRefreshClick}
                />
                <Table
                    textAlign={'center'}
                    data={{ rows: menuList }}
                    columns={menuColumns}
                    paging={false}
                    dynamicRowHeight
                />
            </Card>
        </Page>
    );
};

export default MenuList;
