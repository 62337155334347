import React, { ReactNode } from 'react';
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';

/**
 * NoDataBlock
 *
 * @param className wrapper classnames
 * @param text custom 'no data' message
 * @param height block height(default 100), unit='%'
 * @param width block width(default 100), unit='%'
 */

type NoDataBlockProps = {
    className?: string;
    text?: ReactNode;
    height?: number;
    width?: number;
};

const NoDataBlock = ({ className = '', text, height = 100, width = 100 }: NoDataBlockProps) => {
    const t = useTranslation();
    return (
        <Container className={className} $height={height} $width={width}>
            <NoDataContainer className={'flex-center'}>{text ? text : t('No data', 'Table')}</NoDataContainer>
        </Container>
    );
};

const Container = styled.div<{ $width: number; $height: number }>`
    width: ${({ $width }) => $width}%;
    height: ${({ $height }) => $height}%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NoDataContainer = styled.span`
    padding: 0 2rem;
    height: 5rem;
    max-height: 70%;
    max-width: 50%;
    background-color: rgba(0, 0, 0, 0.1);
`;

export default NoDataBlock;
