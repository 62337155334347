import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FloorInfo as OriginFloorInfo } from '@api/common/floor';
import { NullableNumber, NullableString } from '@util/type/util';

const isSelectableFloor = (floor: FloorInfo, floorList: FloorInfo[]): boolean => {
    return !!floor.imgURL && !floorList.find(compFloor => compFloor.upperFloorId === floor.floorId);
};
/**
 *      floorList = 모든 층 정보
 *      floor = 최상단 층 정보 (최상단 층의 자식 층이 있다면 해당 자식층들 중 가장 첫 번째로 세팅)
 *      floorId = 최상단 층의 아이디
 * * */
function filterFloorsByUpperFloorId(list: FloorInfo[], upperFloorId: string): FloorInfo[] {
    return list.filter(floor => floor.upperFloorId === upperFloorId);
}

// 첫 번째 층 선택을 위한 재귀함수
function recursionForSelectFirstFloor(list: FloorInfo[], floors: FloorInfo[]): FloorInfo[] {
    const filteredFloor = floors.flatMap(item => filterFloorsByUpperFloorId(list, item.floorId));

    // 더이상 상위층 정보에 대한 값이 없을 때
    if (!filteredFloor.length) {
        return floors;
    }

    return recursionForSelectFirstFloor(list, filteredFloor);
}

// 초기 층 선택을 해줘야하는 위젯에서 사용
export const selectFirstFloor = (floorList: FloorInfo[]): { floor: null | FloorInfo; floorId: NullableString } => {
    if (!floorList.length) {
        return { floor: null, floorId: null };
    }

    const initFloor = floorList.filter(floor => floor.upperFloorId === floorList[0].floorId);
    const selectedFloor = !initFloor.length ? floorList[0] : recursionForSelectFirstFloor(floorList, initFloor)[0];

    return { floor: selectedFloor, floorId: selectedFloor.floorId };
};

interface FloorInfoStore {
    floorList: FloorInfo[];
    selectableFloorList: FloorInfo[];
}

export type LatLng = [lat: NullableNumber, lng: NullableNumber];
export type Bounds = [swLatLng: LatLng, neLatLng: LatLng];
export interface FloorInfo extends OriginFloorInfo {
    bounds: Bounds;
}

const initialState: FloorInfoStore = {
    floorList: [],
    selectableFloorList: [],
};

const { actions, reducer } = createSlice({
    name: 'common/floorInfo',
    initialState,
    reducers: {
        setFloorList: (state, action: PayloadAction<Array<OriginFloorInfo>>) => {
            const floorList: FloorInfo[] = action.payload.map(v => ({
                ...v,
                bounds: [
                    [v.swLat, v.swLng],
                    [v.neLat, v.neLng],
                ],
            }));
            state.floorList = floorList;

            state.selectableFloorList = floorList.filter(floor => isSelectableFloor(floor, floorList));
        },
    },
});

export const { setFloorList } = actions;
export default reducer;
