import React, { useMemo } from 'react';
import { BarChart, QuestionTooltip } from '@components';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import InnerItemCard from '@components/Card/InnerItemCard';
import { useTranslation } from '@hooks';
import { customLegendPlugin, hoverBackgroundPlugin, topLabels } from '../util/chartPlugin';
import { createTooltipBase } from '../util';

const COLOR_ORANGE = '#f17c57fa';

const Chart = ({ data }) => {
    const t = useTranslation('OutpatientReport');

    const barData = useMemo(
        () => ({
            labels: data.map(row => row.departmentName),
            datasets: [
                {
                    label: t('Appointment Registration'),
                    data: data.map(row => row.appointmentPatientCount),
                    stack: 'patient',
                    order: 1, // z-index 낮음
                    datalabels: {
                        formatter(value, context) {
                            return value || '';
                        },
                    },
                },
                {
                    label: t('Same-day Registration'),
                    data: data.map(row => row.todayPatientCount),
                    stack: 'patient',
                    order: 1, // z-index 낮음
                    datalabels: {
                        formatter(value) {
                            return value || '';
                        },
                    },
                },
                {
                    label: t('Kiosk Usage'),
                    data: data.map(row => row.kioskCount),
                    stack: 'kiosk',
                    order: 1, // z-index 낮음
                    datalabels: {
                        display: false,
                    },
                },
                {
                    label: t('Kiosk Usage Rate'),
                    data: data.map(row => row.kioskRate),
                    type: 'line',
                    yAxisID: 'y1', // 라인차트는 별도의 y축 사용
                    order: 0, // z-index 높음
                    pointRadius: 12,
                    pointHoverRadius: 12,
                    forcedBorderColor: COLOR_ORANGE,
                    forcedBackgroundColor: COLOR_ORANGE,
                    forcedPointBorderColor: COLOR_ORANGE,
                    forcedPointBackgroundColor: COLOR_ORANGE,
                    datalabels: {
                        display: true,
                        // 이 데이터셋에만 데이터 레이블 표시
                        color: 'white',
                        anchor: 'center',
                        align: 'center',
                        font: {
                            size: 12,
                        },
                        formatter: function (value) {
                            return Math.round(value); // 표시할 값
                        },
                    },
                },
            ],
        }),
        [data, t],
    );

    return (
        <InnerItemCard
            className={'m-2 h-90'}
            bodyClassName={'h-100'}
            header={{ title: t('Number of Reservations/Walk-in Visits by Department and Kiosk Usage') }}
        >
            <div className={'h-90 flx-row position-relative'}>
                <div
                    className={'d-flex gap-2 align-items-center position-absolute'}
                    style={{
                        top: 0,
                        right: 0,
                    }}
                >
                    <div
                        id="custom-legend-container"
                        className={'d-flex gap-2'}
                        style={{ pointerEvents: 'none' }}
                    ></div>
                    <QuestionTooltip
                        placement={'bottom'}
                        isFitContent
                        contents={
                            <div className={'d-flex align-items-center'}>
                                <div className={'d-flex align-items-center'}>
                                    <div
                                        className={'mr-1'}
                                        style={{
                                            width: 12,
                                            height: 12,
                                            borderRadius: 15,
                                            backgroundColor: COLOR_ORANGE,
                                        }}
                                    ></div>
                                    <div className={'pnt-txt txt-bold'} style={{ color: COLOR_ORANGE }}>
                                        {t('Kiosk Usage Rate')} (%)
                                    </div>
                                </div>
                                <span className={'mx-1'}>:</span>
                                <div className={'pnt-txt text-white'}>
                                    {t('(Outpatient Kiosk Users / Outpatient Registrations) * 100')}
                                </div>
                            </div>
                        }
                    />
                </div>

                <BarChart
                    data={barData}
                    type={'stackedBar'}
                    plugins={[hoverBackgroundPlugin, ChartDataLabels, customLegendPlugin, topLabels]}
                    options={{
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 60,
                            },
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            datalabels: {
                                color: '#ffffff', // 라벨 텍스트 색상
                                anchor: 'center', // 기준점
                                align: 'center', // 정렬 방식
                                font: {
                                    size: 14, // 폰트 크기
                                    weight: 'bold', // 폰트 두께
                                },
                            },
                            tooltip: {
                                intersect: false,
                                mode: 'index',
                                enabled: false, // 툴팁 활성화
                                external: function (context) {
                                    createTooltipBase(context, (dataPoints, tooltipEl) => {
                                        dataPoints.forEach(dataPoint => {
                                            const {
                                                label: datasetLabel = '',
                                                backgroundColor: color,
                                                type,
                                            } = dataPoint.dataset;
                                            const value = dataPoint.raw;

                                            const sumValue = dataPoints
                                                .filter(({ dataset: { stack } }) => stack === 'patient')
                                                .reduce((acc, { raw }) => acc + raw, 0);

                                            if (type === 'line') {
                                                const { label: department } = dataPoint;
                                                tooltipEl.innerHTML = `
                                                    <div>
                                                        <div>${department}</div>
                                                        <div class="d-flex align-items-center gap-1">
                                                          <div style="height: 15px; width: 15px; background-color: ${color}; border-radius: 15px"></div>
                                                          <div style="color: ${color}">${datasetLabel}: ${value}%</div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center gap-1">
                                                          <div>${t('Total Registrations')}: ${sumValue} ${t(
                                                    'Cases',
                                                )}</div>
                                                       </div>
                                                    `;
                                            } else {
                                                tooltipEl.insertAdjacentHTML(
                                                    'beforeend',
                                                    `
                                                       <div class="d-flex align-items-center gap-1">
                                                          <div style="height: 15px; width: 15px; background-color: ${color};"></div>
                                                          <div>${datasetLabel}: ${value} ${t('Cases')}</div>
                                                       </div>`,
                                                );
                                            }
                                        });
                                    });
                                },
                            },
                        },
                        scales: {
                            x: {
                                title: {
                                    display: true,
                                    text: t('Departments Using Kiosks'),
                                    font: {
                                        size: 16,
                                        family: 'pretendard',
                                    },
                                    padding: { top: 20 },
                                },
                            },
                            y1: {
                                type: 'linear', // 축의 종류 (linear는 기본값)
                                position: 'right', // y1 축을 오른쪽으로 위치시킴
                                beginAtZero: true, // y1도 0에서 시작
                                min: -5, // y1 최소값
                                max: 105, // 최대값을 100보다 조금 더 크게 설정
                                ticks: {
                                    stepSize: 20, // 20 단위로 눈금 표시
                                    callback: function (value) {
                                        return value >= 0 && value <= 100 ? value : '';
                                    },
                                },
                                grid: {
                                    drawOnChartArea: false, // 오른쪽 y1 축에 대한 그리드 라인 비활성화
                                },
                                title: {
                                    display: true,
                                    text: `${t('Kiosk Usage Rate')}(%)`,
                                    font: {
                                        size: 15,
                                        family: 'pretendard',
                                    },
                                },
                            },
                            y: {
                                grace: '20%',
                                beginAtZero: true,
                                suggestedMax: 10,
                                title: {
                                    display: true,
                                    text: t('Registrations and Kiosk Usage (Cases)'),
                                    font: {
                                        size: 15,
                                        family: 'pretendard',
                                    },
                                },
                            },
                        },
                    }}
                />
            </div>
        </InnerItemCard>
    );
};

export default Chart;
