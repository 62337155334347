import { createContext, Dispatch, useContext } from 'react';
import { ActionType } from '@components/ContextProvider';
import { createSlice } from '@reduxjs/toolkit';
import { NullableNumber, YN } from '@util/type/util';
import { StatusOption } from './type';

type MonitoringFilter = {
    wardList: string[];
    roomList: string[];
    isMapping?: YN;
    isHospitalized?: StatusOption;
};

export type TargetInfo = {
    targetNum: NullableNumber;
    sensorNum: NullableNumber;
    groupNum?: NullableNumber;
};

const initialState: {
    targetInfo: TargetInfo;
    monitoringFilter: MonitoringFilter;
} = {
    targetInfo: {
        targetNum: null,
        sensorNum: null,
        groupNum: null,
    },
    monitoringFilter: {
        wardList: [],
        roomList: [],
        isMapping: 'N',
        isHospitalized: 'IN',
    },
};

export const DeviceMappingManagementStateContext = createContext(initialState);
export const DeviceMappingManagementDispatchContext = createContext<Dispatch<ActionType> | null>(null);

export const useDeviceMappingManagementStateContext = () => useContext(DeviceMappingManagementStateContext);
export const useDeviceMappingManagementDispatchContext = () => useContext(DeviceMappingManagementDispatchContext)!;

const deviceMappingManagementSlice = createSlice({
    name: 'sh/deviceMappingManagement',
    initialState,
    reducers: {
        setTargetInfo: (state, action) => {
            state.targetInfo = { ...state.targetInfo, ...action.payload };
        },
        setMonitoringFilter: (state, action) => {
            const newFilter = action.payload;
            state.monitoringFilter = { ...state.monitoringFilter, ...newFilter };
        },
        setClearTargetInfo: state => {
            state.targetInfo = { targetNum: null, sensorNum: null, groupNum: null };
        },
    },
});

export const { setTargetInfo, setMonitoringFilter, setClearTargetInfo } = deviceMappingManagementSlice.actions;

export default deviceMappingManagementSlice;
