import React, { useEffect, useState } from 'react';
import { Button, ConfirmModal, Icon, Table } from '@components';
import { useAsync, useColumns, useInterval, useTranslation } from '@hooks';
import * as column from '../../../../../../MainPages/util/grid/column';
import { useSensorStatusContext } from '../../../index';
import { fetchExcelLogApi, fetchExcelSensorLogExport } from '@api/common/log';
import { INTERVAL_TIME } from '../../../sensorStatusSlice';
import moment from 'moment';
import { excelExport } from '../../../../../util/excelExport';
import InnerItemCard from '@components/Card/InnerItemCard';

// 센서 상세 정보
const SensorDetails = () => {
    const t = useTranslation('SensorStatus');
    const commonColumn = useTranslation('CommonColumn');

    const [modal, setModal] = useState({
        noData: false,
        excelResult: false,
    });

    const { sensorDetailsList, sensorDetailsListLoading, sensorLogInfo } = useSensorStatusContext();

    const columns = useColumns([
        column.targetName({
            Header: 'Sensor Name',
            className: 'd-flex flx-center',
        }),
        column.sensorMacAddress({
            width: 130,
        }),
        column.data({
            Cell: ({ value }) => {
                const letter = value ? value : '-';
                return <div>{letter}</div>;
            },
        }),
        column.floor({
            width: 60,
            Cell: ({ value }) => {
                const letter = value ? value : '-';
                return <div>{letter}</div>;
            },
        }),
        column.registeredDate({
            width: 100,
            Header: 'Recent Received Date',
            accessor: 'date',
            Cell: ({ value }) => <div>{moment.unix(value).format('YYYY-MM-DD HH:mm:ss')}</div>,
        }),
    ]);

    const [excelInfo, setExcelInfo] = useState(null);

    const { promise: getExcelSensorLogExport } = useAsync({
        promise: fetchExcelSensorLogExport,
        fixedParam: {
            zipFileName: 'Sensor_Status_Log',
            fileName: 'Sensor_Status_Log',
            columnMetas: [
                {
                    key: 'targetName',
                    name: commonColumn('Target Name'),
                },
                {
                    key: 'deviceId',
                    name: commonColumn('Sensor Mac Address'),
                },
                {
                    key: 'data',
                    name: commonColumn('Value'),
                },
                {
                    key: 'floorId',
                    name: commonColumn('Floor'),
                },
                {
                    key: 'regDate',
                    name: commonColumn('Registered Date'),
                    isDateFormat: 'Y',
                },
            ],
        },
        resolve: res => {
            const { filePath: url, reqNum } = res;
            if (url) {
                setExcelInfo({ url, reqNum });
            }
        },
    });

    const toggleDelay = useInterval({
        callback: param => {
            fetchExcelLog(param);
        },
        delay: INTERVAL_TIME,
        params: excelInfo ? { reqNum: excelInfo.reqNum } : {},
        initial: false,
    });

    useEffect(() => {
        // 엑셀 다운로드를 눌러서 엑셀 정보가 들어왔을 때 다운 받는 api 호출
        if (excelInfo) {
            toggleDelay();
        }
    }, [excelInfo]);

    const { promise: fetchExcelLog } = useAsync({
        promise: fetchExcelLogApi,
        resolve: res => {
            const { dataSettingStatusType, filePath: url, fileName } = res;
            if (dataSettingStatusType === 'COMPLETED') {
                excelExport(url, fileName);
            } else if (dataSettingStatusType === 'FAILED') {
                handleExcelDownloadResultModal();
            }
            setExcelInfo(null);
            toggleDelay();
        },
    });

    const handleExportButtonClick = () => {
        const { request } = sensorLogInfo;

        if (!sensorDetailsList.rows.length || !request) {
            handleNoDataModal();
        } else {
            delete request.page;
            delete request.pageSize;
            getExcelSensorLogExport({ ...request });
        }
    };

    const handleNoDataModal = () => {
        setModal(prev => ({ ...prev, noData: !prev.noData }));
    };

    const handleExcelDownloadResultModal = () => {
        setModal(prev => ({ ...prev, excelResult: !prev.excelResult }));
    };

    return (
        <>
            <InnerItemCard
                className={'h-100'}
                bodyClassName={'h-80'}
                header={{
                    title: t('Sensor details'),
                    action: (
                        <Button className="btn-lightgray btn-icon" onClick={handleExportButtonClick}>
                            <Icon iconName={'file_upload'} />
                            {t('Export', 'Button')}
                        </Button>
                    ),
                }}
            >
                <Table paging={false} columns={columns} data={sensorDetailsList} loading={sensorDetailsListLoading} />
            </InnerItemCard>

            <ConfirmModal
                initModal={modal.noData}
                toggleModal={handleNoDataModal}
                header={{ title: t('Alert') }}
                confirmText={<span>{t('There is no data')}</span>}
                removeCancel={true}
            />

            <ConfirmModal
                initModal={modal.excelResult}
                toggleModal={handleExcelDownloadResultModal}
                header={{ title: t('Result', 'ConfirmModal') }}
                confirmText={<span>{t('Failed to download the excel.', 'ConfirmModal')}</span>}
                removeCancel={true}
            />
        </>
    );
};

export default SensorDetails;
