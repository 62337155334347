import { ImageOverlay, DomUtil } from 'leaflet';
import { withLeaflet, MapLayer } from 'react-leaflet';

class RotatedImageOverlay extends MapLayer {
    createLeafletElement(props) {
        const RotatedImageOverlay = ImageOverlay.extend({
            options: {
                deg: 0,
            },
            _animateZoom: function (e) {
                ImageOverlay.prototype._animateZoom.call(this, e);
                const img = this._image;
                img.style[DomUtil.TRANSFORM] += ' rotate(' + this.options.deg + 'deg)';
                // img.style.transformOrigin = 'center';
            },
            _reset: function () {
                ImageOverlay.prototype._reset.call(this);
                const img = this._image;
                img.style[DomUtil.TRANSFORM] += ' rotate(' + this.options.deg + 'deg)';
                // img.style.transformOrigin = 'center';
            },
        });
        if (!props.url || !props.bounds.length) {
            return null;
        }
        const options = this.getOptions(props);
        const el = new RotatedImageOverlay(props.url, props.bounds, options);
        el.on('load', function (e) {
            const { target: layer, currentTarget } = e;
            if (layer._map) {
                layer._map.fitBounds(layer.getBounds());
            }
            if (options.onLoad && typeof options.onLoad === 'function') {
                if (document.contains(currentTarget)) {
                    options.onLoad(e);
                }
            }
        });
        this.contextValue = { ...props.leaflet, popupContainer: el };
        return el;
    }
    // updateLeafletElement(fromProps, toProps) {
    //     if (toProps.url !== fromProps.url) {
    //         this.leafletElement.setUrl(toProps.url);
    //     }
    //     if (toProps.bounds !== fromProps.bounds) {
    //         this.leafletElement.setBounds(latLngBounds(toProps.bounds));
    //     }
    //     if (toProps.opacity !== fromProps.opacity) {
    //         this.leafletElement.setOpacity(toProps.opacity);
    //     }
    //     if (toProps.zIndex !== fromProps.zIndex) {
    //         this.leafletElement.setZIndex(toProps.zIndex);
    //     }
    //     // if (toProps.deg !== fromProps.deg) {
    //     //     this.options.deg = toProps.deg;
    //     //     if (this._image && this.options.deg !== undefined && this.options.deg !== null) {
    //     //         this._image.style[DomUtil.TRANSFORM] += ' rotate(' + this.options.deg + ')';
    //     //     }
    //     // }
    // }
}

export default withLeaflet(RotatedImageOverlay);
