import React, { useEffect, useState } from 'react';
import moment from 'moment';
import useAsync from '@hooks/useAsync';
import useTranslation from '@hooks/useTranslation';
import { LoadingBlock, LineChart } from '@components';
import NoDataBlock from '../../../../../../Components/NoDataBlock';
import { SENSING_TYPE_TEMPERATURE } from '../../ViewDetail';
import { getSensorLogApi } from '@api/sh/biometricInformationMonitoring';

const Graph = ({ date, type = SENSING_TYPE_TEMPERATURE, modelCode, targetNum }) => {
    const t = useTranslation('RefrigeratorSensor');
    const [chartData, setChartData] = useState({});

    // 그래프 통합 API로 변경
    const {
        promise: getRefrigeratorSensorDate,
        state: { isLoading },
    } = useAsync({
        promise: getSensorLogApi,
        resolve: res => {
            const { sensingList } = res;

            if (sensingList[type]) {
                const maxLength = Math.max(sensingList[type]?.length, sensingList['regDate']?.length);

                const graphData = Array.from({ length: maxLength }, (_, idx) => ({
                    x: sensingList['regDate'][idx] ? sensingList['regDate'][idx] * 1000 : null,
                    y: sensingList[type][idx] ? sensingList[type][idx] : null,
                }));

                setChartData({
                    datasets: [
                        {
                            label: t('Humidity'),
                            data: graphData,
                        },
                    ],
                });
            }
        },
        reject: err => console.error(err),
    });

    useEffect(() => {
        setChartData(prevState => {
            if (prevState.datasets) {
                return {
                    datasets: [
                        {
                            ...prevState.datasets[0],
                            label: t(type),
                        },
                    ],
                };
            }
            return prevState;
        });
    }, [t]);

    // 일자 변경
    useEffect(() => {
        const currentDate = moment().date();
        const selectedDate = moment(date * 1000).date();
        let newParams;

        if (modelCode && targetNum) {
            // 현재일자 === 선택일자 ? 현재시간부터 전일 현재 시간까지
            if (currentDate === selectedDate) {
                newParams = {
                    startDate: moment().subtract(1, 'days').unix(),
                    endDate: moment().unix(),
                    modelCode,
                    targetNum,
                };
            }
            // 현재일자 !== 선택일자 ? 선택일자 00:00:00 ~ 23:59:59
            else {
                newParams = {
                    startDate: moment(date * 1000)
                        .startOf('date')
                        .unix(),
                    endDate: moment(date * 1000)
                        .endOf('date')
                        .unix(),
                    modelCode,
                    targetNum,
                };
            }
            getRefrigeratorSensorDate(newParams);
        }
    }, [date, modelCode, targetNum]);

    return (
        <LoadingBlock blocking={isLoading}>
            {chartData?.datasets?.[0]?.data?.length ? (
                <div className="w-100 h-100 p-2 border-size-1 border-depth-7">
                    <LineChart
                        data={chartData}
                        options={{
                            radius: 0,
                            borderWidth: 2,
                            interaction: {
                                intersect: false,
                            },
                            plugins: {
                                legend: {
                                    display: false,
                                },
                            },
                            scales: {
                                x: {
                                    type: 'time',
                                    time: {
                                        unit: 'minute',
                                        tooltipFormat: 'HH:mm:ss',
                                        displayFormats: { minute: 'HH:mm' },
                                    },
                                    grid: { display: true },
                                },
                                y: {
                                    afterDataLimits: function (scale) {
                                        scale.max = scale.max + 5;
                                        scale.min = scale.min - 5;
                                    },
                                    grid: {
                                        borderDash: [8, 3],
                                    },
                                },
                            },
                        }}
                    />
                </div>
            ) : (
                <NoDataBlock className="border-size-1 border-depth-7" />
            )}
        </LoadingBlock>
    );
};

export default Graph;
