import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useContext, useState } from 'react';

import { setEditMode } from '../reducer';
import { FilterDetailDispatchContext, FilterDetailStateContext } from '../index';
import FilterGenerator from '../../../../Components/FilterWidget/FilterGenerator';
import { Card, Label, SearchableSelect } from '@components';
import { useAsync, useConfirmModal, useTranslation } from '@hooks';
import { deleteFilterApi } from '@api/common/filter';
import { A_PATH_FILTER_LIST } from '../../../../Components/Router/path';
import { INDOORPLUS_ERRORS } from '@util/errorCode';
import Button from '@components/Button';

const ShowView = () => {
    const t = useTranslation('Filter');
    const buttonT = useTranslation('Button');
    const history = useHistory();
    const dispatch = useContext(FilterDetailDispatchContext);
    const { origin } = useContext(FilterDetailStateContext);
    const { topCategoryList } = useSelector(state => state.CategoryInfo);
    const [deleteErrMsg, setDeleteErrMsg] = useState(t('Failed to delete filter.'));

    const { promise: deleteFilter } = useAsync({
        promise: deleteFilterApi,
        fixedParam: { filterNum: origin.filterNum },
        resolve: () => {
            toggleDeleteSuccessModal();
        },
        reject: err => {
            if (
                err.data &&
                err.data.code === INDOORPLUS_ERRORS.ALREADY_MAPPED &&
                err.data.errorResponse.includes('screenCode')
            ) {
                setDeleteErrMsg(t('There is a screen with a filter applied. Please disable the filter and try again.'));
            } else {
                setDeleteErrMsg(t('Failed to delete filter.'));
            }
            toggleDeleteFailModal();
        },
        deps: [origin],
    });

    const { Modal: DeleteFilterModal, toggleModal: toggleDeleteFilterModal } = useConfirmModal({
        confirmText: t('Do you want to delete the filter?'),
        okCallback: () => {
            deleteFilter();
        },
    });

    const { Modal: DeleteSuccessModal, toggleModal: toggleDeleteSuccessModal } = useConfirmModal({
        confirmText: t('Filter has been deleted.'),
        removeCancel: true,
        okCallback: () => {
            history.replace(A_PATH_FILTER_LIST);
        },
    });

    const { Modal: DeleteFailModal, toggleModal: toggleDeleteFailModal } = useConfirmModal({
        header: { title: t('Request Fail') },
        confirmText: deleteErrMsg,
        removeCancel: true,
    });

    return (
        <>
            <Card
                className="h-100"
                header={{
                    title: `${t('Filter')} - ${t('View')}`,
                    iconName: 'info',
                    action: (
                        <>
                            <Button className="btn-gray" onClick={() => history.goBack()}>
                                {buttonT('Back')}
                            </Button>
                            <Button className="btn-danger" onClick={toggleDeleteFilterModal}>
                                {buttonT('Delete')}
                            </Button>
                            <Button
                                className="btn-icon btn-secondary"
                                iconName="edit"
                                onClick={() => {
                                    dispatch(setEditMode(true));
                                }}
                            >
                                {buttonT('Edit')}
                            </Button>
                        </>
                    ),
                }}
                bodyClassName={'overflow-visible'}
            >
                <Label
                    name={t('Name')}
                    labelGroupClassName={'mb-3'}
                    labelValueClassName={'label-must mr-5'}
                    value={origin.filterName}
                />
                <Label
                    name={t('Description')}
                    labelGroupClassName={'mb-3'}
                    labelValueClassName={'mr-5'}
                    value={origin.description}
                />
                <div className="pnt-divider horizon-line mb-3" />
                <Label
                    name={t('Filter Type')}
                    labelGroupClassName={'mb-3'}
                    labelValueClassName={'mr-5'}
                    value={
                        <SearchableSelect
                            title={t('Category')}
                            data={topCategoryList}
                            selected={(origin.categoryCodes ?? '').split(',')}
                        />
                    }
                />
                <Label
                    name={t('Filter setting')}
                    labelGroupClassName={'mb-2'}
                    labelValueClassName={'mr-5'}
                    value={
                        <div className={'d-flex w-100 align-items-center filter-wrap'}>
                            <div className={'d-flex filter-wrap'} style={{ columnGap: '0.5rem' }}>
                                <FilterGenerator filterInfo={origin} handleChange={() => {}} filterEditMode={false} />
                            </div>
                        </div>
                    }
                />
                <div className="pnt-divider horizon-line mb-3" />
            </Card>
            <DeleteFilterModal />
            <DeleteSuccessModal />
            <DeleteFailModal />
        </>
    );
};

export default ShowView;
