import {
    getOutpatientWaitDept,
    postOutpatientWaitDeptExcel,
    postOutpatientWaitTimeListExcel,
} from '@api/so/outpatient';
import { useAsync, useTranslation } from '@hooks';
import React, { useRef } from 'react';
import InsightBox, { BoxBodyText, HighlightedText } from '../Components/InsightBox';
import SearchGroupSO from '../Components/SearchGroupSO';
import WidgetCard from '../../Components/WidgetCard';
import { useAsyncExcel } from '../../util/useAsyncExcel';
import useRangeDateSO from '../util/useRangeDateSO';
import Chart from './Chart';
import { Trans } from 'react-i18next';
import { isValidData } from '../util/checkData';
import NoDataBlock from '../../Components/NoDataBlock';
import moment from 'moment';
import useDepartmentOptions from '../util/useDepartmentOptions';
import useDateTypeOptions from '../util/useDateTypeOptions';
import { LoadingBlock } from '@components';

// 외래 대기시간 분석 (진료과별)
const OutpatientWaitAnalysisByDepartment = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();
    const t = useTranslation('OutpatientReport');

    const departmentValues = useDepartmentOptions();
    const dateTypeValues = useDateTypeOptions();
    const dateValues = useRangeDateSO(dateTypeValues.dateType);

    const { state, promise: getData } = useAsync({
        promise: getOutpatientWaitDept,
        immediate: true,
        param: {
            departmentCode: departmentValues.department.toString(),
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
        },
        reject: err => console.error(err),
    });
    const data = state.response ? state.response.rows : [];
    const { insightTotalAvgWaits = 0, insightMaxDepartmentName = '', insightMaxWaitAvg = 0 } = state.response
        ? state.response.additionalDatas
        : {};

    const triggerDownload = useAsyncExcel({
        promise: postOutpatientWaitDeptExcel,
        param: {
            departmentCode: departmentValues.department.toString(),
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
            columnMetas: [
                {
                    key: 'departmentName',
                    name: t('Department name'),
                },
                {
                    key: 'departmentCode',
                    name: t('Department code'),
                },
                {
                    key: 'waitMax',
                    name: `${t('Max')} (${t('Minute')})`,
                },
                {
                    key: 'waitAvg',
                    name: `${t('Avg')} (${t('Minute')})`,
                },
                {
                    key: 'waitMin',
                    name: `${t('Min')} (${t('Minute')})`,
                },
            ],
            fileName:
                'so_kioskData_' +
                moment.unix(dateValues.startDate).format('YYYYMMDD') +
                '_' +
                moment.unix(dateValues.endDate).format('YYYYMMDD'),
            zipFileName: 'so_wait_by_department',
        },
        reject: err => console.error(err),
    });

    const triggerPatientWaitingTimeDownload = useAsyncExcel({
        promise: postOutpatientWaitTimeListExcel,
        param: {
            departmentCode: departmentValues.department.toString(),
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
            columnMetas: [
                {
                    key: 'patientId',
                    name: t('Patient ID'),
                },
                {
                    key: 'patientName',
                    name: t('Patient Name'),
                },
                {
                    key: 'receiptNum',
                    name: t('Receipt Number'),
                },
                {
                    key: 'departmentCode',
                    name: t('Department code'),
                },
                {
                    key: 'departmentName',
                    name: t('Department name'),
                },
                {
                    key: 'doctorId',
                    name: t('Doctor Code'),
                },
                {
                    key: 'doctorName',
                    name: t('Doctor Name'),
                },
                {
                    key: 'firstArrivalDate',
                    name: t('First Arrival Time'),
                },
                {
                    key: 'firstCareStartDate',
                    name: t('First Care Start Time'),
                },
                {
                    key: 'firstTestStartDate',
                    name: t('First Test Start Time'),
                },
                {
                    key: 'firstCareEndDate',
                    name: t('First Care End Time'),
                },
                {
                    key: 'arrivalDate',
                    name: t('Arrival Time'),
                },
                {
                    key: 'careStartDate',
                    name: t('Care Start Time'),
                },
                {
                    key: 'testStartDate',
                    name: t('Test Start Time'),
                },
                {
                    key: 'careEndDate',
                    name: t('Care End Time'),
                },
                {
                    key: 'firstWaits',
                    name: t('First Wait Time'),
                },
                {
                    key: 'lastWaits',
                    name: t('Final Wait Time'),
                },
                {
                    key: 'kioskYn',
                    name: t('Whether to use a kiosk'),
                },
            ],
            fileName:
                'so_waitTimeData' +
                moment.unix(dateValues.startDate).format('YYYYMMDD') +
                '_' +
                moment.unix(dateValues.endDate).format('YYYYMMDD'),
            zipFileName: 'so_wait_by_patient',
        },
        reject: err => console.error(err),
    });

    const handleDownloadClick = () => {
        if (!isValidData(data)) {
            return;
        }

        triggerDownload().then(() => {
            triggerPatientWaitingTimeDownload();
        });
    };

    return (
        <WidgetCard widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
            <SearchGroupSO
                showDateType
                dateTypeValues={dateTypeValues}
                dateValues={dateValues}
                showDepartment
                departmentValues={departmentValues}
                getData={getData}
                showDownload={isValidData(data)}
                handleDownload={handleDownloadClick}
            />
            <LoadingBlock blocking={state.isLoading}>
                {isValidData(data) ? (
                    <>
                        <InsightBox>
                            <BoxBodyText>
                                <Trans
                                    t={t}
                                    values={{ insightTotalAvgWaits, insightMaxDepartmentName, insightMaxWaitAvg }}
                                    components={[<HighlightedText />, <br />]}
                                >
                                    {data.length >= 2
                                        ? 'During the inquiry period, the average waiting time for outpatient services in the selected departments was <0>{{insightTotalAvgWaits}}</0> minutes. <1 /> The longest average waiting time was <0>{{insightMaxWaitAvg}}</0> minutes for <0>{{insightMaxDepartmentName}}</0>.'
                                        : 'During the inquiry period, the average waiting time for outpatient services in <0>{{insightMaxDepartmentName}}</0> was <0>{{insightTotalAvgWaits}}</0> minutes.'}
                                </Trans>
                            </BoxBodyText>
                        </InsightBox>
                        <Chart data={data} />
                    </>
                ) : (
                    <NoDataBlock text={t('No data')} />
                )}
            </LoadingBlock>
            {children}
        </WidgetCard>
    );
};

export default OutpatientWaitAnalysisByDepartment;
