import React, { useMemo } from 'react';
import {
    components,
    ControlProps,
    DropdownIndicatorProps,
    GroupBase,
    MenuListProps,
    Options,
    OptionsOrGroups,
} from 'react-select';
import { OptionType } from '@components/type';
import useTranslation from '@hooks/useTranslation';
import { MenuListInput } from '@components/Select/SearchableSelect/Parts';
import cx from 'classnames';
import { Icon } from '@components';
import { useAppSelector } from '@hooks';

export const Control = ({ children, selectProps, ...rest }: ControlProps<OptionType>) => {
    const { iconName } = selectProps;
    const { menuIsOpen } = rest;
    return (
        <components.Control
            className={`${iconName ? 'icon-on-left' : ''} ${menuIsOpen ? 'on' : ''} `}
            selectProps={selectProps}
            {...rest}
        >
            {children}
        </components.Control>
    );
};

export const DropdownIndicator = ({ ...rest }: DropdownIndicatorProps<OptionType>) => {
    const { colorScheme } = useAppSelector(state => state.ThemeOptions);

    return (
        <components.DropdownIndicator className={`p-0`} {...rest}>
            <Icon iconName={'arrow_drop_down'} className={cx('md-18', colorScheme === 'pnt-navy' && 'color-black')} />
        </components.DropdownIndicator>
    );
};

interface CommonMenuListProps extends MenuListProps<OptionType> {
    selected: Options<OptionType>;
    valueKey: string;
    labelKey: string;
    handleSelectedClick: (option: OptionType) => void;
    allOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>;
    treeOption?: boolean;
}
export const CommonMenuList = ({
    selected,
    valueKey,
    labelKey,
    handleSelectedClick,
    allOptions,
    setValue,
    children,
    treeOption,
    ...restProps
}: CommonMenuListProps) => {
    const t = useTranslation('TreeSelect');
    const { getValue, selectProps } = restProps;
    const { translator = (text: string) => text } = selectProps;
    const { showSearchInput, onMenuInputChange, onMenuInputFocusToggle, inputValue } = selectProps;
    const filteredSelected = useMemo(
        () => selected.filter(option => option[labelKey].toLowerCase().includes(inputValue.toLowerCase())),
        [selected, inputValue],
    );

    return (
        <div>
            {showSearchInput && !!onMenuInputChange && !!onMenuInputFocusToggle && (
                <MenuListInput onMenuInputChange={onMenuInputChange} onMenuInputFocusToggle={onMenuInputFocusToggle} />
            )}
            <components.MenuList className="select__options" {...restProps} setValue={setValue}>
                <div className={'flx-row tl p-1 border-bottom bg-depth-6'}>
                    <label className={cx('pnt-checkbox form-h-small')} htmlFor={'Selected'}>
                        <input
                            id={'Selected'}
                            type="checkbox"
                            onChange={() =>
                                setValue(
                                    selected.filter(
                                        option => !option[labelKey].toLowerCase().includes(inputValue.toLowerCase()),
                                    ),
                                    'deselect-option',
                                )
                            }
                            checked={
                                treeOption
                                    ? getValue().length === allOptions.length
                                    : selected.length === allOptions.length
                            }
                        />
                        <span className="checkbox-effect" />
                    </label>
                    <p className="pnt-txt txt-bold color-trans-black">{t('Selected')}</p>
                </div>
                <div className={'select__options-list'}>
                    {filteredSelected.length ? (
                        filteredSelected.map((option: OptionType, index) => (
                            <div
                                className={'styled-option select__option react-select__option'}
                                key={`${option[valueKey]}_${index}`}
                                onClick={() => handleSelectedClick(option)}
                            >
                                <label className="pnt-checkbox form-h-small check-checked" htmlFor={option[labelKey]}>
                                    <input
                                        id={option[labelKey]}
                                        type="checkbox"
                                        value={option[valueKey]}
                                        checked
                                        onChange={() => null}
                                    />
                                    <span className="checkbox-effect" />
                                </label>
                                <span className="overflow-hidden text-ellipsis">{translator(option[labelKey])}</span>
                            </div>
                        ))
                    ) : (
                        <div className={'styled-option-label'}>{t('Not Selected')}</div>
                    )}
                </div>
                <div className="pnt-divider horizon-line border-gray opacity-6 mb-0" />
                <div className={'flx-row tl p-1 border-bottom bg-depth-5'}>
                    <label className={cx('pnt-checkbox form-h-small')} htmlFor={'All'}>
                        <input
                            id={'All'}
                            type="checkbox"
                            onChange={event => {
                                const { checked } = event.currentTarget;
                                setValue(
                                    checked
                                        ? allOptions.filter(
                                              (option: OptionType) =>
                                                  option[labelKey]
                                                      .toLowerCase()
                                                      .includes(selectProps.inputValue.toLowerCase()) ||
                                                  selected.includes(option),
                                          )
                                        : [],
                                    'select-option',
                                );
                            }}
                            checked={
                                treeOption
                                    ? getValue().length === allOptions.length
                                    : selected.length === allOptions.length
                            }
                        />
                        <span className="checkbox-effect" />
                    </label>
                    <p className="pnt-txt txt-bold color-trans-black">{t('All')}</p>
                </div>
                {children}
            </components.MenuList>
        </div>
    );
};
