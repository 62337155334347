import React, { useRef } from 'react';
import { useAsync } from '@hooks';
import WidgetCard from '../../Components/WidgetCard';
import { setCongestionData } from './Context/slice';
import Map from './Map';
import SearchContainer from './SearchContainer';
import { useOutpatientCongestionContext, useOutpatientCongestionDispatch } from './Context';
import { getCongestionSpace } from '@api/so/outpatient';
import moment from 'moment';

const COUNT_PER_HOUR = 12; // 시간당 데이터 개수, 5분에 하나기 때문에 1시간에 12개
const COUNT_PER_DAY = COUNT_PER_HOUR * 24; // 1일당 데이터 개수
const START_TIME = 8; // 오전 8시
const PERIOD_WITH_RESPONSE = 11; // 응답 데이터가 있는 기간, 08시~19시 => 11시간
const MAX_RESPONSE_COUNT_PER_DAY = COUNT_PER_HOUR * PERIOD_WITH_RESPONSE; // 132
const HOUR_PER_DAY = 24;

const EMPTY_CONGESTION = {
    kioskName: '',
    lat: 0,
    lng: 0,
    congestion: 0,
};

const WidgetContents = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();

    const dispatch = useOutpatientCongestionDispatch();
    const { startDate, endDate, selectedFloorInfo } = useOutpatientCongestionContext();
    const diff = moment.unix(endDate).diff(moment.unix(startDate), 'days');

    const { promise: getData } = useAsync({
        promise: getCongestionSpace,
        param: {
            floor: selectedFloorInfo.floorId,
            startDate: startDate,
            endDate: endDate,
        },
        resolve: ({ rows }) => {
            // 1일당 288개 생성
            const arr = Array.from({ length: COUNT_PER_DAY * (diff + 1) }, () => [EMPTY_CONGESTION]);

            // 생성된 arr의 시간대에 맞는 index에 rows 값 삽입
            // rows는 온전히 모든 시간대에 들어있다면 하루당 132개
            // arr[96] = rows[0] ... arr[227] ... rows[131]
            // arr[384] = rows[132] ... arr[515] ... rows[263]
            // arr[672] = rows[264] ... arr[803] ... rows[395]
            for (let i = 0; i <= diff; i++) {
                for (let j = MAX_RESPONSE_COUNT_PER_DAY * i; j < MAX_RESPONSE_COUNT_PER_DAY * (i + 1); j++) {
                    arr[COUNT_PER_HOUR * (START_TIME + HOUR_PER_DAY * i) + (j - MAX_RESPONSE_COUNT_PER_DAY * i)] = rows[
                        j
                    ] ?? [EMPTY_CONGESTION]; // rows의 특정 index가 없다면(undefined) 초기값 삽입
                }
            }

            dispatch(setCongestionData(arr));
        },
        reject: err => console.error(err),
    });

    return (
        <WidgetCard widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
            <SearchContainer getData={getData} />
            <Map />
            {children}
        </WidgetCard>
    );
};

export default WidgetContents;
