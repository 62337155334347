import React, { ComponentType, createContext, useEffect, useMemo, useReducer, useState } from 'react';
import { ControlProps, MenuListProps, OptionProps, StylesConfig } from 'react-select';

import { Select } from '@components';
import { SelectProps } from '@components/Select';
import { OptionType } from '@components/type';
import { SelectControl } from '@components/Select/Parts';
import {
    MenuPortal,
    MultiSelectMenuList,
    MultiSelectOption,
    ValueContainer,
} from '@components/Select/SearchableSelect/Parts';
import { DropdownIndicator } from '@components/Select/Components/Common';
import { SearchableSelectReducer, SearchableSelectState } from '@components/Select/utils';

import { defaultStyle } from '../selectStyle';

const selectStyles: StylesConfig<OptionType> = {
    ...defaultStyle,
    container: provided => ({
        ...provided,
        width: 'unset',
        minWidth: '9rem',
        maxWidth: '14rem',
    }),
    menu: () => ({ minWidth: '11.5rem', boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
};

export const SearchableSelectContext = createContext<SearchableSelectState>({ labelKey: 'label', valueKey: 'value' });

export interface SearchableSelectProps extends Partial<SelectProps> {
    data: OptionType[];
    selected?: any[];
    title?: string;
    keepSortOrder?: boolean;
    MenuListComponent?: ComponentType<MenuListProps<OptionType>>;
    OptionComponent?: ComponentType<OptionProps<OptionType>>;
    ControlComponent?: ComponentType<ControlProps<OptionType>>;
    showSearchInput?: boolean;
}

const SearchableSelect = ({
    className,
    data = [],
    selected = [],
    title,
    labelKey = 'label',
    valueKey = 'value',
    onChange,
    name,
    MenuListComponent,
    OptionComponent,
    ControlComponent,
    keepSortOrder = false,
    isModalSelect = false,
    showSearchInput,
    placeholder,
    ...restProps
}: SearchableSelectProps) => {
    const [state, dispatch] = useReducer(SearchableSelectReducer, { title, labelKey, valueKey });

    useEffect(() => {
        dispatch({ type: 'UPDATE_TITLE', payload: title });
    }, [title]);

    const selectedOptions = useMemo(() => {
        if (keepSortOrder) {
            return (selected || []).reduce((acc: OptionType[], curr) => {
                const selectedData = data.find(option => option[valueKey] === curr);
                if (selectedData) {
                    acc.push(selectedData);
                }
                return acc;
            }, []);
        }
        return data.reduce((acc: OptionType[], curr) => {
            if ((selected || []).includes(curr[valueKey])) {
                acc.push(curr);
            }
            return acc;
        }, []);
    }, [data, selected, valueKey, keepSortOrder]);

    const [inputValue, setInputValue] = useState('');
    // 메뉴 내부의 input Element 관한 focus
    const [inputFocused, setInputFocused] = useState(false);

    // 메뉴가 열고 닫힐 때 input 의 값을 초기상태로 함.
    useEffect(() => {
        setInputValue('');
    }, [inputFocused]);

    return (
        <SearchableSelectContext.Provider value={state}>
            <Select
                // 기존 MultiSelect Props
                isMulti={true}
                className={className}
                closeMenuOnSelect={false}
                // Searchable Select Props
                isModalSelect={isModalSelect}
                backspaceRemovesValue={false}
                components={{
                    Control: ControlComponent || SelectControl,
                    ValueContainer: ValueContainer,
                    DropdownIndicator,
                    MenuPortal,
                    MenuList: MenuListComponent || MultiSelectMenuList,
                    Option: OptionComponent || MultiSelectOption,
                    IndicatorSeparator: null,
                }}
                controlShouldRenderValue={false}
                isClearable={false}
                onChange={onChange}
                options={data}
                styles={selectStyles}
                tabSelectsValue={false}
                value={selectedOptions}
                name={name}
                valueKey={valueKey}
                labelKey={labelKey}
                // 검색 기능 관련 Props
                onInputChange={(_, { action, prevInputValue }) => {
                    if (action === 'input-change') {
                        return inputValue;
                    }
                    return prevInputValue;
                }}
                inputValue={inputValue}
                onMenuInputChange={inputValue => setInputValue(inputValue)}
                onMenuInputFocusToggle={() => setInputFocused(true)}
                menuIsOpen={inputFocused || undefined}
                onMenuClose={() => setInputFocused(false)}
                // showSearchInput props 를 통해서 보여주는지 여부를 결정하고, option 의 개수가 10개 이상이면 search Input 보여줌
                showSearchInput={showSearchInput || (showSearchInput === undefined && data.length >= 10)}
                placeholder={placeholder}
                {...restProps}
            />
        </SearchableSelectContext.Provider>
    );
};

export default SearchableSelect;
