import React from 'react';
import cx from 'classnames';

interface HighlightProps {
    className?: string;
    originText: string;
    highlightText: string;
    color?: string;
}

const escapeRegExp = (text: string) => {
    return text.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};

const HighlightedText = ({ originText, highlightText, color, className }: HighlightProps) => {
    if (!originText.length || !highlightText.length) return <span>{originText}</span>;
    const safeHighlightText = escapeRegExp(highlightText);
    const parts = originText.split(new RegExp(`(${safeHighlightText})`, 'gi'));

    return (
        <span>
            {parts.map((part, index) =>
                part === highlightText ? (
                    <span key={index} className={cx(color ? color : 'bg-brand', className)}>
                        {part}
                    </span>
                ) : (
                    part
                ),
            )}
        </span>
    );
};

export default HighlightedText;
