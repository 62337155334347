import React, { createContext, useEffect, useMemo, useReducer, useState } from 'react';
import useAsync from '@hooks/useAsync';
import useTranslation from '@hooks/useTranslation';
import reducer, { editGroupInfo, initialState, setInitialState, unmapUser } from './reducer';
import { createAuthGroupApi, fetchAuthGroup, updateAuthGroupApi } from '@api/common/authGroup';
import { Button, Card, Label, Table, TextInput } from '@components';
import { useConfirmModal } from '@hooks';
import BulkProcessModal, { MODAL_MODE_MAPP, MODAL_MODE_UNMAPP } from './Components/BulkProcessModal';
import useMoreUserInfo from '../../../../Login/hooks/useMoreUserInfo';

export const AuthorizationGroupDetailContext = createContext();
export const AuthorizationGroupDetailDispatchContext = createContext();

const AuthorizationGroupDetail = ({ history, match }) => {
    const {
        params: { groupNum },
    } = match;

    const t = useTranslation('AuthorizationGroup');
    const buttonT = useTranslation('Button');
    const [state, dispatch] = useReducer(reducer, initialState);

    const { editing, userListInfo } = state;

    const [openBulkProcessModal, setOpenBulkProcessModal] = useState({ opened: false, mode: MODAL_MODE_MAPP });
    const toggleBulkProcessModal = () => {
        setOpenBulkProcessModal({ ...openBulkProcessModal, opened: !openBulkProcessModal.opened });
    };
    const [validationMsg, setValidationMsg] = useState([]);
    const getMoreUserInfo = useMoreUserInfo();

    const { Modal: SaveConfirmModal, toggleModal: toggleSaveConfirmModal } = useConfirmModal({
        confirmText: groupNum ? t('Do you want to save information?') : t('Do you want to create authorization group?'),
        okCallback: () => {
            const validationList = [];
            if (!editing.groupName?.trim()) {
                validationList.push(t('Authorization group name is required.'));
            }
            if (!validationList.length) {
                const { groupNum, groupName, description, authUserNumList } = editing;
                const saveParam = { groupName, description, authUserNumList };
                if (groupNum) {
                    saveParam.groupNum = groupNum;
                }
                saveGroupInfo(saveParam);
            } else {
                setValidationMsg(validationList);
                toggleValidationModal();
            }
        },
    });

    const { Modal: ValidationModal, toggleModal: toggleValidationModal } = useConfirmModal({
        header: { title: t('Validation Fail') },
        confirmText: (
            <>
                {validationMsg.map((msg, i) => (
                    <li key={`validation_${i}`}>
                        <span className="pnt-label--group">
                            <div className="label-main w-100" style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                                {msg}
                            </div>
                        </span>
                    </li>
                ))}
            </>
        ),
    });

    const { Modal: SaveSuccessModal, toggleModal: toggleSaveSuccessModal } = useConfirmModal({
        confirmText: groupNum
            ? t('Authorization group is changed successfully.')
            : t('Authorization group is created successfully.'),
        okCallback: () => {
            history.goBack();
        },
    });

    const { Modal: SaveFailureModal, toggleModal: toggleSaveFailureModal } = useConfirmModal({
        confirmText: t('The request failed.', 'ErrorHandler'),
    });

    const columns = useMemo(() => {
        return [
            {
                Header: t('ID'),
                className: 'pr-3 pl-3 text-ellipsis flex-center',
                accessor: 'userId',
                width: 120,
                Cell: ({ value }) => <div className={'w-100 text-ellipsis'}>{value}</div>,
            },
            {
                Header: t('Name'),
                className: 'pr-3 pl-3 text-ellipsis flex-center',
                accessor: 'userName',
                Cell: ({ value }) => <div className={'w-100 text-ellipsis'}>{value}</div>,
            },
            {
                Header: t('User Group'),
                className: 'pr-3 pl-3 text-ellipsis',
                accessor: 'loginGroupList',
                formatTitle: ({ value }) =>
                    value[0] ? value[0].groupName + (value[1] ? ` ${t('other')} ${value.length - 1}` : '') : '',
                Cell: ({ value }) => {
                    const label = value[0]
                        ? value[0].groupName + (value[1] ? ` ${t('other')} ${value.length - 1}` : '')
                        : '';
                    return value[0] ? <Label name={label} labelValueClassName={'label-dot'} /> : null;
                },
            },
            {
                Header: t('Division of work'),
                className: 'pr-3 pl-3 text-ellipsis flex-center',
                accessor: 'work',
                Cell: ({ value }) => <div className={'w-100 text-ellipsis'}>{value}</div>,
            },
            {
                Header: t('Action'),
                width: 70,
                className: 'flex-center overflow-visible',
                accessor: 'userNum',
                Cell: ({ value }) => (
                    <div className={'d-flex w-100 justify-content-center'}>
                        <Button
                            className={'btn-danger btn-icon-only'}
                            iconName="delete"
                            onClick={() => {
                                dispatch(unmapUser([value]));
                            }}
                        />
                    </div>
                ),
            },
        ];
    }, [t]);

    const { promise: getAuthGroup } = useAsync({
        promise: fetchAuthGroup,
        resolve: response => {
            dispatch(setInitialState(response));
        },
    });

    const { promise: saveGroupInfo } = useAsync({
        promise: groupNum ? updateAuthGroupApi : createAuthGroupApi,
        resolve: () => {
            getMoreUserInfo();
            toggleSaveSuccessModal();
        },
        reject: () => {
            toggleSaveFailureModal();
        },
    });

    useEffect(() => {
        if (groupNum && !isNaN(groupNum)) {
            getAuthGroup({ groupNum: Number(groupNum) });
        } else {
            dispatch(setInitialState());
        }
    }, [groupNum]);

    return (
        <AuthorizationGroupDetailDispatchContext.Provider value={dispatch}>
            <AuthorizationGroupDetailContext.Provider value={state}>
                <Card
                    className="h-100 mb-0"
                    header={{
                        title: t('Authorization Group Detail'),
                        iconName: groupNum ? 'info' : 'edit',
                        action: (
                            <>
                                <Button
                                    className="btn-gray"
                                    onClick={() => {
                                        history.goBack();
                                    }}
                                >
                                    {buttonT('Cancel')}
                                </Button>
                                <Button
                                    className={'btn-icon btn-brand'}
                                    iconName="save"
                                    onClick={toggleSaveConfirmModal}
                                >
                                    {buttonT('Save')}
                                </Button>
                            </>
                        ),
                    }}
                    bodyClassName={'d-flex flex-column'}
                >
                    <Label
                        labelGroupClassName="pnt-label-6 mb-3"
                        name={t('Authorization Group Name')}
                        value={
                            <TextInput
                                inputGroupClassName={'form-must'}
                                name={'groupName'}
                                value={editing.groupName || ''}
                                placeholder={t('Please enter a name')}
                                handleChange={e => dispatch(editGroupInfo({ groupName: e.currentTarget.value }))}
                            />
                        }
                    />
                    <Label
                        labelGroupClassName="pnt-label-6 mb-3"
                        name={t('Authorization Group Description')}
                        value={
                            <TextInput
                                name={'description'}
                                value={editing.description || ''}
                                placeholder={t('Please enter a description')}
                                handleChange={e => dispatch(editGroupInfo({ description: e.currentTarget.value }))}
                            />
                        }
                    />
                    <div className="pnt-divider horizon-line mb-3" />
                    <div className={'d-flex mb-3'}>
                        <Label name={t('Authorization')} />
                        <div className={'d-flex justify-content-end gap-1 w-100'}>
                            <Button
                                className="btn-danger"
                                onClick={() => {
                                    setOpenBulkProcessModal({ mode: MODAL_MODE_UNMAPP, opened: true });
                                }}
                            >
                                {t('Bulk unmap')}
                            </Button>
                            <Button
                                className="btn-icon btn-secondary"
                                iconName="add"
                                onClick={() => {
                                    setOpenBulkProcessModal({ mode: MODAL_MODE_MAPP, opened: true });
                                }}
                            >
                                {t('Add')}
                            </Button>
                        </div>
                    </div>
                    <div className={'flx-1'}>
                        <Table columns={columns} data={userListInfo} manualPagination={false} />
                    </div>
                </Card>
                <SaveConfirmModal />
                <ValidationModal />
                <SaveSuccessModal />
                <SaveFailureModal />
                <BulkProcessModal
                    initModal={openBulkProcessModal.opened}
                    mode={openBulkProcessModal.mode}
                    toggleModal={toggleBulkProcessModal}
                />
            </AuthorizationGroupDetailContext.Provider>
        </AuthorizationGroupDetailDispatchContext.Provider>
    );
};

export default AuthorizationGroupDetail;
