import { useState } from 'react';
import { DeviceInfo, DeviceType } from '../DeviceInfoModal';

interface DeviceInfoList {
    SENSOR: DeviceInfo[];
    SCANNER: DeviceInfo[];
}

const useDeviceInfoList = () => {
    const [deviceList, setDeviceList] = useState<DeviceInfoList>({
        SENSOR: [],
        SCANNER: [],
    });

    // 매핑되어있는 기기 있을 시 초기 설정
    const handleInitDevice = (info: { sensor: DeviceInfo[]; scanner: DeviceInfo[] }) => {
        setDeviceList({ SENSOR: info.sensor, SCANNER: info.scanner });
    };

    // 검색 후 하나씩 매핑 할 때 사용
    const handleAddDevice = (info: DeviceInfo, deviceType: DeviceType) => {
        setDeviceList(prev => ({ ...prev, [deviceType]: [{ ...info, deviceType, realMapping: false }] }));
    };

    const handleRemoveDevice = (deviceType: DeviceType) => {
        setDeviceList(prev => ({ ...prev, [deviceType]: [] }));
    };

    const handleResetDevice = () => {
        setDeviceList({ SENSOR: [], SCANNER: [] });
    };

    return {
        deviceList,
        handleInitDevice,
        handleAddDevice,
        handleRemoveDevice,
        handleResetDevice,
    };
};

export default useDeviceInfoList;
