import React from 'react';
import { useAsync, useColumns, useConfirm, useTranslation } from '@hooks';
import { AlertTooltip, Button, ConfirmModal, Table } from '@components';
import { WidgetProps } from '../../staticInfo';
import WidgetCard from '../../Components/WidgetCard';
import {
    deleteAnomalyAlertSettingApi,
    PatientAnomalyAlertSetting as PatientAnomalyAlertSettingType,
} from '@api/sh/patientAnomalyAlertSetting';
import styled from 'styled-components';
import GroupSelect from './GroupSelect';
import anomalyAlertSlice, {
    useAnomalyAlertDispatchContext,
    useAnomalyAlertStateContext,
    selectGroupNum,
    AnomalyAlertStateContext,
    AnomalyAlertDispatchContext,
    setAnomalyAlertSettingListInfo,
    selectAlertSettingGroupNum,
} from './slice';
import ContextProvider from '@components/ContextProvider';
import { YN } from '@util/type/util';
import AlertSettingModal from './AlertSettingModal';
import useModal from '../../util/useModal';
import { PAGE_SIZE, YN_APPLY, YN_SELECT, YN_USE } from './constants';
import { useGetPatientAnomalyAlertSettingList, useUpdateGroupInfo } from './hooks';

const PatientAnomalyAlertSetting = ({ widgetInfo, children, ...restProps }: WidgetProps) => {
    return (
        <ContextProvider
            StateContext={AnomalyAlertStateContext}
            DispatchContext={AnomalyAlertDispatchContext}
            slice={anomalyAlertSlice}
        >
            <ContextConsumer widgetInfo={widgetInfo} {...restProps}>
                {children}
            </ContextConsumer>
        </ContextProvider>
    );
};

const ContextConsumer = ({ widgetInfo, children, ...restProps }: WidgetProps) => {
    const t = useTranslation('PatientAnomalyAlertSetting');
    const dispatch = useAnomalyAlertDispatchContext();
    const {
        selectedGroupNum,
        anomalyAlertSettingListInfo,
        selectedAlertSettingGroupNum,
    } = useAnomalyAlertStateContext();
    const [isOpen, handleToggle] = useModal();

    const columns = useColumns(
        [
            { Header: 'Sensor Setting Name', className: 'text-ellipsis', accessor: 'alertSettingName', width: 350 },
            {
                Header: 'Alert Enabled',
                accessor: 'alertActive',
                Cell: ({ value }: { value: YN }) => {
                    return t(YN_APPLY[value]);
                },
            },
            {
                Header: 'Alert Criteria',
                accessor: 'alertOpenCount',
                Cell: ({
                    value,
                    row: { original },
                }: {
                    value: YN;
                    row: { original: PatientAnomalyAlertSettingType };
                }) => {
                    return `${original.alertOpenTime}${t('min')} / ${original.alertOpenCount}${t('times')}`;
                },
                width: 200,
            },
            {
                Header: 'Alert Open Limit Criteria',
                accessor: 'alertSnoozeActive',
                Cell: ({ value }: { value: YN }) => {
                    return t(YN_USE[value]);
                },
                width: 180,
            },
            {
                Header: 'Alert Auto-Close Criteria',
                accessor: 'alertCloseCount',
                Cell: ({ value }: { value: YN }) => {
                    return `${value}${t('times')}`;
                },
                width: 180,
            },
            {
                Header: 'Select Restricted Area',
                accessor: 'alertRestrictionActive',
                Cell: ({ value }: { value: YN }) => {
                    return t(YN_SELECT[value]);
                },
                width: 180,
            },
            {
                Header: 'Sound Alert',
                accessor: 'alertSoundUse',
                Cell: ({ value }: { value: YN }) => {
                    return t(YN_USE[value]);
                },
            },
            {
                Header: 'Edit',
                accessor: 'alertSettingGroupNum',
                className: 'p-0',
                Cell: ({ value }: { value: YN }) => {
                    return (
                        <ActionWrapper>
                            <Button
                                onClick={() => {
                                    dispatch(selectAlertSettingGroupNum(value));
                                    handleToggle();
                                }}
                            >
                                {t('Modify')}
                            </Button>
                            <Button
                                onClick={() => {
                                    dispatch(selectAlertSettingGroupNum(value));
                                    deleteConfirmModalProps.toggleModal();
                                }}
                            >
                                {t('Delete')}
                            </Button>
                        </ActionWrapper>
                    );
                },
            },
        ],
        t,
    );

    const { promise: getAnomalyAlertGroupSettingsList } = useUpdateGroupInfo();

    const { promise: getPatientAnomalyAlertSettingList } = useGetPatientAnomalyAlertSettingList({
        immediate: !!selectedGroupNum,
    });

    const { promise: deleteAnomalyAlertSetting } = useAsync({
        promise: deleteAnomalyAlertSettingApi,
        resolve: () => {
            getPatientAnomalyAlertSettingList();
            getAnomalyAlertGroupSettingsList();
        },
        reject: () => {
            deleteFailureModalProps.toggleModal();
        },
    });

    const deleteConfirmModalProps = useConfirm({
        confirmText: t('Do you want to delete your alert settings?'),
        callbackParam: selectedAlertSettingGroupNum,
        okCallback: (selectedAlertSettingGroupNum: number) => {
            deleteAnomalyAlertSetting({ alertSettingGroupNum: selectedAlertSettingGroupNum });
        },
    });

    const deleteFailureModalProps = useConfirm({
        confirmText: t('Delete failed.'),
        removeCancel: true,
    });

    return (
        <WidgetCard widgetInfo={widgetInfo} Title={CustomTitle} {...restProps}>
            <ContentWrapper>
                <section>
                    <GroupSelect
                        selectedGroupNum={selectedGroupNum}
                        handleSelectGroup={selected => {
                            dispatch(selectGroupNum(selected));
                        }}
                    />
                </section>
                <section className={'list-section'}>
                    <div className={'text-end'}>
                        <Button
                            className={'btn-secondary ml-auto'}
                            onClick={() => {
                                dispatch(selectAlertSettingGroupNum(null));
                                handleToggle();
                            }}
                        >
                            {t('Add sensor')}
                        </Button>
                    </div>
                    <Table
                        manualPagination={false}
                        autoResetPage={false}
                        columns={columns}
                        data={{
                            ...anomalyAlertSettingListInfo,
                            totalPage: Math.ceil(anomalyAlertSettingListInfo.rows.length / PAGE_SIZE),
                            pageSize: PAGE_SIZE,
                        }}
                        onPageChange={page => {
                            dispatch(setAnomalyAlertSettingListInfo({ ...anomalyAlertSettingListInfo, page: page }));
                        }}
                        noDataMessage={
                            <div className={'text-center'}>
                                {t('There are no sensors with saved notification settings.')}
                                <br />
                                {t("Proceed to 'Add sensor'.")}
                            </div>
                        }
                    />
                </section>
            </ContentWrapper>
            {!!selectedGroupNum && <AlertSettingModal initModal={isOpen} toggleModal={handleToggle} />}
            <ConfirmModal {...deleteConfirmModalProps} />
            <ConfirmModal {...deleteFailureModalProps} />
            {children}
        </WidgetCard>
    );
};

const ContentWrapper = styled.div`
    height: 100%;
    grid-template-rows: min-content auto;
    display: grid;
    row-gap: 1.5rem;
    .list-section {
        height: 100%;
        display: grid;
        grid-template-rows: min-content auto;
        row-gap: 0.5rem;
    }
`;

const CustomTitle = ({ name }: { name: string }) => {
    const t = useTranslation('PatientAnomalyAlertSetting');
    return (
        <span className={'flex-center gap-1'}>
            {name}
            <AlertTooltip
                contents={
                    <>
                        {t('You can set up anomaly alerts by department.')}
                        <br />
                        {t(
                            'You can set them all up per department, but you can only select one department to receive the alert, and a ‘bell’ icon will be created for the selected department.',
                        )}
                        <br />
                        {t(
                            'In the ‘Personalisation’ menu on the top right, select the department you want to enable notifications for department you want to use the alerts for.',
                        )}
                    </>
                }
            />
        </span>
    );
};

const ActionWrapper = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: center;
    height: 100%;
    padding: 0.25rem 0.5rem;
    button.pnt-btn {
        min-width: unset;
        height: 100%;
    }
`;

export default PatientAnomalyAlertSetting;
