import { useDispatch } from 'react-redux';
import useAsync from '@hooks/useAsync';
import { fetchUserInfo, getUserGroupInfoApi } from '@api/common/login';
import { fetchCompany } from '@api/common/company';
import { getUserSettingApi } from '@api/common/common';
import { DEFAULT_LANGUAGE, setAdditionalInfo, setAnomalyAlertGroupNum, setLanguage } from '@reducer/UserInfo';
import { setMarkerConfig } from '@reducer/Common/AppConfig';
import { DEFAULT_COLOR_SCHEME, setColorScheme } from '@reducer/ThemeOptions';
import { handleSelectedNotificationTypes } from '@reducer/Notification';
import { NOTIFICATION_TYPE } from '@util/mappInfo';

/**
 * 유저 로그인 시 유저와 관련된 정보 조회
 * - 유저가 생성한 대시보드 정보
 * - 유저 정보
 * - 유저가 속한 회사 정보
 * - 유저 App 개인 설정 정보
 *
 * @returns {(function(*): Promise<void>)|*} 유저 관련 정보 전체 API 호출 Promise
 */
const useMoreUserInfo = () => {
    const dispatch = useDispatch();

    const { promise: getMoreUserInfo } = useAsync({
        promise: [fetchUserInfo, fetchCompany, getUserGroupInfoApi, getUserSettingApi],
        param: [
            { lookupItems: 'userName' },
            null,
            null,
            {
                propId: 'color',
            },
        ],
        resolve: ([{ data: userInfo }, { data: companyInfo }, { rows: groupInfo }, { data: setting }]) => {
            const additionalInfo = {
                userNum: userInfo.userNum,
                userName: userInfo.userName,
                roleNum: userInfo.roleNum,
                authGroupNum: userInfo.serviceProductInfo.authGroupNum,
                serviceProductRoleList: userInfo.serviceProductInfo.serviceProductRoleList,
                groupId: null,
                groupName: '',
                groupNum: '',
                groupNums: [],
                notificationTypes: setting.alertTypes || [NOTIFICATION_TYPE.USER],
                propValue: setting.propValue || DEFAULT_COLOR_SCHEME,
                lang: setting.description || DEFAULT_LANGUAGE,
                userID: userInfo.userID,
                tempPW: userInfo.tempPW,
                companyInfo,
            };
            if (companyInfo) {
                dispatch(
                    setMarkerConfig({
                        markerTransitionActive: companyInfo.markerTransitionActive,
                        markerPulseActive: companyInfo.markerPulseActive,
                        markerBorderRadius: companyInfo.markerBorderRadius,
                        markerPulseAnimation: companyInfo.markerPulseAnimation,
                        markerPulseRssi: companyInfo.markerPulseRssi,
                        markerPulseColor: companyInfo.markerPulseColor,
                    }),
                );
            }

            if (groupInfo && groupInfo[0]) {
                // 자산 대여를 위한 그룹 정보 저장. 추후 수정 필요
                additionalInfo.groupId = groupInfo[0].groupId;
                additionalInfo.groupName = groupInfo[0].groupName;
                additionalInfo.groupNum = groupInfo[0].groupNum;
                additionalInfo.groupNums = groupInfo.map(v => v.groupNum);
                additionalInfo.representationGroupInfo = groupInfo.find(group => !!group.floorId) || groupInfo[0];
                additionalInfo.groupInfoList = groupInfo;
            }

            // 임시 추후 수정 예정
            // 개인설정 값 (lang:언어, colorTheme:테마 색상, alertType:알람 타입)
            if (setting) {
                const {
                    description: lang,
                    propValue: colorTheme,
                    alertTypes: selectedNotificationTypes,
                    groupNum,
                } = setting;
                dispatch(setLanguage(lang || DEFAULT_LANGUAGE));
                dispatch(setColorScheme(colorTheme || DEFAULT_COLOR_SCHEME));
                dispatch(handleSelectedNotificationTypes(selectedNotificationTypes || [NOTIFICATION_TYPE.USER]));
                dispatch(setAnomalyAlertGroupNum(groupNum));
            }

            dispatch(setAdditionalInfo(additionalInfo));
        },
    });
    return getMoreUserInfo;
};

export default useMoreUserInfo;
