import React from 'react';
import { Button, Icon } from '@components';
import { useAsync, useConfirmModal, useTranslation } from '@hooks';
import { fetchExcelLogExport } from '@api/common/log';
import { useLocationLogContext } from '../index';
import { makeParameter } from '../../../util/commons';
import moment from 'moment';

/**
 * 위치로그 정보를 엑셀 파일로 출력하는 컴포넌트
 *
 * @author jinbeom-jung
 * */
const ExcelExport = ({ startDate, endDate, listCount }) => {
    const t = useTranslation('Location Log');
    const { keyword, searchOption } = useLocationLogContext();
    const { promise: createNewLogFile } = useAsync({
        promise: fetchExcelLogExport,
        fixedParam: {
            startDate: moment(startDate).unix(),
            endDate: moment(endDate).unix(),
            zipFileName: 'Location_Log',
            fileName: 'Location_Log',
            columnMetas: [
                {
                    key: 'logNum',
                    name: t('No.', 'CommonColumn'),
                },
                {
                    key: 'targetName',
                    name: t('Target Name', 'CommonColumn'),
                },
                {
                    key: 'beaconName',
                    name: t('Device Name', 'CommonColumn'),
                },
                {
                    key: 'floor',
                    name: t('Floor', 'CommonColumn'),
                },
                {
                    key: 'lat',
                    name: t('Latitude', 'CommonColumn'),
                },
                {
                    key: 'lng',
                    name: t('Longitude', 'CommonColumn'),
                },
            ],
        },
        resolve: () => {
            toggleExcelDownloadResultModal();
        },
        reject: err => console.error(err),
    });

    const handleExportFileClick = () => {
        if (listCount) {
            const result = makeParameter({ searchRequirement: { keyword, opt: searchOption.value }, useIsAll: true });
            createNewLogFile(result);
        } else {
            toggleExcelConfirmModal();
        }
    };

    const { Modal: ExcelDownloadResultModal, toggleModal: toggleExcelDownloadResultModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Excel Download', 'Report') },
        confirmText: t(
            'You can see and download the Excel File that you have requested under `Report > Location Log List` menu.',
        ),
        removeCancel: true,
    });

    const { Modal: ExcelConfirmModal, toggleModal: toggleExcelConfirmModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        header: { title: t('Excel Download', 'Report') },
        confirmText: t('No data', 'Report'),
    });
    return (
        <>
            <Button className="btn-lightgray btn-icon" onClick={handleExportFileClick}>
                <Icon iconName={'file_upload'} />
                {t('Export', 'Button')}
            </Button>
            <ExcelDownloadResultModal />
            <ExcelConfirmModal />
        </>
    );
};

export default ExcelExport;
