import React, { MouseEvent, useEffect, useState } from 'react';
import cx from 'classnames';
import { Card as RSCard, CardBody } from 'reactstrap';
import { TabProps } from '@components/Tab';

export interface TabWithTitleProps extends Omit<TabProps, 'activeTabId' | 'className'> {
    title: string;
    subTitle: string;
}

const TabWithTitle = ({ tabs = [], styles, handleTabChange = () => {}, title, subTitle }: TabWithTitleProps) => {
    const [tabOn, setTabOn] = useState(tabs[0].tabId);
    useEffect(() => {
        handleTabChange(tabOn);
    }, [tabOn]);

    const handleTabClick = (e: MouseEvent<HTMLLIElement>) => {
        e.preventDefault();
        setTabOn(e.currentTarget.id);
    };

    return (
        <RSCard>
            <div className="card-header">
                <div className="card-header__title">
                    <div className="title-wrap">
                        <h3 className="title__main">{title}</h3>
                        <p className="title__sub">{subTitle}</p>
                    </div>
                </div>
                <div className="card-header__tab">
                    <ul>
                        {tabs.map((data, index) => (
                            <li
                                key={`${data.tabId}_${index}`}
                                id={data.tabId}
                                className={cx(tabOn === data.tabId && 'on', 'cursor-pointer')}
                                style={{ ...styles?.headerItem }}
                                onClick={handleTabClick}
                            >
                                <a href={'#!'}>{data.title}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <CardBody>
                {tabs.map((data, index) => (
                    <div key={`${data.tabId}_${index}`} className={cx(tabOn !== data.tabId && 'd-none')}>
                        {data.content}
                    </div>
                ))}
            </CardBody>
        </RSCard>
    );
};

export default TabWithTitle;
