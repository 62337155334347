import React from 'react';
import styled from 'styled-components';
import bulb from '@asset/images/emojione_light-bulb.png';

const InsightBox = ({ children }) => {
    return (
        <div className="mt-2 py-2">
            <Box>
                <Image src={bulb} />
                <div>
                    <BoxHeaderText>{'Insight'}</BoxHeaderText>
                    {children}
                </div>
            </Box>
        </div>
    );
};

const Box = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ffeeb6;
    background: linear-gradient(0deg, #fff5d5, #fff5d5), linear-gradient(0deg, #ffeeb6, #ffeeb6);
    padding: 10px 160px;
    font-size: 20px;
    font-weight: 600;
    line-height: 2.1rem;
`;

const Image = styled.img`
    width: 40px;
    height: 40px;
    margin-right: 10px;
`;

const BoxHeaderText = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: #bf9b23 !important;
`;

export const BoxBodyText = styled.div`
    padding: 5px 0;
    color: #665212 !important;
`;

export const HighlightedText = styled.span`
    padding: 4px 6px;
    color: #fff5d5 !important;
    background: #665212;
`;

export default InsightBox;
