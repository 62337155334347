import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Button, TextInput } from '@components';
import { useTranslation } from '@hooks';
import { MenuInfo } from '@api/common/menu';

interface Props {
    getFilterMenu: (menu: MenuInfo[]) => void;
    handleRefresh: () => void;
    handleSearch: (searchInput: string) => void;
}
const Search = ({ handleRefresh, handleSearch }: Props) => {
    const t = useTranslation('MenuManagement');
    const [searchInput, setSearchInput] = useState('');

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleSearch(searchInput);
    };

    const handleRefreshInput = () => {
        setSearchInput('');
        handleRefresh();
    };

    return (
        <div className="flx-row">
            <p className="pnt-txt txt-bold mr-4">{t('Search', 'Search')}</p>
            <form className={'flx-row'} onSubmit={handleSubmit}>
                <TextInput
                    type={'text'}
                    size={60}
                    placeholder={t('Please enter a menu name or list of screens')}
                    name={'name'}
                    value={searchInput}
                    handleChange={handleInputChange}
                />
                <Button className={'btn-darkgray btn-icon'} iconName="search" onClick={() => handleSearch(searchInput)}>
                    {t('Search', 'Search')}
                </Button>
            </form>
            <Button className="btn-lightgray btn-icon-only ml-2" iconName="refresh" onClick={handleRefreshInput} />
        </div>
    );
};

export default Search;
