import { createSlice } from '@reduxjs/toolkit';
import { InitialScreen } from './types';
import { createContext, Dispatch, useContext } from 'react';
import { ActionType } from '@components/ContextProvider';

export const initScreenInfo = { totalPage: 1, page: 1, rows: [], totalCount: 0 };

export const initialState: InitialScreen = {
    screenListInfo: initScreenInfo,
    selectedScreen: null,
    highlight: '',
};

export const ScreenListContext = createContext(initialState);
export const ScreenListDispatchContext = createContext<Dispatch<ActionType> | null>(null);

export const useScreenListStateContext = () => useContext(ScreenListContext);
export const useScreenListDispatchContext = () => useContext(ScreenListDispatchContext)!;

const screenListSlice = createSlice({
    name: 'screenList',
    initialState,
    reducers: {
        setScreenListInfo: (state, action) => {
            state.screenListInfo = action.payload;
        },
        selectScreen: (state, action) => {
            state.selectedScreen = action.payload;
        },
        setHighlight: (state, action) => {
            state.highlight = action.payload;
        },
    },
});

export const { setScreenListInfo, selectScreen, setHighlight } = screenListSlice.actions;
export default screenListSlice;
