import React, { useRef } from 'react';
import { Button, Icon } from '@components';
import { useAsync, useConfirmModal, useTranslation } from '@hooks';
import { fetchExcelLogApi } from '@api/common/log';
import { excelExport } from '../../../util/excelExport';
import { getTestTargetPatientAlertHistoryExcelApi } from '@api/sh/examPatientMonitoring';
import { NullableNumber } from '@util/type/util';

const INTERVAL_TIME = 1000;
const ExcelExport = ({ param }: { param: any }) => {
    const t = useTranslation('ExamPatientsEmergencyAlertHistory');
    const excelTimerRef = useRef<{ [key: string | number]: NullableNumber }>({});

    const { promise: createNewLogFile } = useAsync({
        promise: getTestTargetPatientAlertHistoryExcelApi,
        fixedParam: {
            ...param,
            isAll: 'Y',
            excelDownloadRequest: {
                zipFileName: t('Emergency_History'),
                fileName: t('Emergency_History'),
                columnMetas: [
                    {
                        key: 'subjectNum',
                        name: t('Study Number'),
                    },
                    {
                        key: 'deviceName',
                        name: t('Device Name'),
                    },
                    {
                        key: 'sensingType',
                        name: t('Vital Signs'),
                    },
                    {
                        key: 'currentValue',
                        name: t('Vital Signs Value'),
                    },
                    {
                        key: 'regDateStr',
                        name: t('Recognition Time'),
                        // isDateFormat: 'Y',
                    },
                ],
            },
        },
        resolve: res => {
            const { filePath, reqNum } = res;
            if (filePath) {
                tryExcelDown({ reqNum });
            }
        },
        reject: err => {
            toggleExcelConfirmModal();
            console.error(err);
        },
    });

    const { promise: checkExcelComplete } = useAsync({
        promise: fetchExcelLogApi,
        resolve: res => {
            const { dataSettingStatusType, reqNum, filePath, fileName } = res;
            switch (dataSettingStatusType) {
                case 'COMPLETED':
                    excelExport(filePath, fileName);
                    clearExcelTimer({ reqNum });
                    break;
                case 'FAILED':
                    toggleExcelDownloadResultModal();
                    clearExcelTimer({ reqNum });
                    break;
            }
        },
        reject: err => console.error(err),
    });

    const tryExcelDown = ({ reqNum }: { reqNum: number }) => {
        excelTimerRef.current[reqNum] = window.setInterval(() => {
            checkExcelComplete({ reqNum });
        }, INTERVAL_TIME);
    };

    const clearExcelTimer = ({ reqNum }: { reqNum: number }) => {
        clearInterval(excelTimerRef.current[reqNum]!);
        excelTimerRef.current[reqNum] = null;
        delete excelTimerRef.current[reqNum];
    };

    const handleExportFileClick = () => {
        createNewLogFile();
    };

    const { Modal: ExcelDownloadResultModal, toggleModal: toggleExcelDownloadResultModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Result', 'Report') },
        confirmText: t('Failed to download the excel.', 'Report'),
    });

    const { Modal: ExcelConfirmModal, toggleModal: toggleExcelConfirmModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        header: { title: t('Excel Download', 'Report') },
        confirmText: t('No data', 'Report'),
    });

    return (
        <>
            <Button className="btn-lightgray btn-icon" onClick={handleExportFileClick}>
                <Icon iconName={'file_upload'} />
                {t('Export', 'Button')}
            </Button>
            <ExcelDownloadResultModal />
            <ExcelConfirmModal />
        </>
    );
};

export default ExcelExport;
