import React from 'react';
import CountUp from 'react-countup';
import useTranslation from '@hooks/useTranslation';
import { useOutpatientBloodDrawStatusContext, useOutpatientBloodDrawStatusDispatch } from '../../index';
import { setSelectedOutpatientStatus } from '../../OutpatientBloodDrawStatusSlice';
import { Icon } from '@components';

export const VIEW_MODE = 'view';

const DATA_LABEL = {
    total: {
        value: null,
        label: 'All Inspections',
    },
    waitingInspect: { value: 'WT', label: 'Waiting Inspections' },
    nonArrival: { value: 'AP', label: 'Non-arrival' },
    doneInspect: { value: 'ED', label: 'Complete Inspections' },
    nonDoneInspect: { value: '', label: 'Incomplete Inspections' },
};

const ItemCount = ({ start = 0, end = 0, handleCountClick, label }) => {
    const t = useTranslation('OutpatientBloodDrawStatus');
    const { selectedOutpatientCareStatus } = useOutpatientBloodDrawStatusContext();
    const dispatch = useOutpatientBloodDrawStatusDispatch();

    const handleLabelClick = () => {
        dispatch(setSelectedOutpatientStatus(DATA_LABEL[label].value));
    };

    const selectedStyleClass = selectedOutpatientCareStatus === DATA_LABEL[label]?.value ? 'selected' : 'nonSelected';

    return (
        <div className={`flex-center col ${selectedStyleClass}`} onClick={handleLabelClick}>
            <CountUp
                className="item-num"
                decimals={0}
                start={start}
                end={end}
                decimal=","
                duration="1"
                useEasing={false}
                separator=""
                prefix=""
                suffix=""
            />
            <div className={'flx-row'}>
                {t(DATA_LABEL[label].label)}
                {selectedOutpatientCareStatus === DATA_LABEL[label].value ? (
                    <Icon iconName={'arrow_drop_down'} />
                ) : (
                    <Icon iconName={'arrow_right'} />
                )}
            </div>
        </div>
    );
};

export default ItemCount;
