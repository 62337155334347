import { BarChart } from '@components';
import InnerItemCard from '@components/Card/InnerItemCard';
import { useAppSelector, useTranslation } from '@hooks';
import React, { useMemo } from 'react';
import { OUTPATIENT_REPORT } from '../../constants';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { fontColorByTheme } from '../util';
import { customLegendPlugin, dayFloatingLabels, hoverBackgroundPlugin } from '../util/chartPlugin';

const Chart = ({ data, isSaturday }) => {
    const t = useTranslation(OUTPATIENT_REPORT);
    const { colorScheme } = useAppSelector(state => state.ThemeOptions);

    const stackedBarData = useMemo(() => {
        const labelArray = [t('Mon'), t('Tue'), t('Wed'), t('Thu'), t('Fri'), t('Sat')];

        return {
            labels: isSaturday ? labelArray : labelArray.slice(0, -1),
            datasets: data.map(({ departmentName, kioskCount }) => ({
                label: departmentName,
                data: isSaturday ? kioskCount : kioskCount.slice(0, -1),
                datalabels: {
                    // 데이터의 길이가 1개일때만 datalabes 표시
                    display: data.length === 1,
                    anchor: 'end',
                    align: 'top',
                    color: fontColorByTheme(colorScheme),
                    formatter: function (value) {
                        return `${value} ${t('Cases')}`; // 표시할 값
                    },
                },
            })),
        };
    }, [data, t, colorScheme, isSaturday]);

    return (
        <InnerItemCard
            className={'m-2 h-90'}
            bodyClassName={'h-100'}
            header={{ title: t('Kiosk Usage by Day of the Week') }}
        >
            <div className={'h-90 flx-row position-relative'}>
                <div
                    id="custom-legend-container"
                    className={'d-flex gap-2 position-absolute'}
                    style={{
                        top: 0,
                        right: 0,
                    }}
                />
                <BarChart
                    type="stackedBar"
                    data={stackedBarData}
                    plugins={[ChartDataLabels, customLegendPlugin, hoverBackgroundPlugin, dayFloatingLabels(t)]}
                    options={{
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 60,
                            },
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                intersect: false,
                                mode: 'index',
                                callbacks: {
                                    label: function (context) {
                                        const label = context.dataset.label || ''; // 데이터셋 레이블
                                        const value = context.raw; // 데이터 값
                                        return `${label}: ${value} ${t('Cases')}`; // "건" 추가
                                    },
                                },
                            },
                        },
                        scales: {
                            x: {
                                title: {
                                    display: true,
                                    text: t('Kiosk Usage Day'),
                                    font: {
                                        size: 16,
                                        family: 'pretendard',
                                    },
                                    padding: { top: 20 },
                                },
                            },
                            y: {
                                grace: '20%',
                                beginAtZero: true,
                                // 합산일경우
                                suggestedMax: data.length === 1 ? Math.max(...data[0].kioskCount) + 10 : 10,
                                title: {
                                    display: true,
                                    text: t(t('Kiosk Usage (Cases)')),
                                    font: {
                                        size: 15,
                                        family: 'pretendard',
                                    },
                                },
                            },
                        },
                    }}
                />
            </div>
        </InnerItemCard>
    );
};

export default Chart;
