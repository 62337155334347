import { LineChart } from '@components';
import InnerItemCard from '@components/Card/InnerItemCard';
import { useAppSelector, useTranslation } from '@hooks';
import React, { useMemo } from 'react';
import { OUTPATIENT_REPORT, TIME_LABELS, TIME_LABELS_DATA } from '../../constants';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { customLegendPlugin, hoverBackgroundPlugin, timeFloatingLabels } from '../util/chartPlugin';
import { fontColorByTheme } from '../util';

// type = 0 or 1 (개별 or 합산)
const Chart = ({ data }) => {
    const t = useTranslation(OUTPATIENT_REPORT);
    const { colorScheme } = useAppSelector(state => state.ThemeOptions);

    const lineData = useMemo(
        () => ({
            labels: TIME_LABELS,
            datasets: data.map(({ departmentName, kioskCount }) => ({
                label: departmentName,
                data: TIME_LABELS_DATA.map((item, index) => ({
                    ...item,
                    y: kioskCount[index],
                })),

                datalabels: {
                    // 데이터의 길이가 1개일때만 datalabes 표시
                    display: data.length === 1,
                    anchor: 'end',
                    align: 'top',
                    color: fontColorByTheme(colorScheme),
                    formatter: function (value) {
                        // 개별일땐 키오스크 사용률까지
                        return `${value.y} ${t('Cases')}`; // 표시할 값
                    },
                },
                tension: 0.4,
            })),
        }),
        [data, t, colorScheme],
    );

    return (
        <InnerItemCard className={'m-2 h-90'} bodyClassName={'h-100'} header={{ title: t('Kiosk Usage by Time Slot') }}>
            <div className={'h-90 flx-row position-relative'}>
                <div
                    id="custom-legend-container"
                    className={'d-flex gap-2 position-absolute'}
                    style={{
                        top: 0,
                        right: 0,
                    }}
                />
                <LineChart
                    plugins={[ChartDataLabels, hoverBackgroundPlugin, customLegendPlugin, timeFloatingLabels(t)]}
                    data={lineData}
                    options={{
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 60,
                            },
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                enabled: true, // 툴팁 활성화
                                intersect: false,
                                mode: 'index', // 가장 가까운 데이터 포인트에 반응하도록 설정
                                callbacks: {
                                    title: function (tooltipItem, i, d) {
                                        const { raw, dataIndex } = tooltipItem[0];
                                        const rangeArray = raw?.range?.split('-') ?? [];
                                        if (dataIndex === 0 && rangeArray.length) return `~${rangeArray[1]}`;
                                        if (dataIndex === TIME_LABELS.length - 2 && rangeArray.length)
                                            return `${rangeArray[0]}~`;
                                        return raw?.range;
                                    },
                                    // 툴팁에 표시할 내용을 설정
                                    label: function (tooltipItem) {
                                        const { y } = tooltipItem.raw; // 해당 축 데이터
                                        const { label } = tooltipItem.dataset;

                                        return `${label}: ${y} ${t('Cases')}`; // 툴팁에 표시될 내용
                                    },
                                },
                            },
                        },
                        scales: {
                            x: {
                                type: 'time',
                                grid: {
                                    display: true,
                                },
                                time: {
                                    unit: 'minute',
                                    stepSize: 30,
                                    displayFormats: {
                                        minute: 'HH:mm',
                                    },
                                },
                                title: {
                                    display: true,
                                    text: t('Kiosk Usage Time'),
                                    font: {
                                        size: 16,
                                        family: 'pretendard',
                                    },
                                    padding: { top: 20 },
                                },
                                ticks: {
                                    callback: function (value, index, ticks) {
                                        if (index === 0 || index === TIME_LABELS.length - 1) {
                                            return '';
                                        }
                                        if (index === 1) {
                                            return `~${value}`;
                                        }
                                        if (index === TIME_LABELS.length - 2) {
                                            return `${value}~`;
                                        }
                                        return value;
                                    },
                                },
                            },
                            y: {
                                grace: '20%',
                                beginAtZero: true,
                                grid: {
                                    display: false,
                                },
                                suggestedMax: data.length === 1 ? Math.max(...data[0].kioskCount) + 10 : 10,
                                title: {
                                    display: true,
                                    text: t(t('Kiosk Usage (Cases)')),
                                    font: {
                                        size: 15,
                                        family: 'pretendard',
                                    },
                                },
                            },
                        },
                    }}
                />
            </div>
        </InnerItemCard>
    );
};

export default Chart;
