import React from 'react';
import { ElementProps } from '@components/type';
import cx from 'classnames';
import { MaterialIcon } from '@material-design-icons/font';

export const DEFAULT_ICON_SIZE = 'md-24';
export const DEFAULT_ICON_TYPE = 'material-icons-round';

export type IconName = MaterialIcon | string;

// 아이콘 사이즈 종류
// 'md-18' | 'md-20' | 'md-22' | 'md-24' | 'md-30' | 'md-36' | 'md-48';

// outlined 사용 시 커스텀 스타일링 필요.
type IconType = 'material-icons-round' | 'material-icons-outlined';

export interface IconProps extends ElementProps {
    iconName?: IconName;
    iconType?: IconType;
    className?: string;
}

const Icon = ({ iconName, iconType = DEFAULT_ICON_TYPE, className = DEFAULT_ICON_SIZE, ...props }: IconProps) => {
    return (
        <>
            {iconName && (
                <span className={cx(iconType, className)} {...props} translate="no">
                    {iconName}
                </span>
            )}
        </>
    );
};

export default Icon;
