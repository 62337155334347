import React from 'react';
import CountUp from 'react-countup';
import useTranslation from '@hooks/useTranslation';
import { useOutpatientBaselineTestingDispatch, useOutpatientBaselineTestingState } from '../../../index';
import { setSelectedOutpatientStatus } from '../../../OutpatientBaselineTestingStatusSlice';
import { Icon } from '@components';

const DATA_LABEL = {
    total: {
        value: null,
        label: 'All Inspections',
    },
    doneInspect: { value: 'doneInspect', label: 'Complete Inspections' },
    nonDoneInspect: { value: 'nonDoneInspect', label: 'UnInspected' },
    safePatient: { value: 'safePatient', label: 'Safety Patients' },
    dangerPatient: { value: 'dangerPatient', label: 'Danger Patients' },
};

const ItemCount = ({ start = 0, end = 0, label }) => {
    const t = useTranslation('OutpatientBaselineTesting');
    const { selectedOutpatientBasicTestStatus } = useOutpatientBaselineTestingState();
    const dispatch = useOutpatientBaselineTestingDispatch();

    const handleLabelClick = () => {
        dispatch(setSelectedOutpatientStatus(DATA_LABEL[label].value));
    };

    const selectedStyleClass =
        selectedOutpatientBasicTestStatus === DATA_LABEL[label].value ? 'selected' : 'nonSelected';

    return (
        <div className={`flex-center col ${selectedStyleClass}`} onClick={handleLabelClick}>
            <CountUp
                className="item-num"
                decimals={0}
                start={start}
                end={end}
                decimal=","
                duration="1"
                useEasing={false}
                separator=""
                prefix=""
                suffix=""
            />
            <div className={'flx-row'}>
                {t(DATA_LABEL[label].label)}
                {selectedOutpatientBasicTestStatus === DATA_LABEL[label].value ? (
                    <Icon iconName={'arrow_drop_down'} />
                ) : (
                    <Icon iconName={'arrow_right'} />
                )}
            </div>
        </div>
    );
};

export default ItemCount;
