import React from 'react';
import CountUp from 'react-countup';
import { useTranslation } from '@hooks';
import { useOutpatientUnattendedReceptionContext, useOutpatientUnattendedReceptionDispatch } from '../../index';
import { setSelectedOutpatientStatus } from '../../OutpatientUnattendedReceptionStatusSlice';
import { Icon } from '@components';

const DATA_LABEL = {
    total: {
        value: null,
        label: 'All Reception',
    },
    todayReception: { value: 'todayReception', label: 'Walk-ins' },
    receptionSuccess: { value: 'receptionSuccess', label: 'Reception Success' },
    receptionFail: { value: 'receptionFail', label: 'Reception Error' },
};

const ItemCount = ({ start = 0, end = 0, label }) => {
    const t = useTranslation('OutpatientUnattendedReceptionStatus');
    const { selectedOutpatientStatus } = useOutpatientUnattendedReceptionContext();
    const dispatch = useOutpatientUnattendedReceptionDispatch();

    const handleLabelClick = () => {
        dispatch(setSelectedOutpatientStatus(DATA_LABEL[label].value));
    };

    const selectedStyleClass = selectedOutpatientStatus === DATA_LABEL[label].value ? 'selected' : 'nonSelected';

    return (
        <div className={`flex-center col ${selectedStyleClass}`} onClick={handleLabelClick}>
            <CountUp
                className="item-num"
                decimals={0}
                start={start}
                end={end}
                decimal=","
                duration="1"
                useEasing={false}
                separator=""
                prefix=""
                suffix=""
            />
            <div className={'flx-row'}>
                {t(DATA_LABEL[label].label)}
                {selectedOutpatientStatus === DATA_LABEL[label].value ? (
                    <Icon iconName={'arrow_drop_down'} />
                ) : (
                    <Icon iconName={'arrow_right'} />
                )}
            </div>
        </div>
    );
};

export default ItemCount;
