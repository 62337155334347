import React from 'react';
import CountUp from 'react-countup';
import useTranslation from '@hooks/useTranslation';
import { useOutpatientProofContext, useOutpatientProofDispatch } from '../../../index';
import { setSelectedOutpatientStatus } from '../../../OutpatientProofIssuanceStatusSlice';
import { Icon } from '@components';

const DATA_LABEL = {
    total: {
        value: null,
        label: 'All Issues',
    },
    medicalBill: { value: 'medicalBill', label: 'Medical bills' },
    medicalBillDetail: { value: 'medicalBillDetail', label: 'Medical bill details' },
    medicalPaymentCertificate: { value: 'medicalPaymentCertificate', label: 'Confirm payment' },
    medicalYearEndSettlement: { value: 'medicalYearEndSettlement', label: 'Year-End Care' },
    dischargeCertificate: { value: 'dischargeCertificate', label: 'Confirm admissions' },
    publishFail: { value: 'publishFail', label: 'Failed to publish' },
};
const ItemCount = ({ start = 0, end = 0, label }) => {
    const t = useTranslation('OutpatientProofIssuance');
    const { selectedOutpatientStatus } = useOutpatientProofContext();
    const dispatch = useOutpatientProofDispatch();

    const handleLabelClick = () => {
        dispatch(setSelectedOutpatientStatus(DATA_LABEL[label].value));
    };

    const selectedStyleClass = selectedOutpatientStatus === DATA_LABEL[label].value ? 'selected' : 'nonSelected';

    return (
        <div className={`flex-center col ${selectedStyleClass}`} onClick={handleLabelClick}>
            <CountUp
                className="item-num"
                decimals={0}
                start={start}
                end={end}
                decimal=","
                duration="1"
                useEasing={false}
                separator=""
                prefix=""
                suffix=""
            />
            <div className={'flx-row'}>
                {t(DATA_LABEL[label].label)}
                {selectedOutpatientStatus === DATA_LABEL[label].value ? (
                    <Icon iconName={'arrow_drop_down'} />
                ) : (
                    <Icon iconName={'arrow_right'} />
                )}
            </div>
        </div>
    );
};

export default ItemCount;
