import React, { useEffect, useState } from 'react';

import Sheet from './Components/Sheet/Sheet';
import Graph from './Components/Graph/Graph';
import Search from './Components/Graph/Components/Search';
import RangeSetting from './Components/RangeSetting/RangeSetting';

import { getRefrigeratorSensorDetailApi } from '@api/monitoring';
import useAsync from '@hooks/useAsync';
import { useRefrigeratorSensorContext } from '../../index';

import moment from 'moment';
import useTranslation from '@hooks/useTranslation';

export const SENSING_TYPE_TEMPERATURE = 'TEMPERATURE';
export const SENSING_TYPE_HUMIDITY = 'HUMIDITY';

const ViewDetail = ({ handleRefresh }) => {
    const t = useTranslation('RefrigeratorSensor');
    const { sensorNum } = useRefrigeratorSensorContext();
    const [sensorDetail, setSensorDetail] = useState({
        sensorName: '',
        sensorId: '',
        wardInfo: '',
        lastDate: '',
        sensorItems: [],
    });
    const { promise: getRefrigeratorSensorDetail } = useAsync({
        promise: getRefrigeratorSensorDetailApi,
        resolve: res => setSensorDetail(res),
        reject: err => console.error(err),
    });

    useEffect(() => {
        if (sensorNum) {
            getRefrigeratorSensorDetail({ sensorNum });
        }
    }, [sensorNum]);

    const [date, setDate] = useState(moment().unix());
    const handleDate = selected => {
        setDate(selected);
    };

    return (
        <>
            <Search date={date} onChange={handleDate} />
            <div className="d-flex mb-4 h-50">
                <div className="w-30 d-flex flex-column">
                    <h5 className="pnt-txt txt-bold mb-2">{t('Refrigerator Sensor detail')}</h5>
                    <div className="flx-1">
                        <Sheet data={sensorDetail} />
                    </div>
                </div>
                <div className="w-70 d-flex flex-column ml-3">
                    <h5 className="pnt-txt txt-bold mb-2">{t('Temperature Graph')}</h5>
                    <div className="flx-1 mb-1">
                        <Graph
                            modelCode={sensorDetail?.modelCode}
                            targetNum={sensorDetail?.targetNum}
                            date={date}
                            type={SENSING_TYPE_TEMPERATURE}
                        />
                    </div>
                    <h5 className="pnt-txt txt-bold mb-2">{t('Humidity Graph')}</h5>
                    <div className="flx-1">
                        <Graph
                            modelCode={sensorDetail?.modelCode}
                            targetNum={sensorDetail?.targetNum}
                            date={date}
                            type={SENSING_TYPE_HUMIDITY}
                        />
                    </div>
                </div>
            </div>
            <div>
                <h5 className="pnt-txt txt-bold mb-2">{t('Range Setting')}</h5>
                <RangeSetting data={sensorDetail} promise={getRefrigeratorSensorDetail} handleRefresh={handleRefresh} />
            </div>
        </>
    );
};

export default ViewDetail;
