import { SENSOR_TYPE, SensorType } from '@util/staticData/sensorMeta';
import { SensorRangeKey } from './DeviceInfoModal';

export const SENSOR = 'SENSOR';
export const SCANNER = 'SCANNER';

export const RANGE_ORDER: SensorRangeKey[] = [
    'minRangeLimit', // 경고 최소값 (범위 최저값)
    'minRiskLimit', // 주의 최소값 (위험 최저값)
    'minControlLimit', // 정상 최소값 (정상 최저값)
    'maxControlLimit', // 정상 최대값 (정상 최대값)
    'maxRiskLimit', // 주의 최대값 (위험 최대값)
    'maxRangeLimit', // 경고 최대값 (범위 최대값)
];

export const RANGE_KEY_TO_LABEL: Record<SensorRangeKey, string> = {
    minRangeLimit: 'Min Range Limit', // 경고 최소값 (범위 최저값)
    minRiskLimit: 'Min Risk Limit', // 주의 최소값 (위험 최저값)
    minControlLimit: 'Min Control Limit', // 정상 최소값 (정상 최저값)
    maxControlLimit: 'Max Control Limit', // 정상 최대값 (정상 최대값)
    maxRiskLimit: 'Max Risk Limit', // 주의 최대값 (위험 최대값)
    maxRangeLimit: 'Max Range Limit', // 경고 최대값 (범위 최대값)
};

// 7 = th + 센서 범위 td 갯수
const INPUT_BORDER = 2;
export const CELL_HEIGHT = 30;
const HEADER_HEIGHT = 43; // 세줄(최대)
export const SENSOR_VALUE_TABLE_HEIGHT = {
    height: CELL_HEIGHT * 6 + INPUT_BORDER + HEADER_HEIGHT,
};

export const SENSOR_TYPE_ORDER = [
    SENSOR_TYPE.HEARTBEAT,
    SENSOR_TYPE.RESPIRATION_RATE,
    SENSOR_TYPE.RESPIRATION_RATE2,
    SENSOR_TYPE.TEMPERATURE,
] as const;
type UsageSensorType = typeof SENSOR_TYPE_ORDER[number];

export const CUSTOM_SENSOR_TYPE_TO_NAME: { [key in SensorType]?: string } = {
    [SENSOR_TYPE.TEMPERATURE]: 'Body Temperature',
    [SENSOR_TYPE.RESPIRATION_RATE]: 'Respiration Rate 0 Condition',
    [SENSOR_TYPE.RESPIRATION_RATE2]: 'Respiration Rate 1 Condition',
};

export const defaultSensorRange = {
    minRangeLimit: {
        HEARTBEAT: 0,
        RESPIRATION_RATE: 0,
        RESPIRATION_RATE2: 0,
        TEMPERATURE: 0,
    },
    minRiskLimit: {
        HEARTBEAT: 0,
        RESPIRATION_RATE: 0,
        RESPIRATION_RATE2: 0,
        TEMPERATURE: 0,
    },
    minControlLimit: {
        HEARTBEAT: 0,
        RESPIRATION_RATE: 0,
        RESPIRATION_RATE2: 0,
        TEMPERATURE: 0,
    },
    maxControlLimit: {
        HEARTBEAT: 0,
        RESPIRATION_RATE: 0,
        RESPIRATION_RATE2: 0,
        TEMPERATURE: 0,
    },
    maxRiskLimit: {
        HEARTBEAT: 0,
        RESPIRATION_RATE: 0,
        RESPIRATION_RATE2: 0,
        TEMPERATURE: 0,
    },
    maxRangeLimit: {
        HEARTBEAT: 0,
        RESPIRATION_RATE: 0,
        RESPIRATION_RATE2: 0,
        TEMPERATURE: 0,
    },
};
