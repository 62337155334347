import { DefaultApiResponse, RequestHandlerCreator } from '@api/util';
import { Nullable, NullableNumber, NullableString, PageInfo, YN } from '@util/type/util';
import { reqDelete, reqGet, reqPost } from '@api/index';

export type PatientAnomalyAlertSetting<AlertSettingGroupNum = unknown> = {
    alertSettingGroupNum: AlertSettingGroupNum extends number ? number : NullableNumber;
    comNum: NullableNumber;
    groupNum: number;
    alertSettingNum: NullableNumber;
    alertSettingName: NullableString;
    alertActive: Nullable<YN>;
    alertOpenTime: AlertSettingGroupNum extends number ? number : NullableNumber;
    alertOpenCount: AlertSettingGroupNum extends number ? number : NullableNumber;
    alertCloseCount: AlertSettingGroupNum extends number ? number : NullableNumber;
    alertSnoozeActive: Nullable<YN>;
    alertSnoozeTime: NullableNumber;
    alertRestrictionActive: Nullable<YN>;
    alertRestrictionZones: AlertRestrictionZone[];
    alertSoundUse: Nullable<YN>;
    alertSoundVolume: NullableNumber;
    alertSoundInfo: AnomalyAlertSound;
};

export type PatientAnomalyAlertSettingParam = {
    groupNum: number;
    alertSettingNum: null;
    alertSettingName: null;
    alertActive: YN;
    alertOpenTime: number;
    alertOpenCount: number;
    alertCloseCount: number;
    alertSnoozeActive: YN;
    alertSnoozeTime: number;
    alertRestrictionActive: YN;
    alertRestrictionZones: AlertRestrictionZone[];
    alertSoundUse: YN;
    alertSoundVolume: number;
    alertSound: {
        uri: string;
        name: string;
    };
};

export type AnomalyAlertSound = {
    baseUrl: string;
    alertSounds: {
        uri: string;
        name: string;
        selected: YN;
    }[];
};

type AlertRestrictionZone = {
    fcGroupNum: number;
    fcGroupName: string;
    isRestrict: YN;
};

export type AnomalyAlertGroupSettings = {
    comNum: number;
    groupNum: number;
    groupName: string;
    groupSelected: YN;
    alertSettingGroupCount: number;
    alertSettingActiveCount: number;
};

export type AlertSoundInfo = {
    alertSoundBaseUrl: string;
    alertSounds: { uri: string; name: string }[];
};

export const getPatientAnomalyAlertSettingApi: RequestHandlerCreator<
    PatientAnomalyAlertSetting,
    { alertSettingGroupNum: number }
> = param => reqGet(`/v3/api/sh01/realtime/emergency/alert/setting/${param.alertSettingGroupNum}`, { ...param });

export const getPatientAnomalyAlertSettingListApi: RequestHandlerCreator<
    PageInfo<PatientAnomalyAlertSetting>,
    { groupNum: number; pageSize: number; page?: number }
> = param => reqGet('/v3/api/sh01/realtime/emergency/alert/setting/list', { ...param });

export const getAnomalyAlertGroupSettingsListApi: RequestHandlerCreator<PageInfo<AnomalyAlertGroupSettings>> = param =>
    reqGet('/v3/api/sh01/realtime/emergency/alert/setting/groups', { isAll: 'Y', ...param });

export const updatePatientAnomalyAlertSettingApi: RequestHandlerCreator<
    PatientAnomalyAlertSetting,
    PatientAnomalyAlertSettingParam
> = param => reqPost('/v3/api/sh01/realtime/emergency/alert/setting', param);

export const deleteAnomalyAlertSettingApi: RequestHandlerCreator<
    DefaultApiResponse,
    { alertSettingGroupNum: number }
> = param => reqDelete(`/v3/api/sh01/realtime/emergency/alert/setting/${param.alertSettingGroupNum}`);

export const getAnomalyAlertSoundApi: RequestHandlerCreator<AlertSoundInfo> = param =>
    reqGet('/v3/api/sh01/realtime/emergency/alert/setting/sound', param);
