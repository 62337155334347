import React from 'react';
import cx from 'classnames';
import { Icon } from '@components';
import { NameplateProps } from '@components/type';

const Nameplate = ({ name = '', className, iconName, iconClassName }: NameplateProps) => {
    return (
        <div className={cx('pnt-nameplate', className)}>
            <Icon iconName={iconName} className={iconClassName} />
            <div className="cont-wrap">{name}</div>
        </div>
    );
};

export default Nameplate;
