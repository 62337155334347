import React, { useEffect, useMemo, useRef, useState } from 'react';
import Control from 'react-leaflet-control';
import { LayersControl } from 'react-leaflet';
import { useSelector } from 'react-redux';

import { useTranslation } from '@hooks';
import SingleTreeSelect from '@components/Select/SingleTreeSelect';
import { Button, GeofenceLayer, Icon, Select } from '@components';

import { useMassRealTimeLocationStatusContext, useMassRealTimeLocationStatusDispatch } from '../index';
import { setFloorInfo, setPopup, setSelectedBuilding, setSelectedFloor } from '../massRealTimeLocationSlice';
import FloorDetailPopup from './FloorDetailPopup';

const AllTarget = ({ widgetRef, mapRef }) => {
    const t = useTranslation('RealTimeLocationStatus');
    const { floorList } = useSelector(state => state.FloorInfo);

    const {
        selectedFloor,
        selectedBuilding,
        geofenceInfo,
        popup,
        realTimeLog,
    } = useMassRealTimeLocationStatusContext();
    const dispatch = useMassRealTimeLocationStatusDispatch();

    const handleFloorChange = selects => {
        if (selects) {
            dispatch(setSelectedFloor(selects.floorId));
            dispatch(setFloorInfo(selects));
        }
    };

    const handleBuildingChange = selected => {
        dispatch(setSelectedBuilding(selected.floorId));
        dispatch(setSelectedFloor(selected.floorId));
        dispatch(setFloorInfo(selected));
    };

    const handlePopupClick = (name, value) => {
        dispatch(setPopup({ [name]: !value }));
    };

    const handleModalToggle = value => {
        dispatch(setPopup({ [value]: !popup[value] }));
    };

    const buildingFloorList = useMemo(() => {
        const buildingList = floorList.filter(floor => floor.levelNo === 0);
        const selectFloorList = [];
        const floorItemList = floorList.filter(floor => floor.levelNo !== 0);
        if (selectedBuilding) {
            floorItemList.forEach(e => {
                const path = e.floorIdPath.split('>');
                if (path && path[0] === selectedBuilding) {
                    const valuePath = path.filter((_, i) => i !== 0);
                    selectFloorList.push({
                        ...e,
                        floorIdPath:
                            valuePath.length > 1 ? valuePath.reduce((acc, cur) => acc + '>' + cur) : valuePath.join(''),
                        levelNo: e.levelNo - 1,
                        upperFloorId: e.upperFloorId === selectedBuilding ? null : e.upperFloorId,
                    });
                }
            });
        }
        const selectBuilding = floorList.find(e => e.floorId === selectedBuilding);
        return { buildingList, floorList, selectFloorList, selectBuilding };
    }, [floorList, selectedBuilding, selectedFloor]);

    return (
        <>
            <Control position="topleft" className={'control-container'}>
                <Button
                    className={'leaflet-map-btn btn-icon'}
                    iconName="info_outline"
                    onClick={() => handlePopupClick('floorDetailPopup', popup.floorDetailPopup)}
                >
                    {t('Detail')}
                </Button>

                <FloorSelectOnMapControl
                    options={buildingFloorList.buildingList}
                    value={buildingFloorList.selectBuilding || null}
                    onChange={handleBuildingChange}
                />

                <SingleTreeSelect
                    data={buildingFloorList.selectFloorList}
                    title={'Category'}
                    valueKey={'floorId'}
                    labelKey={'floorName'}
                    parentKey={'upperFloorId'}
                    selected={selectedFloor}
                    onChange={handleFloorChange}
                    isMap
                />
                {/*<FloorSelect selectedFloorId={selectedFloor} handleChange={handleFloorChange} />*/}
            </Control>

            <Control position="bottomleft" className="control-container">
                <div className="pnt-nameplate leaflet-map-nameplate mw-100">
                    <Icon iconName={'info_outline'} className={'md-18'} />
                    <div>{`${t('Total Number of Items Detected')} : ${realTimeLog.count}`}</div>
                </div>
            </Control>

            <LayersControl position={'topright'}>
                <LayersControl.Overlay checked name={t('Geofence')}>
                    <GeofenceLayer geofenceList={geofenceInfo} />
                </LayersControl.Overlay>
            </LayersControl>

            <FloorDetailPopup
                modal={popup.floorDetailPopup}
                toggleModal={() => handleModalToggle('floorDetailPopup')}
                widgetRef={widgetRef}
                mapRef={mapRef}
            />
            {/*<FilteringPopup*/}
            {/*    modal={popup.filteringPopup}*/}
            {/*    toggleModal={() => handleModalToggle('filteringPopup')}*/}
            {/*    widgetRef={widgetRef}*/}
            {/*    mapRef={mapRef}*/}
            {/*/>*/}
        </>
    );
};

const FloorSelectOnMapControl = ({ options, value, onChange }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const wrapperRef = useRef();
    const toggleMenuOpen = () => {
        setMenuOpen(!menuOpen);
    };

    useEffect(() => {
        const onClick = e => {
            if (wrapperRef.current) {
                const parent = wrapperRef.current;
                if (!parent.contains(e.target)) {
                    setMenuOpen(false);
                }
            }
        };
        document.addEventListener('click', onClick);
        return () => {
            document.removeEventListener('click', onClick);
        };
    }, []);

    return (
        <div ref={wrapperRef} onClick={toggleMenuOpen}>
            <Select
                className={'react-select pnt-select--group leaflet-map-select map-select-item'}
                menuIsOpen={menuOpen}
                options={options}
                labelKey={'floorName'}
                valueKey={'floorId'}
                value={value}
                onChange={selected => {
                    if (typeof onChange === 'function') {
                        onChange(selected);
                    }
                }}
            />
        </div>
    );
};

export default AllTarget;
