import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, Label, TextInput } from '@components';
import useTranslation from '@hooks/useTranslation';
import { useAppSelector, useConfirmModal } from '@hooks';
import { Col } from 'reactstrap';
import styled from 'styled-components';
import { menuIcons } from '../menuIcons';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import useAsync from '@hooks/useAsync';
import { createMenuApi, fetchMenuList, updateMenuApi } from '@api/common/menu';
import { INDOORPLUS_ERRORS } from '@util/errorCode';
import { setMenuList } from '@reducer/CustomMenu';

const MAX_ORDER_NUM = 999;

const initialMenuInfo = { order: 0 };

const MenuDetail = () => {
    const t = useTranslation('MenuManagement');
    const buttonT = useTranslation('Button');
    const history = useHistory();
    const dispatch = useDispatch();
    const { menuCode } = useParams();
    const originMenu = useSelector(state => state.CustomMenu?.originMenuList?.find(menu => menu.menuCode === menuCode));
    const { userInfo } = useAppSelector(state => state.UserInfo);
    const [menuInfo, setMenuInfo] = useState({
        ...initialMenuInfo,
        ...originMenu,
        icon: originMenu?.icon ?? menuIcons[0],
    });
    const [validationMsg, setValidationMsg] = useState([]);

    const { promise: getMenuList } = useAsync({
        promise: fetchMenuList,
        fixedParam: { isAll: 'Y', includeScreenInfo: 'Y', groupNum: userInfo.authGroupNum, roleNum: userInfo.roleNum },
        resolve: res => {
            dispatch(setMenuList(res.rows ?? []));
        },
        reject: err => {
            console.log({ err });
        },
    });

    const { Modal: SaveConfirmModal, toggleModal: toggleSaveConfirmModal } = useConfirmModal({
        confirmText: menuCode ? t('Do you want to save information?') : t('Would you like to create a menu?'),
        okCallback: () => {
            const pathRegExp = /^[a-z|A-Z|0-9]+$/gim;
            const validationList = [];
            if (!menuInfo.menuCode?.trim()) {
                validationList.push(t('Menu code is required.'));
            }
            if (menuInfo.menuCode?.trim() && !pathRegExp.test(menuInfo.menuCode.trim())) {
                validationList.push(t('Menu code can only contain English upper/lower case letters and numbers.'));
            }
            if (!menuInfo.menuName?.trim()) {
                validationList.push(t('Menu name is required.'));
            }
            if (validationList.length) {
                setValidationMsg(validationList);
                toggleValidationModal();
            } else {
                const { menuCode, menuName, icon, order, description } = menuInfo;
                saveMenuInfo({ menuCode: menuCode.trim(), menuName: menuName.trim(), icon, order, description });
            }
        },
    });

    const { Modal: ValidationModal, toggleModal: toggleValidationModal } = useConfirmModal({
        header: { title: t('Validation Fail') },
        confirmText: (
            <>
                {validationMsg.map((msg, i) => {
                    return (
                        <li key={`validation_${i}`}>
                            <span className="pnt-label--group">
                                <div
                                    className="label-main label-dot w-100"
                                    style={{ fontWeight: 'bold', fontSize: '0.9rem' }}
                                >
                                    {msg}
                                </div>
                            </span>
                        </li>
                    );
                })}
            </>
        ),
    });

    const { Modal: SaveSuccessModal, toggleModal: toggleSaveSuccessModal } = useConfirmModal({
        confirmText: menuCode ? t('Menu is changed successfully.') : t('Menu is created successfully.'),
        okCallback: () => {
            history.goBack();
        },
        removeCancel: true,
    });

    const [saveErrMsg, setSaveErrMsg] = useState(t('The request failed.', 'ErrorHandler'));

    const { Modal: SaveFailureModal, toggleModal: toggleSaveFailureModal } = useConfirmModal({
        confirmText: saveErrMsg,
    });

    const { promise: saveMenuInfo } = useAsync({
        promise: menuCode ? updateMenuApi : createMenuApi,
        resolve: () => {
            getMenuList();
            toggleSaveSuccessModal();
        },
        reject: ({ data }) => {
            if (data && data.code === INDOORPLUS_ERRORS.EXISTED_RESOURCE && data.errorResponse.includes('menuCode')) {
                setSaveErrMsg(t('The same menu code exists. Please change the menu code.'));
            } else {
                setSaveErrMsg(t('The request failed.', 'ErrorHandler'));
            }
            toggleSaveFailureModal();
        },
    });

    return (
        <>
            <Card
                className="h-100 mb-0"
                header={{
                    title: t('Menu Detail'),
                    iconName: menuCode ? 'info' : 'edit',
                    action: (
                        <>
                            <Button
                                className="btn-gray"
                                onClick={() => {
                                    history.goBack();
                                }}
                            >
                                {buttonT('Cancel')}
                            </Button>
                            <Button className={'btn-icon btn-brand'} iconName={'save'} onClick={toggleSaveConfirmModal}>
                                {buttonT('Save')}
                            </Button>
                        </>
                    ),
                }}
                bodyClassName={'overflow-visible'}
            >
                <Col className={'d-flex mb-3 pnt-label--group p-0'} xl={5}>
                    <Label
                        labelGroupClassName={'w-100'}
                        name={t('Menu Code')}
                        labelValueClassName={'label-dot w-30'}
                        value={
                            <TextInput
                                disabled={!!menuCode}
                                inputGroupClassName={'w-100 form-must'}
                                name={'menuCode'}
                                value={menuInfo.menuCode || ''}
                                placeholder={t('Please enter a menu code')}
                                handleChange={e => setMenuInfo({ ...menuInfo, menuCode: e.currentTarget.value })}
                            />
                        }
                    />
                </Col>
                <Col className={'d-flex mb-3 pnt-label--group p-0'} xl={5}>
                    <Label
                        labelGroupClassName={'w-100'}
                        name={t('Menu Name')}
                        labelValueClassName={'label-dot w-30'}
                        value={
                            <TextInput
                                inputGroupClassName={'w-100 form-must'}
                                name={'menuName'}
                                value={menuInfo.menuName || ''}
                                placeholder={t('Please enter a name')}
                                handleChange={e => setMenuInfo({ ...menuInfo, menuName: e.currentTarget.value })}
                            />
                        }
                    />
                </Col>
                <Col className={'d-flex mb-3 pnt-label--group p-0'} xl={5}>
                    <Label
                        labelGroupClassName={'w-100'}
                        name={t('Menu Description')}
                        labelValueClassName={'label-dot w-30'}
                        value={
                            <TextInput
                                inputGroupClassName={'w-100'}
                                name={'description'}
                                value={menuInfo.description || ''}
                                placeholder={t('Please enter a description')}
                                handleChange={e => setMenuInfo({ ...menuInfo, description: e.currentTarget.value })}
                            />
                        }
                    />
                </Col>
                <Col className={'d-flex mb-3 pnt-label--group p-0'} xl={5}>
                    <Label
                        labelGroupClassName={'w-100 align-items-start'}
                        name={t('Display Icon')}
                        labelValueClassName={'label-dot w-30 lh-38'}
                        value={
                            <IconSelectBoxWrapper>
                                <div className={'w-100 d-flex align-items-center'}>
                                    <SelectedIconContainer className={'flex-center'}>
                                        <i className={cx('metismenu-icon', menuInfo.icon)} />
                                    </SelectedIconContainer>
                                </div>
                                <div className={'icon-select-box'}>
                                    {menuIcons.map((iconClassName, i) => (
                                        <div
                                            key={`${iconClassName}_${i}`}
                                            className={cx(
                                                'flex-center',
                                                'menu-icon-wrapper',
                                                iconClassName === menuInfo.icon && 'selected',
                                            )}
                                            onClick={e => {
                                                if (!e.currentTarget.classList.contains('selected')) {
                                                    setMenuInfo({ ...menuInfo, icon: iconClassName });
                                                }
                                            }}
                                        >
                                            <i className={`metismenu-icon ${iconClassName}`} />
                                        </div>
                                    ))}
                                </div>
                            </IconSelectBoxWrapper>
                        }
                    />
                </Col>
                <Col className={'d-flex mb-3 pnt-label--group p-0'} xl={5}>
                    <Label
                        labelGroupClassName={'w-100'}
                        name={t('Sort Order')}
                        labelValueClassName={'label-dot w-30'}
                        value={
                            <TextInput
                                inputGroupClassName={'w-100'}
                                type={'number'}
                                handleChange={e => {
                                    const { value } = e.currentTarget;
                                    if (value > MAX_ORDER_NUM) {
                                        setMenuInfo(prevState => ({ ...prevState, order: MAX_ORDER_NUM }));
                                    } else {
                                        setMenuInfo(prevState => ({ ...prevState, order: value }));
                                    }
                                }}
                                name={'order'}
                                value={menuInfo.order || 0}
                                min={0}
                                max={MAX_ORDER_NUM}
                            />
                        }
                    />
                </Col>
            </Card>
            <SaveConfirmModal />
            <ValidationModal />
            <SaveSuccessModal />
            <SaveFailureModal />
        </>
    );
};

const SelectedIconContainer = styled.div`
    width: 38px;
    height: 38px;
    border: 1px solid #eceaf2;
    border-radius: 3px;
`;

const IconSelectBoxWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 250px;
    display: grid;
    row-gap: 5px;
    grid-template-rows: 38px auto;
    overflow: hidden;
`;

export default MenuDetail;
