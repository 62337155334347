import React from 'react';
import { useColumns, useTranslation } from '@hooks';
import * as column from '../column';
import InnerItemCard from '@components/Card/InnerItemCard';

import { Table } from '@components';
import styled from 'styled-components';
import Search from './Search';
import moment from 'moment';

const PG_RESULT_WARING_CODE = 'Er-100';

const OutpatientUnattendedReceivingStatusList = ({ outpatientList, handleOutpatientListParam }) => {
    const t = useTranslation('OutpatientUnattendedReceivingStatus');

    const columns = useColumns(
        [
            column.category(),
            column.receiptDate({
                Cell: ({ value }) => {
                    return value ? moment.unix(value).format('YYYY-MM-DD') : ' - ';
                },
            }),
            column.receiptTime({
                Cell: ({ value }) => {
                    return value ? moment.unix(value).format('HH:mm') : ' - ';
                },
            }),
            column.patientName(),
            column.id(),
            column.speciality(),
            column.doctor(),
            column.receiptType(),
            column.receiptID(),
            column.receiptWon({
                Cell: ({ row, value }) => {
                    const { original } = row;
                    const { pgResultCode } = original;
                    return pgResultCode === PG_RESULT_WARING_CODE ? (
                        <span className={'color-danger'}>{value}</span>
                    ) : (
                        <span>{value}</span>
                    );
                },
            }),
            column.pgResultCode({
                Cell: ({ row, value }) => {
                    const { original } = row;
                    const { pgResultCode } = original;
                    return pgResultCode === PG_RESULT_WARING_CODE ? (
                        <span className={'color-danger'}>{value}</span>
                    ) : (
                        <span>{value}</span>
                    );
                },
            }),
            column.pgResult({
                Cell: ({ row, value }) => {
                    const { original } = row;
                    const { pgResultCode } = original;
                    return pgResultCode === PG_RESULT_WARING_CODE ? (
                        <span className={'color-danger'}>{value}</span>
                    ) : (
                        <span>{value}</span>
                    );
                },
            }),
            column.emrResultCode({
                Cell: ({ row, value }) => {
                    const { original } = row;
                    const { pgResultCode } = original;
                    return pgResultCode === PG_RESULT_WARING_CODE ? (
                        <span className={'color-danger'}>{value}</span>
                    ) : (
                        <span>{value}</span>
                    );
                },
            }),
            column.prescriptionOutputResultCodes({
                Cell: ({ row, value }) => {
                    const { original } = row;
                    const { pgResultCode } = original;
                    return pgResultCode === PG_RESULT_WARING_CODE ? (
                        <span className={'color-danger'}>{value}</span>
                    ) : (
                        <span>{value}</span>
                    );
                },
            }),
        ],
        t,
        [],
    );

    return (
        <Container>
            <InnerItemCard
                className={'h-100'}
                bodyClassName={'h-100'}
                header={{
                    title: t('Patient lists'),
                }}
            >
                <Search />
                <TableContainer className={'h-90'}>
                    <Table
                        data={outpatientList}
                        columns={columns}
                        onPageChange={handleOutpatientListParam}
                        dynamicRowHeight={true}
                    />
                </TableContainer>
            </InnerItemCard>
        </Container>
    );
};
const Container = styled.div`
    height: 550px;
    margin-top: 5px;
`;
const TableContainer = styled.div`
    height: 250px;
    margin-bottom: 5px;
`;
export default OutpatientUnattendedReceivingStatusList;
