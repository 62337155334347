import React from 'react';
import InnerItemCard from '@components/Card/InnerItemCard';
import { useTranslation } from '@hooks';
import { OUTPATIENT_REPORT } from '../../constants';
import CongestionSpaceLayer from './CongestionSpaceLayer';
import { CONGESTION_TYPE_LIST } from './constants';
import { Icon } from '@components';

const Map = () => {
    const t = useTranslation(OUTPATIENT_REPORT);

    return (
        <InnerItemCard
            className={'m-2 h-100'}
            bodyClassName={'h-100 flx-col'}
            header={{ title: t('Congestion Analysis') }}
        >
            <div className={'flx-col h-90 w-100 '}>
                <CongestionSpaceLayer />
                <div className={'gap-2 flx-row justify-content-center w-100'}>
                    {CONGESTION_TYPE_LIST.map(option => (
                        <div key={option.color} className={'d-flex flx-center gap-1'}>
                            <Icon iconName={'rectangle'} style={{ color: option.color }} />
                            <span>{t(option.desc)}</span>
                        </div>
                    ))}
                </div>
            </div>
        </InnerItemCard>
    );
};

export default Map;
