import React, { useMemo } from 'react';
import InnerItemCard from '@components/Card/InnerItemCard';
import { useTranslation } from '@hooks';
import { BarChart } from '@components';
import { customLegendPlugin, hoverBackgroundPlugin, topTicketLabels } from '../util/chartPlugin';
import ChartDataLabels from 'chartjs-plugin-datalabels';

type WaitingTicket = {
    departmentCode: string;
    departmentName: string;
    totalUsedAmount: number;
    generalIssuedAmount: number;
    registrationIssuedAmount: number;
};

interface Props {
    data: WaitingTicket[];
}

const Chart = ({ data }: Props) => {
    const t = useTranslation('outpatientWaitTicket');

    const barData = useMemo(
        () => ({
            labels: data?.map(row => row.departmentName) ?? [],
            datasets: [
                {
                    label: t('General Publishing'),
                    data: data?.map(row => row.generalIssuedAmount) ?? [],
                    stack: 'patient',
                    datalabels: {
                        formatter(value: number) {
                            return value || '';
                        },
                    },
                },
                {
                    label: t('Issue registration numbers'),
                    data: data?.map(row => row.registrationIssuedAmount) ?? [],
                    stack: 'patient',
                    datalabels: {
                        formatter(value: number) {
                            return value || '';
                        },
                    },
                },
            ],
        }),
        [data],
    );

    return (
        <InnerItemCard
            className={'m-2 h-90'}
            bodyClassName={'h-100'}
            header={{ title: t('Order Ticket Usage by Department') }}
        >
            <div className={'h-90 flx-row position-relative'}>
                <div
                    id="custom-legend-container"
                    className={'d-flex gap-2 position-absolute'}
                    style={{
                        top: 0,
                        right: 0,
                    }}
                />
                <BarChart
                    data={barData}
                    type={'stackedBar'}
                    plugins={[hoverBackgroundPlugin, ChartDataLabels as any, customLegendPlugin, topTicketLabels]}
                    options={{
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 60,
                            },
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            datalabels: {
                                color: '#ffffff', // 라벨 텍스트 색상
                                anchor: 'center', // 기준점
                                align: 'center', // 정렬 방식
                                font: {
                                    size: 14, // 폰트 크기
                                    weight: 'bold', // 폰트 두께
                                },
                            },
                            tooltip: {
                                mode: 'index',
                                intersect: false,
                                callbacks: {
                                    // 툴팁에 표시할 내용을 설정
                                    label: function (tooltipItem) {
                                        const { label } = tooltipItem.dataset;
                                        const value = tooltipItem.raw; // 데이터 값
                                        return `${label}: ${value}${t('Cases')}`;
                                    },
                                    title: function (tooltipItems) {
                                        const { label, dataIndex } = tooltipItems[0];
                                        const { totalUsedAmount } = data[dataIndex];
                                        return `${label}\n${t('Total Issued')} : ${totalUsedAmount}${t('Cases')}`;
                                    },
                                },
                            },
                        },
                        scales: {
                            x: {
                                title: {
                                    display: true,
                                    text: t('Order Ticket Usage Department'),
                                    font: {
                                        size: 16,
                                        family: 'pretendard',
                                    },
                                    padding: { top: 20 },
                                },
                            },
                            y: {
                                grace: '20%',
                                beginAtZero: true,
                                suggestedMax: 10,
                                title: {
                                    display: true,
                                    text: t('Order Ticket Usage (Cases)'),
                                    font: {
                                        size: 15,
                                        family: 'pretendard',
                                    },
                                },
                            },
                        },
                    }}
                />
            </div>
        </InnerItemCard>
    );
};

export default Chart;
