// 설정 목록 페이지 사이즈
export const PAGE_SIZE = 15;

export const YN_APPLY = {
    Y: 'Enabled',
    N: 'Disabled',
};

export const YN_SELECT = {
    Y: 'Select',
    N: 'Not Select',
};

export const YN_USE = {
    Y: 'Used',
    N: 'Not Used',
};
