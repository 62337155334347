import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '@hooks';
import { AnomalyAlertGroupSettings } from '@api/sh/patientAnomalyAlertSetting';
import cx from 'classnames';
import { Icon } from '@components';
import { NullableNumber } from '@util/type/util';
import { useAnomalyAlertStateContext } from './slice';
import { useUpdateGroupInfo } from './hooks';

const GroupSelect = ({
    selectedGroupNum,
    handleSelectGroup,
}: {
    selectedGroupNum: NullableNumber;
    handleSelectGroup: (selected: number) => void;
}) => {
    const t = useTranslation('PatientAnomalyAlertSetting');
    const { alertGroupSettingList } = useAnomalyAlertStateContext();

    useUpdateGroupInfo();

    return (
        <GroupSelectWrapper>
            {alertGroupSettingList.map((groupInfo: AnomalyAlertGroupSettings) => {
                return (
                    <div
                        key={'alert-setting-' + groupInfo.groupNum}
                        className={cx('alert-group-item', selectedGroupNum === groupInfo.groupNum && 'selected')}
                        onClick={() => {
                            handleSelectGroup(groupInfo.groupNum);
                        }}
                    >
                        <span className={'group-info-title'}>
                            <span className={'ward-name text-ellipsis'} title={groupInfo.groupName}>
                                {groupInfo.groupName}
                            </span>
                            <span className={cx('setting-status')}>
                                {groupInfo.groupSelected === 'Y' && (
                                    <Icon className={'status-icon mr-1'} iconName={'notifications_active'} />
                                )}
                            </span>
                        </span>
                        <div className={'pnt-divider horizon-line'} />
                        <div className={'group-info-count-wrapper'}>
                            <span className={'group-info-count'}>
                                <span>{t('Set')}</span>
                                <span>{groupInfo.alertSettingGroupCount}</span>
                                <div className={'pnt-divider'} />
                                <span>{t('Applied')}</span>
                                <span>{groupInfo.alertSettingActiveCount}</span>
                            </span>
                        </div>
                    </div>
                );
            })}
        </GroupSelectWrapper>
    );
};

const GroupSelectWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;

    #root .app-container & .alert-group-item {
        cursor: pointer;
        background-color: var(--depth-9);
        border-radius: 5px;
        padding: 0.8rem;
        flex: 0 0 210px;

        span.group-info-title {
            display: grid;
            grid-template-columns: auto min-content;
            justify-content: space-between;
            align-items: center;

            span {
                color: var(--dark-gray);

                &.ward-name {
                    font-size: 1.125rem;
                    font-weight: bold;
                    text-decoration: underline;
                }

                &.setting-status {
                    display: flex;
                    align-items: center;
                    font-size: 0.75rem;
                    color: #fafa24;

                    .status-icon {
                        font-size: 1.125rem;
                        color: #fafa24;
                    }
                }
            }
        }

        div.group-info-count-wrapper {
            width: 100%;

            .group-info-count {
                display: flex;
                float: right;
                width: min-content;
                gap: 0.5rem;

                span {
                    word-break: keep-all;
                    color: var(--dark-gray);
                }
            }
        }

        .pnt-divider {
            margin: 0.5rem 0;
            background-color: var(--dark-gray);

            &:not(.horizon-line) {
                margin: 0 0.5rem;
                width: 1px;
            }
        }

        &.selected {
            span {
                &.ward-name {
                    color: #fff;
                }
            }

            background-color: var(--secondary);

            div.group-info-count-wrapper .group-info-count span {
                color: #fff;
            }

            .pnt-divider {
                background-color: #fff;
            }
        }
    }
`;

export default GroupSelect;
