export const OUTPATIENT_REPORT = 'OutpatientReport';

export const DATE_TYPE_OPTIONS = [
    {
        value: 'daily',
        label: 'Daily',
    },
    {
        value: 'monthly',
        label: 'Monthly',
    },
];

export const METHOD_OPTIONS = [
    {
        value: 0,
        label: 'Individual',
    },
    {
        value: 1,
        label: 'Sum',
    },
];

export const OUTPATIENT_CONGESTION_CHART_TITLE = 'Congestion by department';

export const TIME_LABELS = [
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
];

export const TIME_LABELS_DATA = [
    { x: '08:15', range: '08:00 - 08:30' },
    { x: '08:45', range: '08:30 - 09:00' },
    { x: '09:15', range: '09:00 - 09:30' },
    { x: '09:45', range: '09:30 - 10:00' },
    { x: '10:15', range: '10:00 - 10:30' },
    { x: '10:45', range: '10:30 - 11:00' },
    { x: '11:15', range: '11:00 - 11:30' },
    { x: '11:45', range: '11:30 - 12:00' },
    { x: '12:15', range: '12:00 - 12:30' },
    { x: '12:45', range: '12:30 - 13:00' },
    { x: '13:15', range: '13:00 - 13:30' },
    { x: '13:45', range: '13:30 - 14:00' },
    { x: '14:15', range: '14:00 - 14:30' },
    { x: '14:45', range: '14:30 - 15:00' },
    { x: '15:15', range: '15:00 - 15:30' },
    { x: '15:45', range: '15:30 - 16:00' },
    { x: '16:15', range: '16:00 - 16:30' },
    { x: '16:45', range: '16:30 - 17:00' },
    { x: '17:15', range: '17:00 - 17:30' },
    { x: '17:45', range: '17:30 - 18:00' },
];

export const MEDICAL_WASTE = 'MedicalWaste';

export const ASSET_MANAGEMENT = 'AssetManagement';

export const MEDICAL_WASTE_TAB_LIST = [
    {
        name: 'daily',
    },
    {
        name: 'monthly',
    },
    {
        name: 'yearly',
    },
] as const;

export const WASTE_TYPE_LABEL = {
    isolatedMedical: 'Isolated medical',
    normalMedical: 'Normal medical',
    tissue: 'Tissue',
    damage: 'Damage',
    pathology: 'Pathology',
    biochemistry: 'Biochemistry',
    bloodContamination: 'Blood contamination',
    humanTissue: 'Human tissue',
};

export const INSPECTION_TYPE = {
    HIGH: 'HIGH',
    NORMAL: 'NORMAL',
    MIDDLE: 'MIDDLE',
    LOW: 'LOW',
};

export const INSPECTION_COMPLETED_TYPE = {
    YES: 'Y',
    NO: 'N',
};

export const INSPECTION_RESULT_TYPE = {
    GOOD: 'good',
    BAD: 'bad',
};

export const DUTY_LIST = {
    DAY: 'DAY',
    EVENING: 'EVENING',
    NIGHT: 'NIGHT',
};

export const PROPERTY_ID = {
    ASSET: {
        // 부서/병동
        WARD: 'ward',
        // 대여 부서/병동
        BORROWWARD: 'borrowward',
        // 담당자
        MANAGER: 'manager',
        // 사용 부서/병동 ID
        USING_DEP_ID: 'using_dep_id',
        // 사용자 ID
        USING_USER_ID: 'using_user_id',
        // 사용 형태
        TYPE_OF_USE: 'type_of_use',
        // 공급사
        SUPPLIER: 'supplier',
        // 제조사
        MANUFACTURER: 'manufacturer',
        // 제품명
        PRODUCT_NM: 'product_nm',
        // 모델 번호/이름
        MODEL_NU: 'model_nu',
        // 시리얼 넘버
        SERIAL_NU: 'serial_nu',
        // 입고일
        RECEIVED_DATE: 'received_date',
        // 배치일
        PLACEMENT_DATE: 'placement_date',
        // 보관 장소
        STORAGE_LOCATION: 'storage_location',
        // 최종 점검 일시
        LAST_INSPECT_DATE: 'last_inspect_date',
        // 점검 주기
        INSPECT_INTERVAL: 'inspect_interval',
        // 예정 점검 일시
        SCHEDULED_INSPECTION_DATE: 'scheduled_inspection_date',
        // 비고
        REMARKS: 'remarks',
        // 위험도
        RISK_LEVEL: 'risk_level',
        // 점검 대상 여부
        CHECK_REQUIRE: 'check_require',
    },
};

export const DATE_TYPE = {
    DAILY: 'daily',
    WEEKLY: 'weekly',
    MONTHLY: 'monthly',
    YEARLY: 'yearly',
} as const;
