import { useState } from 'react';

const useDateTypeOptions = (initValue = 'daily') => {
    const [dateType, setDateType] = useState(initValue);

    const handleDateTypeChange = value => {
        setDateType(value);
    };

    return {
        dateType,
        handleDateTypeChange,
    };
};

export default useDateTypeOptions;
