import React, { useCallback, useEffect, useRef, useState } from 'react';
import Draggable from '@components/Draggable';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import { useHistory } from 'react-router-dom';
import Control from 'react-leaflet-control';
import useTranslation from '@util/hooks/useTranslation';
import { Map, RotatedImageOverlay } from '@components';
import { setSelectedNotificationLogInfo } from '@reducer/Notification';

const LocationPopup = ({ appMainOuterRef }) => {
    const t = useTranslation('TimeLine');
    const { selectedNotificationLogInfo } = useSelector(state => state.Notification);
    const { floorId, latLng, categoryCode, targetNum } = selectedNotificationLogInfo;
    const { floorList } = useSelector(state => state.FloorInfo);
    const { categoryToImg } = useSelector(state => state.CategoryInfo);
    const storeDispatch = useDispatch();
    const history = useHistory();

    const mapRef = useRef();

    const [floor, setFloor] = useState(null);
    const [modal, setModal] = useState(false);
    const [position, setPosition] = useState({
        x: 0,
        y: 0,
    });
    const wrapper = useCallback(node => {
        const appMainPosition = getAppMainPosition();
        const popupSize = getPopupSize(node);
        const x = (appMainPosition.width - popupSize.width) / 2;
        const y = -(appMainPosition.height + popupSize.height) / 2;
        setPosition({
            x: x,
            y: y,
        });
    }, []);

    useEffect(() => {
        if (Object.keys(selectedNotificationLogInfo).length) {
            toggleModal();
        }
    }, [history.location]);

    useEffect(() => {
        if (Object.keys(selectedNotificationLogInfo).length) {
            setFloor(floorList.find(floor => floor.floorId === floorId));
            setModal(true);
        }
        if (mapRef && mapRef.current) {
            const map = mapRef.current.leafletElement;

            map.setView(latLng);
        }
    }, [selectedNotificationLogInfo]);

    const getAppMainPosition = () => {
        const appMainPosition = { width: 0, height: 0 };
        if (appMainOuterRef.current) {
            const appMainOuterContainer = appMainOuterRef.current;
            const { offsetWidth: width, offsetHeight: height } = appMainOuterContainer;

            appMainPosition.width = width;
            appMainPosition.height = height;
        }
        return appMainPosition;
    };

    const getPopupSize = popupElement => {
        const popupSize = { width: 0, height: 0 };
        if (popupElement) {
            const { offsetWidth: width, offsetHeight: height } = popupElement;

            popupSize.width = width;
            popupSize.height = height;
        }
        return popupSize;
    };

    const toggleModal = e => {
        storeDispatch(setSelectedNotificationLogInfo({}));
        setModal(!modal);
    };
    return (
        <Draggable key={JSON.stringify(position)} bounds="body" defaultPosition={position}>
            <Toast isOpen={modal} className={'alert-location-popup'} innerRef={wrapper}>
                <ToastHeader toggle={toggleModal}>{t('Location')}</ToastHeader>
                <ToastBody>
                    <div style={{ padding: '0.5rem 0', height: '100%' }}>
                        {!!Object.keys(selectedNotificationLogInfo).length && (
                            <Map
                                tile={false}
                                style={{ backgroundColor: '#C6C6E7' }}
                                ref={mapRef}
                                rotation={floor?.rotation}
                            >
                                <Control position="topleft">
                                    <div className={'item-container'}>
                                        <span>{`${t('Current Floor', 'RealTimeLocationStatus')} : `}</span>
                                        <span>{floor && floor.floorName}</span>
                                    </div>
                                </Control>
                                {floor && floor.imgURL && floor.bounds.length && (
                                    <RotatedImageOverlay
                                        key={floor.floorId}
                                        url={floor.imgURL}
                                        deg={floor.deg}
                                        bounds={floor.bounds}
                                        onLoad={e => {
                                            const map = mapRef.current.leafletElement;
                                            if (map) {
                                                map.setView(latLng);
                                            }
                                        }}
                                    />
                                )}
                                {categoryCode ? (
                                    <Marker
                                        key={targetNum}
                                        position={latLng}
                                        icon={L.divIcon({
                                            className: 'category-marker',
                                            html: `<img class="marker-img" src="${categoryToImg[categoryCode]}"/>`,
                                        })}
                                    />
                                ) : (
                                    <Marker
                                        key={targetNum}
                                        position={latLng}
                                        icon={L.divIcon({
                                            className: 'category-marker',
                                            html: `<div class="marker-img replace-img"><div></div></div>`,
                                        })}
                                    />
                                )}
                            </Map>
                        )}
                    </div>
                </ToastBody>
            </Toast>
        </Draggable>
    );
};

export default LocationPopup;
