import React from 'react';
import ContextProvider from '@components/ContextProvider';
import WidgetContents from './WidgetContents';
import outpatientCongestionSlice from './Context/slice';
import { OutpatientCongestionStatus, OutpatientCongestionStatusDispatchContext } from './Context';

const OutpatientCongestionAnalysisBySpace = ({ children, widgetInfo, ...restProps }) => {
    return (
        <ContextProvider
            StateContext={OutpatientCongestionStatus}
            DispatchContext={OutpatientCongestionStatusDispatchContext}
            slice={outpatientCongestionSlice}
        >
            <WidgetContents widgetInfo={widgetInfo} children={children} {...restProps} />
        </ContextProvider>
    );
};

export default OutpatientCongestionAnalysisBySpace;
