import React, { useRef } from 'react';
import { Map, RotatedImageOverlay } from '@components';
import { CircleMarker, Tooltip } from 'react-leaflet';
import { CONGESTION_TYPE_LIST } from './constants';
import { useOutpatientCongestionContext } from './Context';

const TIME_INDEX = 300; // 5분

const getCogestionInfo = congestion => {
    let radius = 0;
    let color = 'transparent';

    CONGESTION_TYPE_LIST.forEach(({ min, max }, index) => {
        if (congestion >= min && congestion <= max) {
            radius = CONGESTION_TYPE_LIST[index].radius;
            color = CONGESTION_TYPE_LIST[index].color;
        }
    });

    return { radius, color };
};

const CongestionSpaceLayer = () => {
    const mapRef = useRef();
    const { selectedFloorInfo, playTime, startDate, congestionData } = useOutpatientCongestionContext();
    const currentTimeIndex = Math.floor((playTime - startDate) / TIME_INDEX); // 5분에 한번씩 인덱스를 교체

    return (
        <div className={'h-100 w-100'}>
            <Map tile={false} ref={mapRef} rotation={selectedFloorInfo?.rotation}>
                {selectedFloorInfo.imgURL && (
                    <RotatedImageOverlay
                        key={selectedFloorInfo.floorId}
                        url={selectedFloorInfo.imgURL}
                        deg={selectedFloorInfo.deg}
                        bounds={selectedFloorInfo.bounds}
                        onLoad={e => {
                            const lat = selectedFloorInfo
                                ? [selectedFloorInfo?.neLat, selectedFloorInfo?.neLng]
                                : [0, 0];
                            const lng = selectedFloorInfo
                                ? [selectedFloorInfo?.swLat, selectedFloorInfo?.swLng]
                                : [0, 0];
                            const { target: layer } = e;
                            layer._map.fitBounds([lat, lng]);
                            layer._map.setZoom(selectedFloorInfo?.zoom);
                        }}
                    />
                )}
                {congestionData[currentTimeIndex]?.map(({ kioskName, lat, lng, congestion }, index) => {
                    return (
                        <CircleMarker
                            key={kioskName + congestion + index}
                            className={`pulse-${getCogestionInfo(congestion).color}`}
                            center={[lat, lng]}
                            radius={getCogestionInfo(congestion).radius}
                            color={getCogestionInfo(congestion).color}
                            fillOpacity={1}
                        >
                            <Tooltip
                                className={`geofence-name-label`}
                                direction="center"
                                offset={[0, 0]}
                                opacity={1}
                                permanent
                            >
                                <div>{congestion}</div>
                            </Tooltip>
                        </CircleMarker>
                    );
                })}
            </Map>
        </div>
    );
};

export default CongestionSpaceLayer;
