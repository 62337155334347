import React, { useEffect, useState } from 'react';
import Control from 'react-leaflet-control';
import { Button, Checkbox, GeofenceLayer, Icon } from '@components';
import { useAsync, useTranslation } from '@hooks';
import { fetchIotItemList } from '@api/common/asset';
import { clearData, setFloorInfo, setSelectedFloor } from '../realTimeLocationSlice';
import { useRealTimeLocationStatusContext, useRealTimeLocationStatusDispatch } from '../index';
import { useSelector } from 'react-redux';

const SingleTarget = ({ mapRef }) => {
    const t = useTranslation('RealTimeLocationStatus');

    const { floorInfo, geofenceInfo, selectedItem, realTimeLog } = useRealTimeLocationStatusContext();
    const dispatch = useRealTimeLocationStatusDispatch();
    const { floorList } = useSelector(state => state.FloorInfo);

    const [positionTracking, setPositionTracking] = useState(false);

    const { promise: getIotItem } = useAsync({
        promise: fetchIotItemList,
        resolve: response => {
            const { rows } = response;
            const data = rows[0];
            const targetFloorInfo = floorList.find(floor => floor.floorId === data.floorId);
            if (targetFloorInfo) {
                dispatch(setSelectedFloor(data.floorId));
                dispatch(setFloorInfo(floorList.find(floor => floor.floorId === data.floorId)));
            } else {
                dispatch(setSelectedFloor(''));
                dispatch(setFloorInfo({}));
            }
        },
    });

    useEffect(() => {
        if (realTimeLog.data[0]) {
            const map = mapRef.current.leafletElement;
            map.setView(realTimeLog.data[0].location.latLng);
        }
    }, []);

    useEffect(() => {
        if (selectedItem.hasOwnProperty('targetId')) {
            getIotItem({ keyType: 'targetId', targetId: selectedItem.targetId });
        }
    }, [selectedItem]);

    useEffect(() => {
        if (positionTracking) {
            const map = mapRef.current.leafletElement;
            if (realTimeLog.data[0]) {
                map.setView(realTimeLog.data[0].location.latLng);
            }
        }
    }, [realTimeLog]);

    const handleRefreshClick = () => {
        dispatch(clearData());
        setPositionTracking(false);
    };

    const handleItemPositionCheck = e => {
        setPositionTracking(e.target.checked);

        if (realTimeLog.data[0]) {
            const map = mapRef.current.leafletElement;
            map.setView(realTimeLog.data[0].location.latLng);
        }
    };

    return (
        <>
            <Control position={'topleft'} className={'flx-row'}>
                <div className={'leaflet-map-checkbox checkbox-container mr-1'}>
                    <Checkbox
                        className={'form-h-small'}
                        id={'itemPosition'}
                        name={t('Always Show Item Position In Center')}
                        checked={positionTracking}
                        handleChecked={handleItemPositionCheck}
                    />
                </div>
                {Object.keys(selectedItem).length > 0 && (
                    <Button
                        className={'leaflet-map-btn btn-icon-only'}
                        iconName="refresh"
                        onClick={handleRefreshClick}
                    />
                )}
            </Control>

            <Control position="bottomleft" className="control-container">
                <div className="pnt-nameplate leaflet-map-nameplate mw-100">
                    <Icon iconName={'info_outline'} />
                    <div>{`${t('Current Floor')} : ${floorInfo?.floorName ?? ''}`}</div>
                </div>
            </Control>

            {geofenceInfo && geofenceInfo.length > 0 && <GeofenceLayer geofenceList={geofenceInfo} />}
        </>
    );
};

export default SingleTarget;
