import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ScreenDetailPopup from './Components/ScreenDetailPopup';
import { ScreenSubMenu } from './Components/ScreenListColumns';
import { useAppSelector, useAsync, useTranslation } from '@hooks';
import { Button, Card, Label, LoadingBlock, Page, Table } from '@components';
import { fetchScreenList } from '@api/common/screen';
import screenListSlice, {
    initScreenInfo,
    ScreenListContext,
    ScreenListDispatchContext,
    selectScreen,
    setScreenListInfo,
    useScreenListDispatchContext,
    useScreenListStateContext,
} from './slice';
import { CustomMenu, setScreenList } from '@reducer/CustomMenu';
import * as commonCol from '../../../util/grid/column';
import useColumns from '@hooks/useColumns';
import Search from './Components/Search';
import ContextProvider from '@components/ContextProvider';
import { Cell } from '@components/Table/types';
import { NullableString } from '@util/type/util';
import HighlightedText from '../../../Components/HighlightedText';
import { WidgetInfo } from '../../../../Widgets/staticInfo';

const ScreenList = () => {
    const { originScreenList } = useAppSelector(state => state.CustomMenu);

    return (
        <ContextProvider
            StateContext={ScreenListContext}
            DispatchContext={ScreenListDispatchContext}
            slice={screenListSlice}
            initializer={initialState => ({
                ...initialState,
                screenListInfo: { ...initScreenInfo, rows: originScreenList },
            })}
        >
            <ContextConsumer />
        </ContextProvider>
    );
};
const ContextConsumer = () => {
    const storeDispatch = useDispatch();
    const t = useTranslation('Screen');
    const { originScreenList } = useAppSelector(state => state.CustomMenu);
    const { userInfo } = useAppSelector(state => state.UserInfo as Record<string, any>);

    const { selectedScreen, screenListInfo, highlight } = useScreenListStateContext();
    const dispatch = useScreenListDispatchContext();

    const [modal, setModal] = useState<boolean>(false);
    const columns = useColumns(
        [
            {
                Header: 'Menu Name',
                className: 'text-ellipsis',
                accessor: 'menuName',
                width: 150,
            },
            {
                Header: 'Screen Name',
                className: 'text-ellipsis',
                accessor: 'screenName',
                Cell: ({ value }: Cell<CustomMenu, string>) => (
                    <HighlightedText originText={value} highlightText={highlight} />
                ),
            },
            {
                Header: 'Screen Description',
                className: 'text-ellipsis text-start',
                accessor: 'description',
                width: 250,
            },
            {
                Header: 'Widget List',
                className: 'px-3 text-ellipsis',
                accessor: 'widgetInfo',
                width: 230,
                formatTitle: ({ value }: Cell<CustomMenu, NullableString>) => {
                    const widgetInfo: WidgetInfo[] = value ? JSON.parse(value) : [];

                    return widgetInfo?.length && widgetInfo.length !== 1
                        ? `${widgetInfo[0]?.config?.settings?.name ?? t(widgetInfo[0].name, 'Widget')} ${t('other')} ${
                              widgetInfo.length - 1
                          }`
                        : t(widgetInfo[0]?.name, 'Widget');
                },
                Cell: ({ value }: Cell<CustomMenu, NullableString>) => {
                    const widgetInfo: WidgetInfo[] = value ? JSON.parse(value) : [];
                    return widgetInfo?.map((v, idx) => {
                        const widgetName = v.config?.settings?.name ?? t(v.name, 'Widget');
                        return (
                            <Label
                                key={v.id}
                                name={
                                    <HighlightedText
                                        className={'text-transform-none'}
                                        originText={widgetName}
                                        highlightText={highlight}
                                    />
                                }
                                labelValueClassName={`pnt-txt txt-dot txt-bold ${
                                    value?.length !== idx + 1 ? 'mb-2' : ''
                                }`}
                            />
                        );
                    });
                },
                style: {
                    maxHeight: '150px',
                    overflow: 'auto',
                },
            },
            {
                Header: 'Sort Order',
                className: 'd-flex flx-center',
                accessor: 'order',
                width: 80,
            },
            commonCol.modDate({ width: 180 }),
            {
                Header: 'Action',
                width: 70,
                className: 'flex-center overflow-visible',
                Cell: ScreenSubMenu,
            },
        ],
        t,
        [highlight],
    );

    const { state: screenListReqState, promise: getScreenList } = useAsync({
        promise: fetchScreenList,
        fixedParam: { isAll: 'Y', groupNum: userInfo.authGroupNum },
        resolve: response => {
            storeDispatch(setScreenList(response.rows ?? []));
        },
    });

    const handleModalOpen = () => {
        setModal(true);
    };

    const handleModalClose = () => {
        setModal(false);
        dispatch(selectScreen(null));
    };

    useEffect(() => {
        getScreenList();
    }, []);

    useEffect(() => {
        if (selectedScreen) {
            setModal(true);
        } else {
            setModal(false);
        }
        return () => {
            setModal(false);
        };
    }, [selectedScreen]);

    useEffect(() => {
        dispatch(setScreenListInfo({ ...initScreenInfo, rows: originScreenList }));
    }, [originScreenList]);

    return (
        <>
            <Page className="h-100" menuPath={[t('Screen Management', 'Menu'), t('Screen List', 'Menu')]}>
                <Card
                    className="h-100 mb-0"
                    bodyClassName={'d-flex flex-column gap-4'}
                    header={{
                        title: t('Screen List'),
                        action: (
                            <Button className={'btn-icon btn-secondary'} iconName="add" onClick={handleModalOpen}>
                                {t('Create a Screen')}
                            </Button>
                        ),
                    }}
                >
                    <Search />
                    <LoadingBlock blocking={screenListReqState.isLoading}>
                        <Table columns={columns} data={screenListInfo} paging={false} dynamicRowHeight />
                    </LoadingBlock>
                </Card>
            </Page>
            <ScreenDetailPopup initOpen={modal} toggleModal={handleModalClose} screenInfo={selectedScreen} />
        </>
    );
};

export default ScreenList;
