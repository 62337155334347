import React from 'react';
import Card from '../../Components/WidgetCard';
import { Row } from 'reactstrap';
import { fetchTagBatteryCount } from '@api/common/asset';
import useMonitor from '../../util/useMonitor';
import { useTranslation } from '@hooks';
import useFilter from '@hooks/useFilter';
import { Icon, Table } from '@components';
import { useBatteryStatusColumns } from './Components/useBatteryStatusColumns';

const defaultData = {
    0: 0,
    10: 0,
    30: 0,
    60: 0,
    80: 0,
    unknown: 0,
};

const TABLE_LEGEND_HEIGHT = 21.6;
const TABLE_HEADER_HEIGHT = 32;
const TABLE_ROW_HEIGHT = 36.4;

const Battery = ({ level, IconText, data }) => {
    const t = useTranslation('TagBatteryStatus');
    return (
        <div className={'bg-depth-6 p-3 flx-row gap-0 align-center'}>
            <div>
                <Icon iconName={IconText} style={{ fontSize: '60px' }} />
            </div>
            <div className="flx-col">
                <div className="flx-row gap-1">
                    <p className="pnt-txt txt-bold s-3">{data}</p>
                    <p className="pnt-txt s-6">{t('Count')}</p>
                </div>
                <div className="flx-row  opacity-8 gap-1">
                    <p className="pnt-txt s-8 txt-bold">{level}</p>
                    {IconText !== 'battery_unknown' && <p className="pnt-txt s-9 opacity-8">(%)</p>}
                </div>
            </div>
        </div>
    );
};

const TagBatteryStatus = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const t = useTranslation('TagBatteryStatus');
    const filterParam = useFilter();

    const { data } = useMonitor({
        config,
        defaultData,
        dynamicParam: filterParam,
        fetchData: fetchTagBatteryCount,
        makeData: ({ newData }) => {
            const batteryCountData = newData.rows;

            let batteryStatus = {
                0: 0,
                10: 0,
                30: 0,
                60: 0,
                80: 0,
                unknown: 0,
            };

            if (batteryCountData && batteryCountData.length > 0) {
                for (let i = 0; i < batteryCountData.length; i++) {
                    switch (batteryCountData[i].division) {
                        case '0':
                            batteryStatus[0] += batteryCountData[i].count;
                            break;
                        case '10':
                        case '20':
                            batteryStatus[10] += batteryCountData[i].count;
                            break;
                        case '30':
                        case '40':
                        case '50':
                            batteryStatus[30] += batteryCountData[i].count;
                            break;
                        case '60':
                        case '70':
                            batteryStatus[60] += batteryCountData[i].count;
                            break;
                        case '80':
                        case '90':
                            batteryStatus[80] += batteryCountData[i].count;
                            break;
                        case 'unknown':
                            batteryStatus['unknown'] += batteryCountData[i].count;
                            break;
                        default:
                            break;
                    }
                }
            }

            return batteryStatus;
        },
    });

    const tagBatteryColumn = useBatteryStatusColumns();

    return (
        <Card widgetInfo={widgetInfo} {...restProps}>
            <div
                className={'w-100 pb-3'}
                style={{ height: `calc(100% - ${TABLE_HEADER_HEIGHT + TABLE_ROW_HEIGHT + TABLE_LEGEND_HEIGHT}px)` }}
            >
                <Row className={'h-100 grid-container item-grid-200'}>
                    <Battery level={'100 ~ 80'} data={data[80]} IconText={'battery_full'} />
                    <Battery level={'60 ~ 79'} data={data[60]} IconText={'battery_5_bar'} />
                    <Battery level={'30 ~ 59'} data={data[30]} IconText={'battery_4_bar'} />
                    <Battery level={'10 ~ 29'} data={data[10]} IconText={'battery_2_bar'} />
                    <Battery level={'0 ~ 9'} data={data[0]} IconText={'battery_1_bar'} />
                    <Battery level={t('Unknown')} data={data['unknown']} IconText={'battery_unknown'} />
                </Row>
            </div>
            <div
                className={'w-100'}
                style={{ height: `${TABLE_HEADER_HEIGHT + TABLE_ROW_HEIGHT + TABLE_LEGEND_HEIGHT}px` }}
            >
                {t('Current / Count')}
                <div style={{ height: `${TABLE_HEADER_HEIGHT + TABLE_ROW_HEIGHT}px` }}>
                    <Table
                        data={{ rows: [data] }}
                        columns={tagBatteryColumn}
                        paging={false}
                        className="table-for-viewer"
                    />
                </div>
            </div>
            {children}
        </Card>
    );
};

export default TagBatteryStatus;
