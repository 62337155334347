import React, { Children, useContext } from 'react';
import ResizeDetector from 'react-resize-detector';
import cx from 'classnames';
import { Collapse } from 'reactstrap';
import { FilterSearchGroupStateContext } from '../index';
import { Icon } from '@components';

export const RESPONSIVE_FILTER_WIDTH = 540;
const FilterGroup = ({ label, children, className, labelIconName = 'tune' }) => {
    const state = useContext(FilterSearchGroupStateContext);
    const { filterOpen } = state;

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => {
                const isSmall = width < RESPONSIVE_FILTER_WIDTH;
                const childArray = Children.toArray(children);
                const lastChild = children && childArray.length > 1 && childArray.pop();
                return (
                    <div className="filter-box">
                        {label && (
                            <div className={cx('filter-label', className)}>
                                <Icon iconName={labelIconName} className={'md-18'} />
                                {label}
                            </div>
                        )}
                        {!isSmall ? (
                            childArray
                        ) : (
                            <Collapse className="w-100" isOpen={filterOpen}>
                                {childArray}
                            </Collapse>
                        )}
                        {lastChild}
                    </div>
                );
            }}
        />
    );
};

export default FilterGroup;
