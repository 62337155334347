import {
    getOutpatientWaitTime,
    postOutpatientWaitTimeExcel,
    postOutpatientWaitTimeListExcel,
} from '@api/so/outpatient';
import { useAsync, useTranslation } from '@hooks';
import React, { useRef } from 'react';
import InsightBox, { BoxBodyText, HighlightedText } from '../Components/InsightBox';
import SearchGroupSO from '../Components/SearchGroupSO';
import WidgetCard from '../../Components/WidgetCard';
import { useAsyncExcel } from '../../util/useAsyncExcel';
import useDepartmentOptions from '../util/useDepartmentOptions';
import useRangeDateSO from '../util/useRangeDateSO';
import Chart from './Chart';
import { isValidData } from '../util/checkData';
import NoDataBlock from '../../Components/NoDataBlock';
import { Trans } from 'react-i18next';
import moment from 'moment';
import useDateTypeOptions from '../util/useDateTypeOptions';
import { LoadingBlock } from '@components';

// 외래 대기시간 분석 (시간대별)
const OutpatientWaitAnalysisByTime = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();
    const t = useTranslation('OutpatientReport');

    const departmentValues = useDepartmentOptions();
    const dateTypeValues = useDateTypeOptions();
    const dateValues = useRangeDateSO(dateTypeValues.dateType);

    const { state, promise: getData } = useAsync({
        promise: getOutpatientWaitTime,
        immediate: true,
        param: {
            departmentCode: departmentValues.department?.toString(),
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
        },
        reject: err => console.error(err),
    });
    const data = state.response ? state.response.rows : [];
    const { departmentName, waitAvg, peakTime } = state.response ? state.response.additionalDatas : {};

    const triggerDownload = useAsyncExcel({
        promise: postOutpatientWaitTimeExcel,
        param: {
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
            departmentCode: departmentValues.department.toString(),
            columnMetas: [
                {
                    key: 'departmentName',
                    name: t('Department name'),
                },
                {
                    key: 'departmentCode',
                    name: t('Department code'),
                },
                {
                    key: 'wait0800',
                    name: `${t('Wait Time')} (08:00) (${t('Minute')})`,
                },
                {
                    key: 'wait0830',
                    name: `${t('Wait Time')} (08:30) (${t('Minute')})`,
                },
                {
                    key: 'wait0900',
                    name: `${t('Wait Time')} (09:00) (${t('Minute')})`,
                },
                {
                    key: 'wait0930',
                    name: `${t('Wait Time')} (09:30) (${t('Minute')})`,
                },
                {
                    key: 'wait1000',
                    name: `${t('Wait Time')} (10:00) (${t('Minute')})`,
                },
                {
                    key: 'wait1030',
                    name: `${t('Wait Time')} (10:30) (${t('Minute')})`,
                },
                {
                    key: 'wait1100',
                    name: `${t('Wait Time')} (11:00) (${t('Minute')})`,
                },
                {
                    key: 'wait1130',
                    name: `${t('Wait Time')} (11:30) (${t('Minute')})`,
                },
                {
                    key: 'wait1200',
                    name: `${t('Wait Time')} (12:00) (${t('Minute')})`,
                },
                {
                    key: 'wait1230',
                    name: `${t('Wait Time')} (12:30) (${t('Minute')})`,
                },
                {
                    key: 'wait1300',
                    name: `${t('Wait Time')} (13:00) (${t('Minute')})`,
                },
                {
                    key: 'wait1330',
                    name: `${t('Wait Time')} (13:30) (${t('Minute')})`,
                },
                {
                    key: 'wait1400',
                    name: `${t('Wait Time')} (14:00) (${t('Minute')})`,
                },
                {
                    key: 'wait1430',
                    name: `${t('Wait Time')} (14:30) (${t('Minute')})`,
                },
                {
                    key: 'wait1500',
                    name: `${t('Wait Time')} (15:00) (${t('Minute')})`,
                },
                {
                    key: 'wait1530',
                    name: `${t('Wait Time')} (15:30) (${t('Minute')})`,
                },
                {
                    key: 'wait1600',
                    name: `${t('Wait Time')} (16:00) (${t('Minute')})`,
                },
                {
                    key: 'wait1630',
                    name: `${t('Wait Time')} (16:30) (${t('Minute')})`,
                },
                {
                    key: 'wait1700',
                    name: `${t('Wait Time')} (17:00) (${t('Minute')})`,
                },
            ],
            fileName:
                'so_kioskData_' +
                moment.unix(dateValues.startDate).format('YYYYMMDD') +
                '_' +
                moment.unix(dateValues.endDate).format('YYYYMMDD'),
            zipFileName: 'so_wait_by_time',
        },
        reject: err => console.error(err),
    });

    const triggerPatientWaitingTimeDownload = useAsyncExcel({
        promise: postOutpatientWaitTimeListExcel,
        param: {
            departmentCode: departmentValues.department.toString(),
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
            columnMetas: [
                {
                    key: 'patientId',
                    name: t('Patient ID'),
                },
                {
                    key: 'patientName',
                    name: t('Patient Name'),
                },
                {
                    key: 'receiptNum',
                    name: t('Receipt Number'),
                },
                {
                    key: 'departmentCode',
                    name: t('Department code'),
                },
                {
                    key: 'departmentName',
                    name: t('Department name'),
                },
                {
                    key: 'doctorId',
                    name: t('Doctor Code'),
                },
                {
                    key: 'doctorName',
                    name: t('Doctor Name'),
                },
                {
                    key: 'firstArrivalDate',
                    name: t('First Arrival Time'),
                },
                {
                    key: 'firstCareStartDate',
                    name: t('First Care Start Time'),
                },
                {
                    key: 'firstTestStartDate',
                    name: t('First Test Start Time'),
                },
                {
                    key: 'firstCareEndDate',
                    name: t('First Care End Time'),
                },
                {
                    key: 'arrivalDate',
                    name: t('Arrival Time'),
                },
                {
                    key: 'careStartDate',
                    name: t('Care Start Time'),
                },
                {
                    key: 'testStartDate',
                    name: t('Test Start Time'),
                },
                {
                    key: 'careEndDate',
                    name: t('Care End Time'),
                },
                {
                    key: 'firstWaits',
                    name: t('First Wait Time'),
                },
                {
                    key: 'lastWaits',
                    name: t('Final Wait Time'),
                },
                {
                    key: 'kioskYn',
                    name: t('Whether to use a kiosk'),
                },
            ],
            fileName:
                'so_waitTimeData' +
                moment.unix(dateValues.startDate).format('YYYYMMDD') +
                '_' +
                moment.unix(dateValues.endDate).format('YYYYMMDD'),
            zipFileName: 'so_wait_by_patient',
        },
        reject: err => console.error(err),
    });

    const handleDownloadClick = () => {
        if (!isValidData(data)) {
            return;
        }

        triggerDownload().then(() => {
            triggerPatientWaitingTimeDownload();
        });
    };

    return (
        <WidgetCard widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
            <SearchGroupSO
                showDateType
                dateTypeValues={dateTypeValues}
                showDepartment
                departmentValues={departmentValues}
                dateValues={dateValues}
                getData={getData}
                showDownload={isValidData(data)}
                handleDownload={handleDownloadClick}
            />
            <LoadingBlock blocking={state.isLoading}>
                {isValidData(data) ? (
                    <>
                        <InsightBox>
                            <BoxBodyText>
                                <Trans
                                    t={t}
                                    values={{
                                        departmentName,
                                        waitAvg,
                                        peakTime,
                                    }}
                                    components={[<HighlightedText />]}
                                >
                                    {data.length >= 2
                                        ? 'During the inquiry period, the average waiting time for outpatient services in the selected departments was <0>{{waitAvg}}</0> minutes, with the peak waiting time occurring at <0>{{peakTime}}</0>.'
                                        : 'During the inquiry period, the average waiting time for outpatient services in <0>{{departmentName}}</0> was <0>{{waitAvg}}</0> minutes, with the peak waiting time occurring at <0>{{peakTime}}</0>.'}
                                </Trans>
                            </BoxBodyText>
                        </InsightBox>

                        <Chart data={data} />
                    </>
                ) : (
                    <NoDataBlock text={t('No data')} />
                )}
            </LoadingBlock>

            {children}
        </WidgetCard>
    );
};

export default OutpatientWaitAnalysisByTime;
