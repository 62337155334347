import React from 'react';
import CountUp from 'react-countup';
import { Col } from 'reactstrap';
import useTranslation from '@hooks/useTranslation';
import cx from 'classnames';
import { Icon } from '@components';

export const VIEW_MODE = 'view';

/**
 * 센서 현황 IoT 아이템, 센서, 상태 및 카운트 숫자를 나타내주는 컴포넌트
 *
 * @param start // 카운트 시작 할 숫자
 * @param end // 카운트 끝나는 숫자
 * @param currentStatus // 현재 선택된 상태
 * @param handleCountClick // 카운트 label 클릭시 발생하는 클릭이벤트
 * @param label //  각 카운트의 명칭
 * @param status // 각 카운트의 상태값
 * @param mode // 클릭 이벤트 유무
 *
 * @author jinbeom-jung
 * */
const ItemCount = ({ start = 0, end = 0, currentStatus, handleCountClick, label, status, mode = 'dynamic' }) => {
    const t = useTranslation('SensorStatus');
    const handleClick = () => {
        return VIEW_MODE !== mode && handleCountClick(status);
    };

    return (
        <Col
            className={mode === VIEW_MODE ? 'view' : currentStatus === label ? 'selected' : 'nonSelected'}
            onClick={handleClick}
        >
            <CountUp
                className="item-num"
                decimals={0}
                start={start}
                end={end}
                decimal=","
                duration="1"
                useEasing={false}
                separator=""
                prefix=""
                suffix=""
            />
            {mode === VIEW_MODE ? (
                <div className="flx-row">{t(label)}</div>
            ) : (
                <div className={cx('flx-row', currentStatus === label && '')}>
                    {t(label)}
                    {currentStatus === label ? (
                        <Icon iconName={'arrow_drop_down'} />
                    ) : (
                        <Icon iconName={'arrow_right'} />
                    )}
                </div>
            )}
        </Col>
    );
};

export default ItemCount;
