import React, { useState } from 'react';
import { Button, Modal, Table, TextInput } from '@components';
import { ModalFooter } from 'reactstrap';
import { useAsync, useColumns, useTranslation } from '@hooks';
import { getDeviceListApi, getScannerListApi } from '@api/sh/deviceManagement';
import * as col from '../MappingModal/columns';
import { MODEL_CODE } from '@util/staticData/sensorMeta';
import { DeviceInfo, DeviceType } from './DeviceInfoModal';
import { SENSOR } from './constants';

type SensorInfo = {
    sensorName: string;
    deviceNum: number;
};

type ScannerInfo = {
    scannerName: string;
    scannerNum: number;
};

interface Props {
    isOpen: boolean;
    toggleModal: () => void;
    searchType: DeviceType;
    handleAddDevice: (info: DeviceInfo, deviceType: DeviceType) => void;
}

const DeviceSearchModal = ({ isOpen, toggleModal, searchType, handleAddDevice }: Props) => {
    const t = useTranslation('DeviceMappingManagement');
    const [keyword, setKeyword] = useState('');

    const handleSearchClick = () => {
        // 텍스트 기준 목록 조회
        getDeviceList({ keyword });
    };

    const handleResetKeyword = () => {
        setKeyword('');
    };

    // 매핑 가능 기기 목록 조회 API
    const { promise: getDeviceList, state: deviceList } = useAsync({
        promise: searchType === SENSOR ? getDeviceListApi : getScannerListApi,
        fixedParam: {
            opt: searchType === SENSOR ? 'sensorName' : 'scannerName',
            modelCodeList: [MODEL_CODE.MEZOO_SMARTPATCH],
            pageSize: 5,
        },
        immediate: isOpen, // 초기 렌더링 시 API 호출 방지(모달을 열었을 때만 실행시키기 위함)
        deps: [isOpen],
    });

    const mappingColumns = useColumns(
        [
            searchType === SENSOR ? col.sensorName() : col.scannerName(),
            col.mapping({
                Cell: ({ row: { original } }: { row: { original: SensorInfo | ScannerInfo } }) => {
                    const param =
                        searchType === SENSOR
                            ? {
                                  deviceNum: (original as SensorInfo).deviceNum,
                                  deviceName: (original as SensorInfo).sensorName,
                              }
                            : {
                                  deviceNum: (original as ScannerInfo).scannerNum,
                                  deviceName: (original as ScannerInfo).scannerName,
                              };

                    return (
                        <Button
                            className="btn-secondary"
                            onClick={() => {
                                handleAddDevice(param, searchType);
                                toggleModal();
                                handleResetKeyword();
                            }}
                        >
                            {t('Select')}
                        </Button>
                    );
                },
            }),
        ],
        t,
        [searchType],
    );

    const deviceListResponse = deviceList?.response || { rows: [], page: 1 };

    return (
        <>
            <Modal
                initModal={isOpen}
                toggleModal={toggleModal}
                removeModalClose={false}
                headerTitle={t('Search device')}
                bodyText={
                    <div className={'d-flex flex-column gap-3'}>
                        <div className={'pnt-txt txt-dot'}>
                            {searchType === SENSOR
                                ? t('Select the name of the label on your device or search for it.')
                                : t('Select the name of the label on your scanner or search for it.')}
                        </div>
                        <div className={'w-100 d-flex'}>
                            <TextInput
                                inputGroupClassName={'w-100'}
                                placeholder={
                                    searchType === SENSOR
                                        ? t('Please enter the device name.')
                                        : t('Please enter the scanner name.')
                                }
                                value={keyword}
                                handleChange={e => {
                                    setKeyword(e.target.value);
                                }}
                            />
                            <Button className={'btn-secondary'} onClick={handleSearchClick}>
                                {t('Search', 'Search')}
                            </Button>
                        </div>
                        <div style={{ height: '180px' }}>
                            <Table
                                columns={mappingColumns}
                                data={{
                                    ...deviceListResponse,
                                    pageSize: 5,
                                }}
                                onPageChange={page => {
                                    getDeviceList({ page });
                                }}
                            />
                        </div>
                    </div>
                }
                modalFooter={
                    <ModalFooter className={'d-flex justify-content-end'}>
                        <Button
                            className={'btn-secondary'}
                            onClick={() => {
                                handleResetKeyword();
                                toggleModal();
                            }}
                        >
                            {t('Close', 'Button')}
                        </Button>
                    </ModalFooter>
                }
            />
        </>
    );
};

export default DeviceSearchModal;
