import React, { useState } from 'react';
import { DATE_TYPE } from '../../constants';
import { DatePicker } from '@components';
import CalendarContainer from './CalendarContainer';
import moment from 'moment';

const DATEPICKER_FORMAT = {
    [DATE_TYPE.DAILY]: 'yyyy-MM-dd',
    [DATE_TYPE.WEEKLY]: 'yyyy-MM-dd',
    [DATE_TYPE.MONTHLY]: 'yyyy-MM',
    [DATE_TYPE.YEARLY]: 'yyyy',
};

const RangeDatePickerSo = ({
    dateType = DATE_TYPE.DAILY, // daily, weekly, monthly, yearly
    startDate,
    handleStartChange,
    endDate,
    handleEndChange,
    handleDateTypeChange,
}) => {
    const [isStartDateOpen, setIsStartDateOpen] = useState(false);
    const [isEndDateOpen, setIsEndDateOpen] = useState(false);

    const handleStartToggle = () => {
        setIsStartDateOpen(prev => !prev);
        setIsEndDateOpen(false);
    };
    const handleEndToggle = () => {
        setIsEndDateOpen(prev => !prev);
        setIsStartDateOpen(false);
    };

    const handleSave = openType => (startDate, endDate) => {
        handleStartChange(moment.unix(startDate));
        handleEndChange(moment.unix(endDate));

        if (openType === 'start') {
            setIsStartDateOpen(false);
            return;
        }

        setIsEndDateOpen(false);
    };

    return (
        <>
            <DatePicker
                handleChange={() => null}
                value={startDate}
                valueType="s"
                open={isStartDateOpen}
                dateFormat={DATEPICKER_FORMAT?.[dateType] || DATEPICKER_FORMAT.DAILY}
                onClickOutside={handleStartToggle}
                onInputClick={handleStartToggle}
                calendarContainer={() => (
                    <CalendarContainer
                        start={startDate}
                        end={endDate}
                        dateType={dateType}
                        handleDateType={handleDateTypeChange}
                        handleSave={handleSave('start')}
                    />
                )}
                shouldCloseOnSelect={false}
            />

            <span className={'mx-1 font-weight-bold'}>~</span>

            <DatePicker
                handleChange={() => null}
                value={endDate}
                valueType="s"
                open={isEndDateOpen}
                dateFormat={DATEPICKER_FORMAT?.[dateType] || DATEPICKER_FORMAT.DAILY}
                onClickOutside={handleEndToggle}
                onInputClick={handleEndToggle}
                calendarContainer={() => (
                    <CalendarContainer
                        start={startDate}
                        end={endDate}
                        dateType={dateType}
                        handleDateType={handleDateTypeChange}
                        handleSave={handleSave('end')}
                    />
                )}
                shouldCloseOnSelect={false}
            />
        </>
    );
};

export default RangeDatePickerSo;
