import React from 'react';
import styled, { css } from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { initUUIDv4 } from '@util/common/util';
import cx from 'classnames';

const DailyTable = ({ columns, rows, maxHeight }) => {
    const t = useTranslation('RefrigeratorSensor');
    const tbodyRef = React.useRef();

    return (
        <>
            <StyledTable $maxHeight={maxHeight}>
                <thead className="bg-depth-7">
                    <tr className="border-bottom border-depth-8">
                        {columns.map((item, idx) => {
                            return (
                                <StyledTh
                                    key={idx}
                                    $width={item.width}
                                    className={cx(idx !== 0 && 'border-start border-depth-8')}
                                >
                                    <span>{item.header ? t(item.header) : ''}</span>
                                </StyledTh>
                            );
                        })}
                    </tr>
                </thead>
                <PerfectScrollbar ref={tbodyRef} component={'tbody'}>
                    {rows.map((rowItem, rowIdx) => {
                        return (
                            <tr
                                key={`${initUUIDv4()}_${rowIdx}`}
                                className={cx(rowIdx < rows.length - 1 && 'border-bottom border-depth-8')}
                            >
                                {columns.map((columnItem, colIdx) => {
                                    const component =
                                        typeof rowItem[columnItem.accessor] === 'function'
                                            ? rowItem[columnItem.accessor](rowIdx)
                                            : rowItem[columnItem.accessor];
                                    return (
                                        <StyledTd
                                            key={colIdx}
                                            className={cx(colIdx !== 0 && 'border-start border-depth-8')}
                                            $width={columnItem.width}
                                            $temperatureWarning={
                                                (columnItem.accessor === 'humidity' && rowItem.outOfControlHumidity) ||
                                                (columnItem.accessor === 'temperature' &&
                                                    rowItem.outOfControlTemperature)
                                            }
                                        >
                                            {component}
                                        </StyledTd>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </PerfectScrollbar>
            </StyledTable>
            <StyledTable>
                <thead className="bg-depth-7">
                    <tr className="border-bottom border-depth-8">
                        <StyledTh $width={100}>{t('Remarks')}</StyledTh>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <StyledTd $width={100}>{rows[0].contents}</StyledTd>
                    </tr>
                </tbody>
            </StyledTable>
        </>
    );
};

const StyledTable = styled.table`
    width: 100%;

    thead {
        position: sticky;
        top: 0;

        tr {
            border-bottom-color: white;
        }
    }

    tbody {
        display: block;
        td:nth-child(4) > input {
            text-align: left !important;
            text-overflow: ellipsis;
        }
        td > div:last-child:hover {
            background-color: rgba(155, 155, 155, 0.4) !important;
        }
    }

    tr {
        display: flex;
        align-content: center;

        &:last-child {
            border-bottom: none;
        }
    }

    th,
    td {
        min-height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        &:first-child {
            border-left: none;
        }
    }
`;
const StyledTh = styled.th`
    flex: ${props => (props.$width ? (1 * props.$width) / 100 : 1)};
`;
const StyledTd = styled.td`
    flex: ${props => (props.$width ? (1 * props.$width) / 100 : 1)};
    & > input {
        ${props =>
            props.$temperatureWarning &&
            css`
                background-color: #ffce2e !important;
            `}
    }
`;

export default DailyTable;
