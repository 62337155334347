import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import { deleteMenuApi, fetchMenuList, MenuInfo } from '@api/common/menu';
import { useAppSelector, useAsync, useConfirm, useTranslation } from '@hooks';
import { A_PATH_MENU } from '../../../Components/Router/path';
import { useDispatch } from 'react-redux';
import { setMenuList } from '@reducer/CustomMenu';
import { Icon } from '@components';
import { ConfirmModal } from '@components';

interface Props {
    row: {
        original: MenuInfo;
    };
    getFilterMenu: React.Dispatch<React.SetStateAction<MenuInfo[]>>;
}

const MenuListSubMenu = ({ row: { original }, getFilterMenu }: Props) => {
    const dispatch = useDispatch();
    const t = useTranslation('MenuManagement');
    const { userInfo } = useAppSelector(state => state.UserInfo as Record<string, any>);

    const { promise: getMenuList } = useAsync({
        promise: fetchMenuList,
        fixedParam: { isAll: 'Y', includeScreenInfo: 'Y', groupNum: userInfo.authGroupNum },
        resolve: res => {
            dispatch(setMenuList(res.rows ?? []));
            getFilterMenu(res.rows);
        },
        reject: err => {
            console.log({ err });
        },
    });

    const { promise: deleteMenu } = useAsync({
        promise: deleteMenuApi,
        resolve: () => {
            deleteSuccessProps.toggleModal();
        },
        reject: () => {
            deleteFailedModalProps.toggleModal();
        },
    });

    const deleteModalProps = useConfirm({
        header: { title: t('Confirm', 'ConfirmModal') },
        confirmText:
            original.screenList && original.screenList.length
                ? t(
                      'There are screens mapped to this menu. You can delete the mapped screen after moving it to another menu.',
                  )
                : t('Do you want to delete the menu?'),
        removeCancel: original.screenList && original.screenList.length,
        callbackParam: original,
        okCallback: () => {
            if (!(original.screenList && original.screenList.length)) {
                deleteMenu(original);
            }
        },
    });

    const deleteSuccessProps = useConfirm({
        confirmText: t('Menu deletion is complete.'),
        removeCancel: true,
        okCallback: () => {
            getMenuList();
        },
    });

    const deleteFailedModalProps = useConfirm({
        confirmText: t('The delete request failed.'),
        removeCancel: true,
    });

    return (
        <>
            <UncontrolledDropdown className="w-100">
                <DropdownToggle tag={'div'} className={'flex-center cursor-pointer'}>
                    <Icon iconName={'menu'} />
                </DropdownToggle>
                <DropdownMenu className={'grid-sub-menu'}>
                    <Link to={`${A_PATH_MENU}/edit/${original.menuCode}`}>
                        <DropdownItem>
                            <Icon iconName={'info_outline'} className={'md-18'} />
                            <span className="ml-2">{t('Menu Detail')}</span>
                        </DropdownItem>
                    </Link>
                    <DropdownItem onClick={deleteModalProps.toggleModal}>
                        <Icon iconName={'delete_outline'} className={'md-18'} />
                        <span className="ml-2">{t('Delete the menu')}</span>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            <ConfirmModal {...deleteModalProps} />
            <ConfirmModal {...deleteSuccessProps} />
            <ConfirmModal {...deleteFailedModalProps} />
        </>
    );
};

export default MenuListSubMenu;
