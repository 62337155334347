import React, { useMemo, useState } from 'react';
import { Button, ConfirmModal, Modal, Select, Table } from '@components';
import { ModalFooter } from 'reactstrap';
import { useAsync, useColumns, useConfirm, useTranslation } from '@hooks';
import { deleteDeviceMappingApi, getTargetInfoApi, postTargetDeviceMappingApi } from '@api/sh/deviceManagement';
import * as col from '../../columns';
import { MODEL_CODE } from '@util/staticData/sensorMeta';
import DeviceSearchModal from './DeviceSearchModal';
import { Nullable, NullableNumber } from '@util/type/util';
import { SingleValue } from 'react-select';
import useGroupInfoList from '../../../BiometricInformationMonitoring/Components/MappingModal/hooks/useGroupInfoList';
import useDeviceInfoList from '../../../BiometricInformationMonitoring/Components/MappingModal/hooks/useDeviceInfoList';
import { useDeviceMappingManagementStateContext } from '../../slice';

export type DeviceType = 'SENSOR' | 'SCANNER' | 'BEACON';

export type DeviceInfo = {
    deviceNum: number;
    deviceName: string;
    deviceType?: DeviceType;
    realMapping?: boolean;
};

interface SelectedDeleteInfo {
    deviceType: Nullable<DeviceType>;
    deviceNum: NullableNumber;
}

interface Props {
    isOpen: boolean;
    toggleModal: () => void;
    closeCallback: () => void;
}

const filteredByType = (list: DeviceInfo[], deviceType: DeviceType) => {
    if (!list) {
        return [];
    }
    return list.filter(item => item.deviceType === deviceType);
};

export const SENSOR = 'SENSOR';
export const SCANNER = 'SCANNER';

const DeviceInfoModal = ({ isOpen, closeCallback, toggleModal }: Props) => {
    const t = useTranslation('DeviceMappingManagement');
    const {
        targetInfo: { targetNum, sensorNum },
    } = useDeviceMappingManagementStateContext();
    const [showDeviceSearchModal, setShowDeviceSearchModal] = useState(false);
    const [selectedDeleteInfo, setSelectedDeleteInfo] = useState<SelectedDeleteInfo>({
        deviceType: null,
        deviceNum: null,
    });
    const [searchType, setSearchType] = useState<DeviceType>(SENSOR);
    const { groupInfoList, groupListOption, selectedGroup, handleGroupChange, handleGroupReset } = useGroupInfoList();
    const {
        deviceList,
        handleAddDevice,
        handleInitDevice,
        handleRemoveDevice,
        handleResetDevice,
    } = useDeviceInfoList();

    const mappingSuccessModalProps = useConfirm({
        initModal: false,
        confirmText: t('The mapping is complete.'),
        removeCancel: true,
        okCallback: () => {
            getTargetInfo();
        },
    });

    const [error, setError] = useState({
        code: '',
        message: '',
    });
    const mappingFailedModalProps = useConfirm({
        initModal: false,
        confirmText: (
            <div>
                <div className={'mb-1'}>{t('The mapping failed, please try again in a moment.')}</div>
                {(error?.message || error?.code) && (
                    <details>
                        <summary>
                            {t('Error Code')}: {error.code}
                        </summary>
                        <div>{error.message}</div>
                    </details>
                )}
            </div>
        ),
        removeCancel: true,
    });

    const unMappingModalProps = useConfirm({
        initModal: false,
        confirmText: (
            <div>
                <div>{t('Do you want to unmap?')}</div>
                <div>{t('If you unmap, you will no longer be able to monitor biometrics.')}</div>
            </div>
        ),
        okCallback: () => {
            const { deviceType, deviceNum } = selectedDeleteInfo;
            const param =
                deviceType === SENSOR
                    ? { sensorNumList: deviceNum }
                    : {
                          scannerNum: deviceNum,
                      };
            deleteDeviceMapping({ targetNum, ...param, deviceMappingTargetGroupNum: selectedGroup.groupNum });
        },
    });

    const confirmUnmappingModalProps = useConfirm({
        initModal: false,
        confirmText: (
            <div>
                <div>{t('The mapping is unmapped.')}</div>
                <div>{t('To remap, search for your device and select the device you want to map.')}</div>
            </div>
        ),
        removeCancel: true,
        okCallback: () => {
            // 상세정보 리프레쉬
            getTargetInfo();
        },
    });

    // 대상 정보 상세 조회
    const { promise: getTargetInfo, state: targetInfo } = useAsync({
        promise: getTargetInfoApi,
        fixedParam: {
            modelCodeList: [MODEL_CODE.MEZOO_SMARTPATCH],
            targetNum,
            sensorNum: sensorNum ?? undefined,
        },
        resolve: res => {
            // 기기정보가 있을 경우 해당 기기의 groupNum을 set해줌
            if (Array.isArray(res?.targetDevice)) {
                const groupNum = res.targetDevice[0]?.groupNum;
                if (groupNum) {
                    handleGroupChange({ groupNum });
                }
                const sensor = filteredByType(res.targetDevice, SENSOR).map(item => ({ ...item, realMapping: true }));
                const scanner = filteredByType(res.targetDevice, SCANNER).map(item => ({ ...item, realMapping: true }));
                handleInitDevice({ sensor, scanner });
            }
        },
        keepState: true,
        immediate: isOpen, // 초기 렌더링 시 API 호출 방지(모달을 열었을 때만 실행시키기 위함)
        deps: [isOpen],
    });

    // 매핑 API
    const { promise: postDeviceMapping } = useAsync({
        promise: postTargetDeviceMappingApi,
        resolve: () => {
            mappingSuccessModalProps.toggleModal();
        },
        reject: error => {
            if (error?.data) {
                const { code, message } = error.data;
                setError({ code, message });
            }
            mappingFailedModalProps.toggleModal();
        },
    });

    // 언매핑 API
    const { promise: deleteDeviceMapping } = useAsync({
        promise: deleteDeviceMappingApi,
        resolve: () => {
            confirmUnmappingModalProps.toggleModal();
            handleResetDevice();
            handleGroupReset();
        },
    });

    const deviceColumns = useColumns(
        [
            col.deviceName(),
            col.actions({
                Cell: ({
                    row: { original },
                }: {
                    row: { original: { deviceType: DeviceType; deviceNum: number; realMapping?: boolean } };
                }) => {
                    const { deviceType, deviceNum, realMapping } = original;
                    return (
                        <Button
                            className="btn-icon-only btn-trans br-5 icon-big"
                            iconName={'link_off'}
                            onClick={() => {
                                if (realMapping) {
                                    setSelectedDeleteInfo({ deviceType, deviceNum });
                                    unMappingModalProps.toggleModal();
                                } else {
                                    handleRemoveDevice(deviceType);
                                }
                            }}
                        />
                    );
                },
            }),
        ],
        t,
        [selectedGroup],
    );

    const targetDevice = useMemo(() => {
        return targetInfo?.response?.targetDevice;
    }, [targetInfo.response]);

    const { wardName, room, bed, targetName } = targetInfo?.response || {
        wardName: null,
        room: null,
        bed: null,
        targetName: null,
    };

    const hasSensor = filteredByType(targetDevice, SENSOR).length > 0;
    const hasScanner = filteredByType(targetDevice, SCANNER).length > 0;

    // 만약 이미 센서랑스캐너가 전부 매핑되어있다면 disabled
    const hasSensors = hasSensor && hasScanner;
    // 자동매핑이 Y인데 sensor가 없다면 disabled
    const autoMappingGroup = selectedGroup.useScannerAutoMapping === 'Y' && deviceList.SENSOR.length === 0;
    // 자동매핑이 N인데 sensor나 scanner가 하나라도 없을 경우 disabled
    const noAutoMappingGroup =
        selectedGroup.useScannerAutoMapping === 'N' &&
        (deviceList.SENSOR.length === 0 || deviceList.SCANNER.length === 0);

    const isMappingDisabled = hasSensors || autoMappingGroup || noAutoMappingGroup;

    // 스캐너 자동매핑여부 = Y ? 센서만 선택 후 매핑
    // 스캐너 자동매핑여부 = N ? 센서/스캐너 두개 다 선택 후 매핑
    const handleMappingClick = () => {
        const newSensor = deviceList.SENSOR[0]?.deviceNum;
        const newScanner = deviceList.SCANNER[0]?.deviceNum;
        const targetInfo = {
            targetNum,
            ...(selectedGroup.groupNum && { deviceMappingTargetGroupNum: selectedGroup?.groupNum }),
        };

        // 센서와 스캐너가 모두 없는 경우
        if (!hasSensor && !hasScanner) {
            postDeviceMapping({
                ...targetInfo,
                ...(newSensor && { sensorNum: [newSensor] }),
                ...(newScanner && { scannerNum: newScanner }),
            });
            return;
        }

        // 센서가 있고, 스캐너가 없는 경우
        if (hasSensor && !hasScanner && newScanner) {
            postDeviceMapping({ ...targetInfo, scannerNum: newScanner });
            return;
        }
    };

    return (
        <>
            <Modal
                initModal={isOpen}
                toggleModal={toggleModal}
                removeModalClose={false}
                headerTitle={<div>{t('Biometric monitoring device info')}</div>}
                bodyText={
                    <div className={'h-100'}>
                        <div className={'d-flex flex-column gap-1 mb-3'}>
                            <div className={'pnt-txt txt-dot txt-bold s-6'}>{t('Patient information')}</div>
                            <div className={'d-flex ml-2 pl-1'}>
                                <span className={'w-30'}>{t('Ward/Room/Bed')}</span>
                                <span>
                                    {wardName || '-'} / {room || '-'} / {bed || '-'}
                                </span>
                            </div>
                            <div className={'d-flex ml-2 pl-1'}>
                                <span className={'w-30'}>{t('Patient name')}</span>
                                <span>{targetName || '-'}</span>
                            </div>
                        </div>
                        {/* 내가 속한 부서가 2개 이상일 경우에만 노출 */}
                        {groupInfoList.length >= 2 && (
                            <div className={'d-flex flex-column gap-2 mb-2'}>
                                <div className={'d-flex justify-content-between'}>
                                    <div className={'pnt-txt txt-dot txt-bold s-6'}>
                                        {t('Patient Mapping Department')}
                                    </div>
                                    <div>
                                        <Select
                                            key={'select_' + targetNum + '_' + selectedGroup.groupNum || 'key'}
                                            isModalSelect
                                            customContainerStyles={{ width: '200px' }}
                                            value={groupListOption.find(item => item.value === selectedGroup?.groupNum)}
                                            options={groupListOption}
                                            onChange={(option: SingleValue<any>) => {
                                                if (option) {
                                                    handleGroupChange({
                                                        groupName: option.label,
                                                        groupNum: option.value,
                                                        useScannerAutoMapping: option.useScannerAutoMapping,
                                                    });
                                                }
                                            }}
                                            disabled={!!deviceList.SENSOR.length}
                                        />
                                    </div>
                                </div>
                                <small className={'d-flex justify-content-end pnt-txt txt-bold'}>
                                    * {t('Please select the department to mapp (monitor) the patient.')}
                                </small>
                            </div>
                        )}
                        <div className={'d-flex justify-content-between mb-2'}>
                            <div className={'pnt-txt txt-dot txt-bold s-6'}>{t('Device information')}</div>
                            <Button
                                className={'btn-secondary'}
                                onClick={() => {
                                    setSearchType(SENSOR);
                                    setShowDeviceSearchModal(true);
                                }}
                                disabled={!!deviceList.SENSOR.length || !selectedGroup.groupNum}
                            >
                                {t('Search device')}
                            </Button>
                        </div>
                        <div className={'mb-2'} style={{ height: '80px' }}>
                            <Table columns={deviceColumns} data={{ rows: deviceList.SENSOR }} paging={false} />
                        </div>
                        <div className={'d-flex justify-content-between mb-2'}>
                            <div className={'pnt-txt txt-dot txt-bold s-6'}>{t('Scanner information')}</div>
                            <Button
                                className={'btn-secondary'}
                                onClick={() => {
                                    setSearchType(SCANNER);
                                    setShowDeviceSearchModal(true);
                                }}
                                disabled={
                                    !deviceList.SENSOR.length ||
                                    !!deviceList.SCANNER.length ||
                                    selectedGroup?.useScannerAutoMapping === 'Y'
                                }
                            >
                                {t('Search scanner')}
                            </Button>
                        </div>
                        <div className={'mb-2'} style={{ height: '80px' }}>
                            <Table columns={deviceColumns} data={{ rows: deviceList.SCANNER }} paging={false} />
                        </div>
                        <Button
                            className={'btn-secondary w-100 my-3'}
                            disabled={isMappingDisabled}
                            onClick={handleMappingClick}
                        >
                            {t('Map devices')}
                        </Button>
                    </div>
                }
                modalFooter={
                    <ModalFooter className={'d-flex justify-content-end'}>
                        <Button
                            className={'btn-gray'}
                            onClick={() => {
                                closeCallback();
                                toggleModal();
                            }}
                        >
                            {t('Close', 'Button')}
                        </Button>
                    </ModalFooter>
                }
            />
            <ConfirmModal {...mappingSuccessModalProps} />
            <ConfirmModal {...mappingFailedModalProps} />
            <ConfirmModal {...unMappingModalProps} />
            <ConfirmModal {...confirmUnmappingModalProps} />
            <DeviceSearchModal
                isOpen={showDeviceSearchModal}
                searchType={searchType}
                handleAddDevice={handleAddDevice}
                toggleModal={() => {
                    setShowDeviceSearchModal(prev => !prev);
                }}
            />
        </>
    );
};

export default DeviceInfoModal;
