import React from 'react';
import moment from 'moment';
import useTranslation from '@hooks/useTranslation';
import { selectCurrentIVInjection } from '../../../IVInjectionMonitoringSlice';
import { useIVInjectionMonitoringContext, useIVInjectionMonitoringDispatch } from '../../../index';
import UnmapButton from './UnmapButton';
import cx from 'classnames';
import { Icon } from '@components';

const SENSING_TYPE = {
    CAPACITY: 'CAPACITY',
    BATTERY: 'BATTERY',
    DROP_RATE: 'DROP_RATE',
};
const DANGER_DROP_RATE = {
    1: 'FULL', // 1: 수액 방울 최대 (Full Drop)
    2: 'STOP', // 2: 수액 방울 멈춤 (Stop Drop)
};
const SENSING_STATUS = {
    CRITICAL: 'CRITICAL',
    NORMAL: 'Normal',
};
// 초기 센싱 값
const INIT_SENSOR_VALUE = 18000;
// Lost Signal On State
const LOST_SIGNAL_ON_STATE = ['LOSTSIGNAL_ON', 'LOSTSIGNAL_KEEP_ON'];

// 배터리 잔량 아이콘 iconName
const getBatteryIconClassName = batteryValue => {
    if (!batteryValue) {
        return 'battery_unknown';
    } else if (batteryValue <= 0) {
        return 'battery_0_bar';
    } else if (batteryValue > 0 && batteryValue <= 15) {
        return 'battery_1_bar';
    } else if (batteryValue > 15 && batteryValue <= 30) {
        return 'battery_2_bar';
    } else if (batteryValue > 30 && batteryValue <= 45) {
        return 'battery_3_bar';
    } else if (batteryValue > 45 && batteryValue <= 60) {
        return 'battery_4_bar';
    } else if (batteryValue > 60 && batteryValue <= 75) {
        return 'battery_5_bar';
    } else if (batteryValue > 75 && batteryValue <= 99) {
        return 'battery_6_bar';
    } else {
        return 'battery_full';
    }
};

export const calcCurrentRemainingValue = (totalValue, currentValue) => {
    if (isNaN(currentValue)) {
        return 0;
    }
    return totalValue - currentValue > 0 ? Math.floor(totalValue - currentValue) : 0;
};

const IVInjectionInfo = ({ targetInfo, IVInjection, sensor, handleCallbackApi }) => {
    const t = useTranslation('IVInjectionMonitoring');
    const dispatch = useIVInjectionMonitoringDispatch();
    const { remainingValueDisplay } = useIVInjectionMonitoringContext();

    // 수액 센서 고유번호
    const IVInjectionNum = IVInjection.ivInjectionNum;

    // 수액 누적 투여량 센서
    const sensorCapacity = sensor.sensorItems.find(sensorItem => sensorItem.sensingType === SENSING_TYPE.CAPACITY);
    // 배터리 센서
    const sensorBattery = sensor.sensorItems.find(sensorItem => sensorItem.sensingType === SENSING_TYPE.BATTERY);
    // 수액 초당 투여량 센서
    const sensorDropRate = sensor.sensorItems.find(sensorItem => sensorItem.sensingType === SENSING_TYPE.DROP_RATE);
    // Full/Stop 및 현재 주입량
    const currentInjectionValue = (value => {
        if (!value) {
            return 0;
        }
        // 초당 투여량(DropRate)에 따른 Full/Stop
        if (!!DANGER_DROP_RATE[value]) {
            return DANGER_DROP_RATE[value];
        } else {
            return IVInjection.currentVal > 0 ? Math.round(IVInjection.currentVal) : 0;
        }
    })(sensorDropRate?.currentValue);
    // 남은 수액량 남은 수액량 0 이하의 경우 fail
    const currentRemainingIVValue = calcCurrentRemainingValue(IVInjection.totalVal, sensorCapacity?.currentValue);

    // 배터리 잔량
    const remainingBattery = sensorBattery?.currentValue;
    // 센서 연결 여부 (현재시간 - 마지막 센서 수신 시간)
    const failSensorConnection = LOST_SIGNAL_ON_STATE.includes(IVInjection.lostSignalState);
    // 최저 제한 속도 (default)
    const minLimitSpeed =
        IVInjection.minLimitSpeed > 0
            ? IVInjection.minLimitSpeed
            : Math.ceil(IVInjection.injectionVal - IVInjection.injectionVal / 2);
    // 최고 제한 속도 (default)
    const maxLimitSpeed =
        IVInjection.maxLimitSpeed > 0
            ? IVInjection.maxLimitSpeed
            : Math.floor(IVInjection.injectionVal + IVInjection.injectionVal / 2);
    // 현재투여량 최고/최저 제한 속도 이탈 여부
    const normalDropSpeed =
        !DANGER_DROP_RATE[sensorDropRate?.currentValue] && // FULL or STOP
        minLimitSpeed <= currentInjectionValue && // 최소 주입량
        maxLimitSpeed >= currentInjectionValue; // 최대 주입량

    // 수액 센서 관련 상태 메세지
    const statusMessage = (() => {
        if (IVInjection.endDate === 0 || !remainingBattery || remainingBattery <= 0) {
            return 'Check Power';
        }
        if (failSensorConnection) {
            return 'Lost Signal(Reconnect)';
        }
        if (!normalDropSpeed) {
            return 'Weird Speed';
        }
        if (IVInjection.currentVal === INIT_SENSOR_VALUE) {
            return 'Reading';
        }
        return SENSING_STATUS.NORMAL;
    })();

    // 수액 상세 정보 event
    const handleIVInjectionClick = () => {
        dispatch(selectCurrentIVInjection({ IVInjectionNum, targetInfo, sensorItems: sensor.sensorItems }));
    };

    // 언맵핑을 위한 센서정보 객체 생성
    const unmapIVSensorInfo = {
        ...IVInjection,
        sensorId: sensor.deviceId,
        targetId: targetInfo.targetId,
    };

    // 센싱 상태 '정상' 여부
    const normalSensingStatus = statusMessage === SENSING_STATUS.NORMAL;

    return (
        <div
            onClick={handleIVInjectionClick}
            className="IV-info transition-card border-start border-end border-depth-7"
        >
            {/* 약품명 / 북마크 */}
            <div className="flx-row border-bottom border-depth-7">
                <div className="pnt-txt flx-1">{IVInjection.medicineName}</div>
                <div className="border-depth-7 p-0">
                    <UnmapButton IVInjection={unmapIVSensorInfo} handleCallbackApi={handleCallbackApi} />
                </div>
            </div>
            {/* 현재주입량(오더량) / 상태메세지 / 배터리 */}
            <div className="flx-row border-bottom border-depth-7">
                {/* 현재주입량(오더량) */}
                <div className="flx-1">
                    <span className={cx('pnt-txt txt-bold s-5', !normalDropSpeed && 'color-danger')}>
                        {currentInjectionValue}({IVInjection.injectionVal})
                    </span>
                    <span className="ml-1 color-depth-9">cc/hr</span>
                </div>
                {/* 상태메세지 */}
                <div className="border-depth-7">
                    <span className={cx('pnt-txt', normalSensingStatus ? 'color-depth-9' : 'color-danger')}>
                        {t(statusMessage)}
                    </span>
                </div>
                {/* 배터리 */}
                <div className="border-depth-7 px-1">
                    <Icon iconName={getBatteryIconClassName(remainingBattery)} className={'md-18'} />
                </div>
            </div>
            {/* 남은 수액량 / 종료시간 */}
            {remainingValueDisplay && (
                <div className="flx-row bg-depth-5 border-bottom border-depth-7">
                    {/* 남은 수액량 */}
                    <div className="flx-1">
                        <span className="fw-bolder">{currentRemainingIVValue}</span>
                        <span className="ml-2 color-depth-9">{sensorCapacity?.measurementUnit}</span>
                    </div>
                    {/* 종료시간 */}
                    <div className="pnt-txt border-depth-7 w-auto">
                        {IVInjection.endDate ? moment.unix(IVInjection.endDate).format('YY-MM-DD / HH:mm:ss') : '- / -'}
                    </div>
                </div>
            )}
        </div>
    );
};

export default IVInjectionInfo;
