import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ConfirmModal, Label, Modal, SearchableSelect, Select, TextInput } from '@components';
import { ModalBody, ModalFooter } from 'reactstrap';
import { YN } from '@util/type/util';
import { Trans } from 'react-i18next';
import cx from 'classnames';
import {
    getPatientAnomalyAlertSettingApi,
    PatientAnomalyAlertSetting,
    PatientAnomalyAlertSettingParam,
    updatePatientAnomalyAlertSettingApi,
} from '@api/sh/patientAnomalyAlertSetting';
import { useAppSelector, useAsync, useConfirm, useTranslation } from '@hooks';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Button from '@components/Button';
import { useAnomalyAlertStateContext } from './slice';
import { useGetPatientAnomalyAlertSettingList, useUpdateGroupInfo } from './hooks';

const OPTIONS_USAGE = [
    { value: 'Y', label: 'Enabled' },
    { value: 'N', label: 'Disabled' },
];

const OPTIONS_SELECT_YN = [
    { value: 'Y', label: 'Select' },
    { value: 'N', label: 'Not Select' },
];

const OPTIONS_OPEN_TIME = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
];

const OPTIONS_OPEN_LIMIT_TIME = [
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 30, label: 30 },
];

type Settings = Omit<PatientAnomalyAlertSetting, 'alertSettingGroupNum' | 'comNum'> & {
    alertOpenTime?: number;
    alertOpenCount?: number;
    alertCloseCount?: number;
};

const initialSettings: Omit<Settings, 'groupNum'> = {
    alertSettingNum: null,
    alertSettingName: null,
    alertActive: 'N',
    alertOpenTime: 2,
    alertOpenCount: 60,
    alertCloseCount: 10,
    alertSnoozeActive: 'N',
    alertSnoozeTime: 10,
    alertRestrictionActive: 'N',
    alertRestrictionZones: [],
    alertSoundUse: 'Y',
    alertSoundVolume: 100,
    alertSoundInfo: {
        baseUrl: '',
        alertSounds: [],
    },
};

type AlertSettingModalProps = {
    initModal: boolean;
    toggleModal: () => void;
};

const AlertSettingModal = ({ initModal, toggleModal }: AlertSettingModalProps) => {
    const t = useTranslation('PatientAnomalyAlertSetting');
    const storeDispatch = useDispatch();
    const { anomalyAlertGroupNum } = useAppSelector(state => state.UserInfo);
    const geofenceGroupList = useAppSelector(state => state.GeofenceInfo.geofenceGroupList);
    const alertSoundList = useAppSelector(state => state.EmergencyAlert.anomalyAlertSoundList) as {
        uri: string;
        name: string;
        baseUrl: string;
        src: string;
    }[];
    const allSensorSettingList = useAppSelector(state => {
        const userWideNotificationSettings = state.Notification.userWideNotificationSettings as {
            groupAlertInfoList: { alertSettingNum: number; alertSettingName: string }[];
        } | null;
        if (userWideNotificationSettings && userWideNotificationSettings.groupAlertInfoList) {
            return userWideNotificationSettings.groupAlertInfoList;
        }
        return [];
    });
    const [sensorSettingList, setSensorSettingList] = useState<{ alertSettingNum: number; alertSettingName: string }[]>(
        [],
    );
    const {
        selectedGroupNum,
        selectedAlertSettingGroupNum,
        anomalyAlertSettingListInfo,
    } = useAnomalyAlertStateContext();
    const [settings, setSettings] = useState<Settings>({
        groupNum: selectedGroupNum ?? 0,
        ...initialSettings,
    });

    const audioRef = useRef<HTMLAudioElement>(null);
    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume =
                typeof settings.alertSoundVolume === 'number' ? settings.alertSoundVolume / 100 : 1;
        }
    }, [settings.alertSoundVolume]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.load();
        }
    }, [settings]);

    const usageOptions = useMemo(() => {
        return OPTIONS_USAGE.map(({ value, label }) => ({ value, label: t(label) }));
    }, [t]);

    const selectYnOptions = useMemo(() => {
        return OPTIONS_SELECT_YN.map(({ value, label }) => ({ value, label: t(label) }));
    }, [t]);

    useAsync({
        promise: getPatientAnomalyAlertSettingApi,
        fixedParam: { alertSettingGroupNum: selectedAlertSettingGroupNum },
        immediate: !!selectedAlertSettingGroupNum && initModal,
        deps: [selectedAlertSettingGroupNum],
        resolve: res => {
            setSettings(res as PatientAnomalyAlertSetting<number>);
        },
    });

    useEffect(() => {
        if (allSensorSettingList.length) {
            const filteredList = allSensorSettingList.filter(setting => {
                return (
                    anomalyAlertSettingListInfo.rows.find(
                        alertSetting =>
                            alertSetting.alertSettingGroupNum === selectedAlertSettingGroupNum &&
                            alertSetting.alertSettingNum === setting.alertSettingNum,
                    ) ||
                    !anomalyAlertSettingListInfo.rows.find(
                        alertSetting => alertSetting.alertSettingNum === setting.alertSettingNum,
                    )
                );
            });
            setSensorSettingList(filteredList);

            if (!selectedAlertSettingGroupNum && selectedGroupNum) {
                const initial = { ...initialSettings };

                // 센서 초기값 설정
                if (filteredList.length) {
                    const initialSensorSetting = filteredList[0];
                    initial.alertSettingNum = initialSensorSetting.alertSettingNum;
                    initial.alertSettingName = initialSensorSetting.alertSettingName;
                }

                // 사운드 초기값 설정
                if (alertSoundList.length) {
                    initial.alertSoundInfo = {
                        ...initial.alertSoundInfo,
                        baseUrl: alertSoundList[0].baseUrl,
                        alertSounds: alertSoundList.map(({ uri, name }: { uri: string; name: string }, idx) => {
                            if (idx === 0) {
                                return { uri, name, selected: 'Y' };
                            }
                            return { uri, name, selected: 'N' };
                        }),
                    };
                }

                setSettings({ ...initial, groupNum: selectedGroupNum });
            }
        }
    }, [
        selectedGroupNum,
        selectedAlertSettingGroupNum,
        anomalyAlertSettingListInfo,
        allSensorSettingList,
        alertSoundList,
    ]);

    const { promise: getAnomalyAlertGroupSettingsList } = useUpdateGroupInfo();
    const { promise: getPatientAnomalyAlertSettingList } = useGetPatientAnomalyAlertSettingList();

    const { promise: updatePatientAnomalyAlertSetting } = useAsync({
        promise: updatePatientAnomalyAlertSettingApi,
        resolve: res => {
            saveSuccessModalProps.toggleModal();

            getAnomalyAlertGroupSettingsList();
            getPatientAnomalyAlertSettingList();
        },
        reject: () => {
            saveFailureModalProps.toggleModal();
        },
    });

    const saveConfirmModalProps = useConfirm({
        confirmText: t('Do you want to save?'),
        callbackParam: settings,
        okCallback: (
            settings: Omit<PatientAnomalyAlertSettingParam, 'alertSound'> &
                Pick<PatientAnomalyAlertSetting, 'alertSoundInfo'>,
        ) => {
            if (validAlertOpenCount(settings) && validAlertCloseCount(settings) && validSensorSetting(settings)) {
                const { alertSoundInfo, ...restSettings } = settings;
                const selectedSound = alertSoundInfo.alertSounds.find(sound => sound.selected === 'Y');
                if (audioRef.current) {
                    updatePatientAnomalyAlertSetting({
                        ...restSettings,
                        alertSoundVolume: Math.floor(audioRef.current.volume * 100),
                        alertSound: selectedSound ?? {
                            uri: '',
                            name: '',
                        },
                    });
                }
            } else {
                validationFailureModalProps.toggleModal();
            }
        },
    });

    const validationFailureModalProps = useConfirm({
        confirmText: t("Sorry, we didn't get the value right, please check and try again."),
        removeCancel: true,
    });

    const saveSuccessModalProps = useConfirm({
        confirmText: t('Save is complete.'),
        removeCancel: true,
        okCallback: () => {
            toggleModal();
        },
    });

    const saveFailureModalProps = useConfirm({
        confirmText: t('The save failed.'),
        removeCancel: true,
    });

    return (
        <>
            <Modal
                className={'modal-xl'}
                initModal={initModal}
                toggleModal={toggleModal}
                removeModalClose
                headerTitle={t('Add/Edit anomaly alert sensors')}
                modalFooter={
                    <ModalFooter>
                        <Button
                            onClick={() => {
                                toggleModal();
                            }}
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            className={'btn-secondary'}
                            onClick={() => {
                                saveConfirmModalProps.toggleModal();
                            }}
                        >
                            {t('Save')}
                        </Button>
                    </ModalFooter>
                }
            >
                <ModalBodyWrapper>
                    <Label
                        name={
                            <span>
                                <div>{t('Sensor Setting Name')}</div>
                                <small>* {t('Confirm the sensor name for which you want to set an alert.')}</small>
                            </span>
                        }
                        value={
                            <Select
                                disabled={!!selectedAlertSettingGroupNum}
                                isModalSelect
                                valueKey={'alertSettingNum'}
                                labelKey={'alertSettingName'}
                                options={sensorSettingList}
                                value={sensorSettingList.find(
                                    ({ alertSettingNum }) => alertSettingNum === settings.alertSettingNum,
                                )}
                                onChange={selected => {
                                    const selectedItem = selected as {
                                        alertSettingNum: number;
                                        alertSettingName: string;
                                    };
                                    if (selectedItem) {
                                        setSettings({
                                            ...settings,
                                            alertSettingNum: selectedItem.alertSettingNum,
                                            alertSettingName: selectedItem.alertSettingName,
                                        });
                                    }
                                }}
                            />
                        }
                    />
                    <div className="pnt-divider horizon-line" />
                    <Label
                        name={
                            <span>
                                <div>{t('Enable or disable the anomaly alert popup')}</div>
                                <small>* {t('You can choose to enable or disable the anomaly alert popup.')}</small>
                            </span>
                        }
                        value={
                            <Select
                                isModalSelect
                                options={usageOptions}
                                value={usageOptions.find(({ value }) => value === settings.alertActive)}
                                onChange={selected => {
                                    const selectedItem = selected as { value: YN; label: string };
                                    if (selected) {
                                        setSettings({ ...settings, alertActive: selectedItem.value });
                                    }
                                }}
                            />
                        }
                    />
                    <div className="pnt-divider horizon-line" />
                    <Label
                        name={
                            <span>
                                <div>{t('Criteria for opening the alert popup')}</div>
                                <small>
                                    * {t('Set the anomaly persistence criteria that triggers the alert popup.')}
                                </small>
                            </span>
                        }
                        value={
                            <span>
                                <div className={'d-flex align-items-center'}>
                                    <Trans
                                        i18nKey={'PatientAnomalyAlertSetting'}
                                        components={[
                                            <TextInput
                                                maxlength={4}
                                                inputGroupClassName={cx(
                                                    'mx-1 w-narrow',
                                                    !validAlertOpenCount(settings) && 'input-error',
                                                )}
                                                disabled={settings.alertActive === 'N'}
                                                value={settings.alertOpenCount}
                                                handleChange={e => {
                                                    const { value } = e.currentTarget;
                                                    if (/^\d*$/.test(value)) {
                                                        setSettings({
                                                            ...settings,
                                                            alertOpenCount: Number(value),
                                                        });
                                                    }
                                                }}
                                                errorMsg={
                                                    settings.alertOpenCount === 0
                                                        ? t('Please enter a number greater than or equal to 1.')
                                                        : t('Cannot exceed 60 times per minute. Current limit:') +
                                                          ' ' +
                                                          settings.alertOpenTime * 60
                                                }
                                            />,
                                            <Select
                                                isModalSelect
                                                disabled={settings.alertActive === 'N'}
                                                className={'mx-1'}
                                                options={OPTIONS_OPEN_TIME}
                                                value={OPTIONS_OPEN_TIME.find(
                                                    ({ value }) => value === settings.alertOpenTime,
                                                )}
                                                onChange={selected => {
                                                    const selectedItem = selected as { value: number; label: number };
                                                    if (selected) {
                                                        setSettings({
                                                            ...settings,
                                                            alertOpenTime: selectedItem.value,
                                                        });
                                                    }
                                                }}
                                            />,
                                        ]}
                                    >
                                        {t('Pop up an alert when anomalies persists <0></0> times in <1></1> minutes.')}
                                    </Trans>
                                </div>
                            </span>
                        }
                    />
                    <div className="pnt-divider horizon-line" />
                    <Label
                        name={
                            <span>
                                <div>{t('Alert Open Limit Criteria')}</div>
                                <small>
                                    * {t('You can restrict the alert from opening for a certain amount of time.')}
                                </small>
                            </span>
                        }
                        value={
                            <span className={'d-flex align-items-center'}>
                                <Select
                                    className={'mr-3'}
                                    isModalSelect
                                    disabled={settings.alertActive === 'N'}
                                    options={usageOptions}
                                    value={usageOptions.find(({ value }) => value === settings.alertSnoozeActive)}
                                    onChange={selected => {
                                        const selectedItem = selected as { value: YN; label: string };
                                        if (selected) {
                                            setSettings({ ...settings, alertSnoozeActive: selectedItem.value });
                                        }
                                    }}
                                />
                                <div>
                                    <div className={'d-flex align-items-center'}>
                                        <Trans
                                            i18nKey={'PatientAnomalyAlertSetting'}
                                            components={[
                                                <Select
                                                    isModalSelect
                                                    disabled={
                                                        settings.alertActive === 'N' ||
                                                        settings.alertSnoozeActive === 'N'
                                                    }
                                                    options={OPTIONS_OPEN_LIMIT_TIME}
                                                    value={OPTIONS_OPEN_LIMIT_TIME.find(
                                                        ({ value }) => value === settings.alertSnoozeTime,
                                                    )}
                                                    onChange={selected => {
                                                        const selectedItem = selected as {
                                                            value: number;
                                                            label: number;
                                                        };
                                                        if (selected) {
                                                            setSettings({
                                                                ...settings,
                                                                alertSnoozeTime: selectedItem.value,
                                                            });
                                                        }
                                                    }}
                                                />,
                                            ]}
                                        >
                                            {t("Don't open the popup for <0></0> minutes after checking the alert.")}
                                        </Trans>
                                    </div>
                                    <span className="custom-error-txt">
                                        {t(
                                            'It will take effect from the time you tick the checkbox in the alert popup and click the ‘OK’ button.',
                                        )}
                                    </span>
                                </div>
                            </span>
                        }
                    />
                    <div className="pnt-divider horizon-line" />
                    <Label
                        name={
                            <span>
                                <div>{t('Criteria for auto-closing the alert popup')}</div>
                                <small>* {t('Set the criteria for the alert popup to close automatically.')}</small>
                            </span>
                        }
                        value={
                            <span>
                                <div className={'d-flex align-items-center'}>
                                    <Trans
                                        i18nKey={'PatientAnomalyAlertSetting'}
                                        components={[
                                            <TextInput
                                                maxlength={4}
                                                inputGroupClassName={cx(
                                                    'mx-1 w-narrow',
                                                    !validAlertCloseCount(settings) && 'input-error',
                                                )}
                                                disabled={settings.alertActive === 'N'}
                                                value={settings.alertCloseCount}
                                                handleChange={e => {
                                                    const { value } = e.currentTarget;
                                                    if (/^\d*$/.test(value)) {
                                                        setSettings({
                                                            ...settings,
                                                            alertCloseCount: Number(value),
                                                        });
                                                    }
                                                }}
                                                errorMsg={t('Please enter a number greater than or equal to 1.')}
                                            />,
                                        ]}
                                    >
                                        {t('After <0></0> consecutive steady states, close the alert popup.')}
                                    </Trans>
                                </div>
                            </span>
                        }
                    />
                    <div className="pnt-divider horizon-line" />
                    <Label
                        name={
                            <span>
                                <div>{t('Selecting alert restriction zones')}</div>
                                <small>* {t("Disable anomaly alert when you're in certain areas.")}</small>
                            </span>
                        }
                        value={
                            <>
                                <Select
                                    isModalSelect
                                    disabled={settings.alertActive === 'N'}
                                    options={selectYnOptions}
                                    value={selectYnOptions.find(
                                        ({ value }) => value === settings.alertRestrictionActive,
                                    )}
                                    onChange={selected => {
                                        const selectedItem = selected as { value: YN; label: string };
                                        if (selected) {
                                            setSettings({ ...settings, alertRestrictionActive: selectedItem.value });
                                        }
                                    }}
                                />
                                <SearchableSelect
                                    isModalSelect
                                    disabled={settings.alertActive === 'N' || settings.alertRestrictionActive === 'N'}
                                    valueKey={'fcGroupNum'}
                                    labelKey={'fcGroupName'}
                                    data={geofenceGroupList}
                                    value={settings.alertRestrictionZones.filter(fcGroup => fcGroup.isRestrict === 'Y')}
                                    onChange={selected => {
                                        const selectedItems = selected as PatientAnomalyAlertSetting['alertRestrictionZones'];
                                        setSettings({
                                            ...settings,
                                            alertRestrictionZones: geofenceGroupList.map(
                                                ({ fcGroupNum, fcGroupName }) => {
                                                    if (
                                                        selectedItems.find(
                                                            selected => selected.fcGroupNum === fcGroupNum,
                                                        )
                                                    ) {
                                                        return { fcGroupNum, fcGroupName, isRestrict: 'Y' };
                                                    } else {
                                                        return { fcGroupNum, fcGroupName, isRestrict: 'N' };
                                                    }
                                                },
                                            ),
                                        });
                                    }}
                                />
                            </>
                        }
                    />
                    <div className="pnt-divider horizon-line" />
                    <Label
                        name={
                            <span>
                                <div>{t('Set alert sound')}</div>
                                <small>* {t('Set the sound that plays when the anomaly alert popup opens.')}</small>
                            </span>
                        }
                        value={
                            <>
                                <Select
                                    isModalSelect
                                    disabled={settings.alertActive === 'N'}
                                    options={usageOptions}
                                    value={usageOptions.find(({ value }) => value === settings.alertSoundUse)}
                                    onChange={selected => {
                                        const selectedItem = selected as { value: YN; label: string };
                                        if (selected) {
                                            setSettings({ ...settings, alertSoundUse: selectedItem.value });
                                        }
                                    }}
                                />
                                <Select
                                    isModalSelect
                                    disabled={settings.alertActive === 'N' || settings.alertSoundUse === 'N'}
                                    options={alertSoundList}
                                    value={settings.alertSoundInfo.alertSounds.find(({ selected }) => selected === 'Y')}
                                    valueKey={'uri'}
                                    labelKey={'name'}
                                    onChange={selected => {
                                        const selectedItem = selected as { uri: string; name: string };
                                        const { alertSoundInfo } = settings;
                                        if (selected) {
                                            setSettings({
                                                ...settings,
                                                alertSoundInfo: {
                                                    ...alertSoundInfo,
                                                    alertSounds: alertSoundList.map(
                                                        ({ uri, name }: { uri: string; name: string }) => {
                                                            if (selectedItem.uri === uri) {
                                                                return { uri, name, selected: 'Y' };
                                                            }
                                                            return { uri, name, selected: 'N' };
                                                        },
                                                    ),
                                                },
                                            });
                                        }
                                    }}
                                />
                                <audio
                                    ref={audioRef}
                                    controls
                                    controlsList={'nodownload noremoteplayback noplaybackrate'}
                                    style={{ height: '34px' }}
                                >
                                    {!(settings.alertActive === 'N' || settings.alertSoundUse === 'N') &&
                                        settings.alertSoundInfo && (
                                            <source
                                                src={
                                                    settings.alertSoundInfo.baseUrl +
                                                    (settings.alertSoundInfo || { alertSounds: [] }).alertSounds.find(
                                                        ({ selected }) => selected === 'Y',
                                                    )?.uri
                                                }
                                            />
                                        )}
                                </audio>
                            </>
                        }
                    />
                </ModalBodyWrapper>
            </Modal>
            <ConfirmModal {...saveConfirmModalProps} />
            <ConfirmModal {...saveSuccessModalProps} />
            <ConfirmModal {...saveFailureModalProps} />
            <ConfirmModal {...validationFailureModalProps} />
        </>
    );
};

const validAlertOpenCount = (settings: Settings) => {
    return !(settings.alertOpenCount === 0 || settings.alertOpenTime * 60 < settings.alertOpenCount);
};

const validAlertCloseCount = (settings: Settings) => {
    return settings.alertCloseCount !== 0;
};

const validSensorSetting = (settings: Settings) => {
    return !!settings.alertSettingNum;
};

const ModalBodyWrapper = styled(ModalBody)`
    display: grid;
    row-gap: 0.25rem;

    .label-main {
        flex: 0 0 25rem;
    }

    .pnt-input--group.w-narrow {
        min-width: 90px;
        width: 90px;

        &.input-error {
            margin-bottom: 0;

            .input-error-txt {
                white-space: nowrap;
                margin-top: 0;
            }
        }
    }
    #root .app-container & span.custom-error-txt {
        color: var(--danger);
        font-size: 12px;
        font-weight: 500;
    }
`;

export default AlertSettingModal;
