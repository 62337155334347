import { useAsync } from '@hooks';
import {
    AnomalyAlertGroupSettings,
    getAnomalyAlertGroupSettingsListApi,
    getPatientAnomalyAlertSettingListApi,
} from '@api/sh/patientAnomalyAlertSetting';
import {
    selectGroupNum,
    setAlertGroupSettingList,
    setAnomalyAlertSettingListInfo,
    useAnomalyAlertDispatchContext,
    useAnomalyAlertStateContext,
} from './slice';
import useAppSelector from '@hooks/useAppSelector';
import { useDispatch } from 'react-redux';
import { setAnomalyAlertSettingList } from '@reducer/EmergencyAlert';

export const useUpdateGroupInfo = () => {
    const { anomalyAlertGroupNum } = useAppSelector(state => state.UserInfo);
    const dispatch = useAnomalyAlertDispatchContext();
    const { selectedGroupNum } = useAnomalyAlertStateContext();

    return useAsync({
        promise: getAnomalyAlertGroupSettingsListApi,
        immediate: true,
        deps: [selectedGroupNum, anomalyAlertGroupNum],
        resolve: res => {
            const groupList = (res.rows || []) as AnomalyAlertGroupSettings[];
            groupList.sort((a, b) => (a.groupName > b.groupName ? 1 : -1));
            dispatch(setAlertGroupSettingList(groupList));
            if (groupList.length && !selectedGroupNum) {
                const firstSelected = groupList.find(group => group.groupSelected === 'Y');
                dispatch(selectGroupNum(firstSelected ? firstSelected.groupNum : groupList[0].groupNum));
            }
        },
    });
};

export const useGetPatientAnomalyAlertSettingList = (param?: { immediate: boolean }) => {
    const storeDispatch = useDispatch();
    const { anomalyAlertGroupNum } = useAppSelector(state => state.UserInfo);
    const dispatch = useAnomalyAlertDispatchContext();
    const { selectedGroupNum } = useAnomalyAlertStateContext();

    return useAsync({
        promise: getPatientAnomalyAlertSettingListApi,
        fixedParam: { groupNum: selectedGroupNum, isAll: 'Y' },
        immediate: param?.immediate,
        deps: [selectedGroupNum, anomalyAlertGroupNum],
        resolve: res => {
            dispatch(setAnomalyAlertSettingListInfo(res));
            if (selectedGroupNum === anomalyAlertGroupNum) {
                storeDispatch(setAnomalyAlertSettingList(res.rows));
            }
        },
    });
};
