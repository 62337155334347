import React from 'react';
import { useColumns, useTranslation } from '@hooks';
import * as column from '../column';
import InnerItemCard from '@components/Card/InnerItemCard';

import { Table } from '@components';
import styled from 'styled-components';
import Search from '../../Search';
import { setOutpatientListParam } from '../../OutpatientProofIssuanceStatusSlice';
import { useOutpatientProofDispatch } from '../../index';
import moment from 'moment';

const OutpatientProofStatusList = ({ outpatientList }) => {
    const t = useTranslation('OutpatientProofIssuance');
    const dispatch = useOutpatientProofDispatch();

    const columns = useColumns(
        [
            column.category(),
            column.issueDate({
                Cell: ({ value }) => {
                    return value ? moment.unix(value).format('YYYY-MM-DD') : ' - ';
                },
            }),
            column.issueTime({
                Cell: ({ value }) => {
                    return value ? moment.unix(value).format('HH:mm') : ' - ';
                },
            }),
            column.patientName(),
            column.id(),
            column.speciality(),
            column.doctor(),
            column.issuanceCertificate({ width: 200, Cell: ({ value }) => <span>{t(value)}</span> }),
            column.receiptWon({
                Cell: ({ row, value }) => {
                    const { original } = row;
                    return <span style={{ color: original.status === 'fail' && 'red' }}>{value}</span>;
                },
            }),
            column.pgResultCode({
                Cell: ({ row, value }) => {
                    const { original } = row;
                    return <span style={{ color: original.status === 'fail' && 'red' }}>{value}</span>;
                },
            }),
            column.emrResultCode({
                Cell: ({ row, value }) => {
                    const { original } = row;
                    return <span style={{ color: original.status === 'fail' && 'red' }}>{value}</span>;
                },
            }),
        ],
        t,
        [],
    );

    const handlePageChange = pageIndex => {
        dispatch(
            setOutpatientListParam(prevState => {
                return { ...prevState, page: pageIndex };
            }),
        );
    };

    return (
        <Container>
            <InnerItemCard
                className={'h-100'}
                bodyClassName={'h-100'}
                header={{
                    title: t('Proof-of-Issue List'),
                }}
            >
                <Search />
                <TableContainer className={'h-90'}>
                    <Table
                        data={outpatientList}
                        columns={columns}
                        onPageChange={handlePageChange}
                        dynamicRowHeight={true}
                    />
                </TableContainer>
            </InnerItemCard>
        </Container>
    );
};
const Container = styled.div`
    height: 550px;
    margin-top: 5px;
`;
const TableContainer = styled.div`
    height: 250px;
    margin-bottom: 5px;
`;
export default OutpatientProofStatusList;
