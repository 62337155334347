import { BarChart } from '@components';
import InnerItemCard from '@components/Card/InnerItemCard';
import { useAppSelector, useTranslation } from '@hooks';
import React, { useMemo } from 'react';
import { OUTPATIENT_REPORT } from '../../constants';
import { customLegendPlugin, hoverBackgroundPlugin } from '../util/chartPlugin';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { fontColorByTheme } from '../util';

const Chart = ({ data }) => {
    const t = useTranslation(OUTPATIENT_REPORT);
    const { colorScheme } = useAppSelector(state => state.ThemeOptions);

    const barData = useMemo(
        () => ({
            labels: data.map(({ departmentName }) => departmentName),
            datasets: [
                {
                    label: t('Min Waiting Time'),
                    data: data.map(row => row.waitMin),
                    forcedBackgroundColor: 'rgb(18,136,200)',
                    datalabels: {
                        // 데이터의 길이가 1개일때만 datalabes 표시
                        anchor: 'end',
                        align: 'top',
                        color: fontColorByTheme(colorScheme),
                        formatter: function (value) {
                            // 개별일땐 키오스크 사용률까지
                            return data.length === 1 ? `${value} ${t('Minute')}` : value; // 표시할 값
                        },
                    },
                },
                {
                    label: t('Average Waiting Time'),
                    data: data.map(row => row.waitAvg),
                    forcedBackgroundColor: 'rgb(6,170,67)',
                    datalabels: {
                        // 데이터의 길이가 1개일때만 datalabes 표시
                        anchor: 'end',
                        align: 'top',
                        color: fontColorByTheme(colorScheme),
                        formatter: function (value) {
                            // 개별일땐 키오스크 사용률까지
                            return data.length === 1 ? `${value} ${t('Minute')}` : value; // 표시할 값
                        },
                    },
                },
                {
                    label: t('Max Waiting Time'),
                    data: data.map(row => row.waitMax),
                    forcedBackgroundColor: 'rgba(255,22,55, 1.0)',
                    datalabels: {
                        // 데이터의 길이가 1개일때만 datalabes 표시
                        anchor: 'end',
                        align: 'top',
                        color: fontColorByTheme(colorScheme),
                        formatter: function (value) {
                            // 개별일땐 키오스크 사용률까지
                            return data.length === 1 ? `${value} ${t('Minute')}` : value; // 표시할 값
                        },
                    },
                },
            ],
        }),
        [data, t, colorScheme],
    );

    return (
        <InnerItemCard
            className={'m-2 h-90'}
            bodyClassName={'h-100'}
            header={{ title: t('Outpatient Waiting Time by Department') }}
        >
            <div className={'h-90 flx-row position-relative'}>
                <div
                    id="custom-legend-container"
                    className={'d-flex gap-2 position-absolute'}
                    style={{
                        top: 0,
                        right: 0,
                    }}
                />
                <BarChart
                    data={barData}
                    type={'multiBar'}
                    plugins={[ChartDataLabels, hoverBackgroundPlugin, customLegendPlugin]}
                    options={{
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 60,
                            },
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            datalabels: {
                                color: context => {
                                    const dataset = context.dataset;
                                    return dataset.backgroundColor;
                                },
                                anchor: 'end',
                                align: 'top',
                                font: {
                                    size: 14,
                                },
                            },
                            tooltip: {
                                intersect: false,
                                mode: 'index',
                                callbacks: {
                                    label: function (context) {
                                        const label = context.dataset.label || ''; // 데이터셋 레이블
                                        const value = context.raw; // 데이터 값
                                        return `${label}: ${value} ${t('Minute')}`; // "건" 추가
                                    },
                                },
                            },
                        },
                        scales: {
                            x: {
                                title: {
                                    display: true,
                                    text: t('Departments Using Kiosks'),
                                    font: {
                                        size: 16,
                                        family: 'pretendard',
                                    },
                                    padding: { top: 20 },
                                },
                            },
                            y: {
                                grace: '20%',
                                beginAtZero: true,
                                suggestedMax: 10,
                                title: {
                                    display: true,
                                    text: t('Outpatient Waiting Time (minutes)'),
                                    font: {
                                        size: 15,
                                        family: 'pretendard',
                                    },
                                },
                            },
                        },
                    }}
                />
            </div>
        </InnerItemCard>
    );
};

export default Chart;
