import React, { useRef } from 'react';
import { WidgetProps } from '../../staticInfo';
import WidgetCard from '../../Components/WidgetCard';
import useDateTypeOptions from '../util/useDateTypeOptions';
import useRangeDateSO from '../util/useRangeDateSO';
import useDepartmentOptions from '../util/useDepartmentOptions';
import SearchGroupSO from '../Components/SearchGroupSO';
import { useAsync, useTranslation } from '@hooks';
import { getOutpatientWaitingTicket, postOutpatientWaitingTicketExcel } from '@api/so/outpatient';
import { useAsyncExcel } from '../../util/useAsyncExcel';
import moment from 'moment';
import { isValidData } from '../util/checkData';
import Chart from './Chart';
import { LoadingBlock } from '@components';
import NoDataBlock from '../../Components/NoDataBlock';
import InsightBox, { BoxBodyText, HighlightedText } from '../Components/InsightBox';
import { Trans } from 'react-i18next';
import { TFunction } from 'i18next';

const OutpatientWaitingTicket = ({ children, widgetInfo, ...restProps }: WidgetProps) => {
    const widgetRef = useRef(null);
    const t = useTranslation('outpatientWaitTicket');

    const dateTypeValues = useDateTypeOptions();
    const dateValues = useRangeDateSO(dateTypeValues.dateType);
    const departmentValues = useDepartmentOptions();

    const { state, promise: getData } = useAsync({
        promise: getOutpatientWaitingTicket,
        immediate: true,
        param: {
            departmentCode: departmentValues.department.toString(),
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
        },
        reject: err => console.error(err),
    });

    const triggerDownload = useAsyncExcel({
        promise: postOutpatientWaitingTicketExcel,
        param: {
            departmentCode: departmentValues.department.toString(),
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
            columnMetas: [
                {
                    key: 'departmentName',
                    name: t('Department Name'),
                },
                {
                    key: 'waitSeq',
                    name: t('Order'),
                },
                {
                    key: 'patientId',
                    name: t('PatientNum'),
                },

                {
                    key: 'patientName',
                    name: t('PatientName'),
                },

                {
                    key: 'waitRegistrationDate',
                    name: t('Order Registration Time'),
                },

                {
                    key: 'testStartDate',
                    name: t('Call time'),
                },
            ],
            fileName:
                'so_kioskData_' +
                moment.unix(dateValues.startDate).format('YYYYMMDD') +
                '_' +
                moment.unix(dateValues.endDate).format('YYYYMMDD'),
            zipFileName: 'so_usage_by_analysis_ticket',
        },
    });

    const handleDownloadClick = () => {
        if (!isValidData(data)) {
            return;
        }

        triggerDownload();
    };

    const data = state.response ? state.response.rows : [];
    const {
        mostUsedDeptName = '',
        leastUsedDeptName = '',
        totalUsedAmount = 0,
        generalIssuedAmount = 0,
        registrationIssuedAmount = 0,
    } = state.response?.additionalDatas ?? {};

    return (
        <WidgetCard widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
            <SearchGroupSO
                showDepartment
                dateTypeValues={dateTypeValues}
                departmentValues={departmentValues}
                dateValues={dateValues}
                getData={getData}
                showDownload={isValidData(data)}
                handleDownload={handleDownloadClick}
                showMethod={false}
                methodValues={undefined}
                showSaturday={false}
                handleSaturday={() => null}
            />
            <LoadingBlock blocking={state.isLoading}>
                {isValidData(data) ? (
                    <>
                        <InsightBox>
                            <BoxBodyText>
                                <Trans
                                    t={t as TFunction}
                                    values={{
                                        mostUsedDeptName,
                                        leastUsedDeptName,
                                        totalUsedAmount,
                                        generalIssuedAmount,
                                        registrationIssuedAmount,
                                        ownDeptName: data[0]?.departmentName ?? '',
                                        ownTotalUsedAmount: data[0]?.totalUsedAmount ?? 0,
                                        ownGeneralIssuedAmount: data[0]?.generalIssuedAmount ?? 0,
                                        ownRegistrationIssuedAmount: data[0]?.registrationIssuedAmount ?? 0,
                                    }}
                                    components={[<HighlightedText />, <br />]}
                                >
                                    {data.length >= 2
                                        ? 'During the query period, the selected department have a total of <0>{{totalUsedAmount}}</0>Cases, <0>{{generalIssuedAmount}}</0> for general issuance and <0>{{registrationIssuedAmount}}</0> for registration number issuance <1/>The department with the highest usage of the order book in the time period is labeled <0>{{mostUsedDeptName}}</0>, and the department with the lowest usage is labeled <0>{{leastUsedDeptName}}</0>.'
                                        : 'Within the lookup period, <0>{{ownDeptName}}</0> Order ticket usage totaled <0>{{ownTotalUsedAmount}}</0>, with <0>{{ownGeneralIssuedAmount}}</0> regular issuances and <0>{{ownRegistrationIssuedAmount}}</0> registration number issuances.'}
                                </Trans>
                            </BoxBodyText>
                        </InsightBox>
                        <Chart data={data} />
                    </>
                ) : (
                    <NoDataBlock text={t('No data')} />
                )}
            </LoadingBlock>
            {children}
        </WidgetCard>
    );
};

export default OutpatientWaitingTicket;
