export const CONGESTION_TYPE_LIST = [
    {
        min: -Infinity,
        max: 129,
        color: 'yellowgreen',
        desc: 'Normal (~129%)',
        radius: 20,
    },
    {
        min: 130,
        max: 149,
        color: 'yellow',
        desc: 'Caution (~149%)',
        radius: 30,
    },
    {
        min: 150,
        max: 169,
        color: 'orange',
        desc: 'Congested (~169%)',
        radius: 40,
    },
    {
        min: 170,
        max: Infinity,
        color: 'red',
        desc: 'Serious (170%~)',
        radius: 60,
    },
];

export const TIMER_STEP = 1;

export const SPEED_OPTION = [1, 2, 4, 8, 16, 32, 64];
